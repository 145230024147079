/**
 * FreeFM aside
 *
 * Layout for Artikel and Sendung.
 */
.freefm-aside {
  margin-bottom: 30px;
  @include font-size(16);
}

.freefm-aside-aside {
  @include font-size($fontsize_small);
  > .panel-pane {
    margin-bottom: 20px;
    background-color: #ebebeb;
    &:last-child {
      margin-bottom: 0;
    }
    padding: 0;
    .pane-content {
      padding: 0 18px 18px 18px;
    }
  }
  h2.pane-title {
    font-weight: 600;
    @include font-size(16);
    text-transform: uppercase;
    margin: 0;
    padding: 18px;
    //color: $color_headline;
    color: #000;
    border: none;
  }
}


.freefm-aside-meta {
  .panel-pane {
    margin-bottom: 10px;
    background-color: transparent;
  }
  h2.pane-title {
    margin: 0;
    padding: 8px 0;
    @include font-size(14);
  }
  .field-name-field-externe-website .field-item {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// .freefm-aside-aside .pane-freefm-podcasts-panel-pane-sendung,
// .freefm-aside-aside .pane-freefm-podcasts-panel-pane-1,
// .freefm-aside-aside .pane-freefm-podcasts-panel-pane-2 {
.freefm-aside-aside .pane-views-panes {
  padding: 0 !important;
  position: relative;
  .pane-content {
    padding: 0;
  }
  .pane-title {
    margin: 0;
    padding: 18px;
    position: relative;
    //&:after {
    //  content: '';
    //  position: absolute;
    //  right: 18px;
    //  top: 18px;
    //  width: 20px;
    //  height: 20px;
    //  @include bgicon('elements/rss-brown', 20px, 20px);
    //}

  }
  .views-row {
    padding: 14px 18px;
    position: relative;
  }
  .views-row:nth-child(2n+1) {
    background-color: #e2e2e1;
  }
  .views-field-title {
    @include font-size(16);
    margin-top: .5rem;
    font-style: italic;
    &, a {
      color: $color-text;
    }
    &:after {
    }
  }
  .view-empty {
    padding: 0 18px 18px 18px;
    p {
      padding: 0;
      margin: 0;
    }
  }
  a.airplan-podcast {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
  }
  .podcast-all {
    padding: 18px 25px 18px 18px;
    border-right: 18px solid transparent;
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: $color_minfo;
    @include bgicon("elements/arrow_sendeblog-orange", 20px, 14px);
    background-position: 100% 20px;
    &:hover {
      color: $color_minfo;
    }
  }
  .view-header {
    position: relative;
  }
  .podcast-rss-link {
    span {
      display: none;
    }
    position: absolute;
    right: 18px;
    top: -38px;
    width: 20px;
    height: 20px;
    @include bgicon('elements/rss-brown', 20px, 20px);
  }
}

/*****
 * Nodes: General.
 ****/

 h1.node-title {
  margin-bottom: 27px;
 }

.node {
  .content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .field-item > h2:first-child {
    margin-top: 0;
  }

  .submitted {
    color: $color_text_meta;
    @include font-size(16);
    a {
      color: $color_text_meta;
    }
  }
  .teaser-image {
    float: left;
    padding: 0 20px 20px 0;
    &.teaser-image-wd {
      float: none;
      padding: 0 0 20px 0;
    }
    @include breakpoint(430px) {
      float: none;
      padding: 0;
      margin: 0 auto;
    }
  }
  .field-name-field-images {
    @include clearfix;
    margin-top: 1em;
    .field-item {
      float: left;
      width: 115px;
      height: 115px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .field-name-field-audio {
    padding: 1em;
    margin: 1em 0;
    background: $color_tbright;
  }
}

.node.node-teaser {
  @include clearfix();
}

.node-is-page {
  position: relative;
  .submitted {
    position: absolute;
    right: 0;
    top: -30px;
    width: auto;
    height: auto;
  }
  .node .submitted {
    position: static;
    right: auto;
    top: auto;
  }
}

/**
 * Artikel
 */

.node-artikel.node-is-page {
  .node-top {
    @include clearfix();
    @include font-size(14);
    padding: 18px 0;
    .field-name-field-sendung {
      float: left;
      a {
        color: $color-text;
        text-transform: uppercase;
        font-weight: 600;
        @include font-size(18);
        font-style: italic;
      }
    }
    .submitted {
      display: block;
      float: right;
      position: static;
      @include font-size($fontsize_small);
    }
  }
  h1.node-title {
    border: none;
    color: $color-text;
    text-transform: none;
    @include font-size(25);
    font-weight: normal;
  }


  .field-name-field-tags {
    border-top: 1px solid #d2d2d4;
    color: $color_mprogramm;
    padding-top: 16px;
    margin-top: 16px;
    * {
      display: inline-block;
    }
    a {
       @include font-size(14);
       text-transform: uppercase;
       font-style: italic;
       font-weight: 600;
       color: #19735f;
       margin-right: .85em;
       &:before {
         content: '→ ';
         display: inline-block;
         font-size: 16px;
         margin-right: 5px;
       }
    }
  }
}

.freefm-aside-main .node-is-page .content .pane-node-field-audio.mobile {
  h2.pane-title {
    display: none;
  }
}

/*****
 * Nodes: Platte der Woche.
 ****/

.node-platte-der-woche {

  .node-intro {
    padding-bottom: 45px;
    margin-bottom: 45px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;

    .submitted {
      position: absolute;
      right: 0;
      top: -20px;
      width: auto;
      height: auto;

    }
    .field-name-field-coverbild {
      float: left;
      img {
        max-width: 220px;
      }
      @include breakpoint($mobile) {
        float: none;
        width: auto;
        margin: 0 auto;
      }
      .field-label {
        display: none;
      }
    }

    @include breakpoint($max, $mobile) {
      &.has-coverbild {
        .node-intro-right,
        .node-intro-bottom {
          position: absolute;
        }
        .node-intro-bottom {
          bottom: 40px;
        }
        .node-intro-right,
        .node-intro-bottom {
          margin-left: 250px;
        }
      }
    }

    h2 {
      color: #282828;
      font-weight: normal;
      //font-style: italic;
      @include font-size(24);
      text-transform: none;
      margin: 10px 0;
      padding: 0;
      border: none;
    }
    h2 a {
      color: #282828;
      display: inline;
      text-decoration: none;
      border-bottom: 1px solid #282828;
      font-style: italic;
    }

    .field-name-field-artist,
    .field-name-field-v-datum,
    .field-name-field-label {
      @include clearfix();
      .field-label {
        font-weight: normal;
        width: 100px;
        float: left;
      }
      .field-items {
        font-weight: 600;
        float: left;
      }
    }
    .field-name-field-artist .field-label {
      width: auto;
    }
  }

  .field-name-body p {
    @include column-count(2);
    @include column-gap(30px);
    @include breakpoint($mobile) {
    	@include column-count(1);
    }
    padding-bottom: 40px;
    //border-bottom: 1px solid $color_line_grey;
    //margin-bottom: 40px;
  }

  .field-name-field-tracklist, .field-name-field-tourdates {
    @include clearfix();
    padding-bottom: 40px;
    .field-label {
      @include font-size(24);
      font-weight: normal;
      color: $color_mmusik;
      //font-style: italic;
      padding-bottom: 1rem;
    }
    @include breakpoint($max, $mobile) {
      float: left;
      width: 49%;
    }
  }

  iframe {
    margin-bottom: 1em;
  }

}

.playlisttable {
  width: 100%;
  tr.even, tr.odd {
    background: transparent;
    //border-bottom: 1px solid $color_tbright;
    border: none;
    td {
      padding: 6px 3px 0 3px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  tr.odd {
    //background: #fcfcfa;
  }
  td:first-child {
    font-weight: bold;
  }
  td {
    padding: 0;
    color: $color_text_small;
  }
  td.playlist-meta {
    color: #245773;
    font-weight: normal;
    padding-top: 0 !important;
  }
  th {
    font-weight: normal;
    // font-style: italic;
    //font-size: 1.2em;
    border: none;
    background: transparent;
    padding: 3px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  h3 {
    margin: 0;
    padding: 20px 0;
    color: $color_text;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      left: 50px;
      border-bottom: 1px solid $color_line_grey;
    }
  }
  @include breakpoint($mobile) {
    tr, td, th {
      display: block;
    }
    tr.odd {
      background: $color_tbright;
    }
    tr {
      border-bottom: 1px solid $color_line_grey;
    }
    th {
      display: inline-block;
      &:after {
        content: '/';
        padding: 8px 0;
      }
      &:last-child:after {
        content: '';
      }
    }
    tr {
      padding: 5px;
    }
    td, th {
      padding: 0;
    }
  }
}

.node-playlist {
  h2 {
    margin: 20px 0;
    border: none;
    a {
      font-weight: normal;
      font-style: italic;
      color: $color_text;
    }
  }
  &.node-teaser, &.node-vm-expand {
    border-bottom: 1px solid $color_line_grey;
    margin-bottom: 20px;
    padding-bottom: 20px;
    .field-name-body {
      color: $color_text_small;
      p:last-child {
        margin-bottom: 0;
      }
    }
    .field-name-field-sendung {
      display: none;
    }
  }
}

/*****
 * Nodes: Sendungen.
 ****/
.square-title {
  @extend %square-title;
}

// all sendung node pages
.page-node-sendung {
  h1.title, h2.page-title {
    @include font-size(24);
    margin: 20px 0 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    border: none;
  }

}


.node-sendung {
  .field-name-body {
    color: $color_text_small;
    //@include font-size($fontsize_small);
  }
  .field-name-field-redaktion {
    .field-item {
      display: inline;
      &:after {
        content: ',\a0';
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }
}

//.field-name-field-airtime {
//	.field-item {
//		margin-bottom: 5px;
//	}
//	small {
//		display: block;
//		margin-left: 10px;
//		color: $color_text_small;
//	}
//}

.node-type-sendung {
  .pane-sendung-body {
    border-top: 1px solid #d2d2d4;
    border-bottom: 1px solid #d2d2d4;
    padding: 18px 0 0 0;
    margin: 0 0 1em 0;
    position: relative;
    @include clearfix();
    .togglebody {
      float: right;
    }
    .rss-link {
      float: right;
      @include bgicon('elements/rss-brown', 20px, 20px);
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 3px;
      span {
        display: none;
      }
    }
    .field-name-body {
      float: none;
      clear: both;
      padding-bottom: 18px;
    }
    .togglebody {
      cursor: pointer;
      @include font-size(16);
      font-weight: bold;
      margin-left: 2em;
      margin-top: 3px;
      color: $color_mprogramm;
      &:after {
        content: '+';
        display: inline-block;
        margin-left: 5px;
      }

    }
  }
  h1.node-title {
    color: $color-text;
    text-transform: none;
    padding: 0 0 18px 0;
    border: none;
    margin: 0;
    float: left;
    font-weight: 600;
    @include font-size(25);
  }
}

.node-type-sendung .pane-views-sendungsblog-block {
  h2.pane-title {
    display: none;
  }
  h2 {
    @include font-size(21);
  }
  .submitted {
    @include font-size(14);
  }
  .node-vm-expand {
    .field-name-field-sendung {
      display: none;
    }
  }
}

.node-type-sendung .freefm-aside {
  .freefm-aside-meta {
    .pane-node-field-airtime {
      .field-item {
        margin-bottom: .5rem;
      }
      small {
        display: block;
      }
    }
  }
  .pane-node-field-airtime.airtime-playout {
    padding: 0 !important;
    .pane-title {
      margin: 0;
      padding: 18px;
    }
    .pane-content {
      padding: 0;
    }
    .airplan-listen {
      background-color: transparent;
      color: $color-text;
      padding: 14px 18px;
      margin: 0;
    }
    .field-item:nth-child(2n+1) .airplan-listen {
      background-color: #e2e2e1;
    }
  }
}

/**
 * Nodes: teasers.
 */
.view-sendungsblog,
.view-inhalt-nach-projekt,
.view-taxonomy-term,
.view-content-by-term {
  .node-teaser, .node-vm-expand {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
    @include clearfix();
    h2 {
      border: none;
      font-style: italic;
      font-weight: normal;
      //@include font-size(2o);
      text-transform: none;
      margin: 20px 0;
      padding: 0;
    }
    .field-name-field-sendung {
      display: none;
    }
  }
}

/**
 * Audio details.
 */
.audio-details {
  margin: 0 0 2rem 0;
  .airplan-podcast {
    float: right;
    margin-top: 5px;
    margin-left: 20px;
  }
  h3 {
    @include font-size($fontsize_text);
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-style: italic;
    margin: 0 0 20px 0;
    color: $color-text;
  }
  a.download {
    color: $color-minfo;
    margin: 0 0 20px 0;
    display: block;
    @include font-size(16);
    font-weight: 600;
  }
  .audio-meta {
    margin: 0 -20px;
    padding: 20px;
    background-color: #e3e3e3;
    color: $color-text;
  }
  h4 {
    font-weight: 600;
    color: $color-text;
    @include font-size(16);
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    @include font-size(16);
  }
  dt {
    width: 33%;
  }
  dd {
    width: 66%;
    margin: 0;
  }
}

.field-item:last-child > .audio-details {
  margin-bottom: 0;
}

/**
 * Gallery.
 */

.image-gallery {
  .item {
    float: left;
    width: 33%;
    height: 310px;
    padding: 0 20px 0 0;
  }
}

.view-freefm-galleries {
  .view-content {
    //display: flex;
    //flex-wrap: wrap;
    @include clearfix();
  }
  .views-row {
    @include breakpoint-min(770px) {
      width: 50%;
      float: left;
    }
    @include breakpoint-min($tablet) {
      width: 33%;
    }

    //flex: 0 0;
    padding-right: 20px;
    padding-bottom: 20px;
    height: 320px;
    margin-bottom: 20px;
  }
  .views-field-title {
    font-size: 20px;
    font-style: italic;
    height: 4em;
    vertical-align: bottom;
    display: table;
    .field-content {
      display: table-cell;
      vertical-align: bottom;
      height: 100%;
      padding-bottom: 5px;
    }
  }
  .views-field-field-images-1 {
    //color: $color_line_grey;
    color: $color_text_meta;
    font-weight: bold;
  }
}



.field-name-field-file-attachements {
  background-color: #ebebeb;
  width: 300px;
  // float: right;
  .field-label, .field-item {
    padding: 15px;
  }
  .field-item:nth-child(n+1) {
    background-color: #e2e2e1;
  }
  .field-item {
    position: relative;
    padding-right: 30px;
    // .file-icon {
    //   position: absolute;
    //   top: 15px;
    //   right: 15px;
    // }
  }
}
