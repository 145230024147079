////////////////////////////////////////////////////////////////////////////////
// Print Styles
// --------------------
// This partial defines styles to be used when a page is printed. The styles are
// included in the main style sheet to avoid an unnecessary HTTP connection: 
// h5bp.com/r
////////////////////////////////////////////////////////////////////////////////

@media print {
  * {
    background: transparent !important;
    color: black !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after, 
  a[href^="javascript:"]:after, 
  a[href^="#"]:after { // Don't show links for images, or javascript/internal links
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead { // h5bp.com/t
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .sidebar,
  #navigation,
  #header-region,
  #search-theme-form,
  #footer,
  .breadcrumb,
  .tabs,
  .feed-icon,
  .links {
    display: none;
  }

  #page {
    width: 100%;
  }

  #content,
  #content #content-inner,
  .content,
  .title {
    margin: 20px 0;
    width: auto;
  }

  a {
    &:hover,
    &:active,
    &:link,
    &:visited {
      color: black
    }
  }

  #content a { // CSS2 selector to add visible href after links.
    &:link:after, &:visited:after {
      content: " (" attr(href) ") ";
      @include font-size(11);
      font-weight: normal;
    }
  }
}