////////////////////////////////////////////////////////////////////////////////
// "vars" partial
// --------------------
//
////////////////////////////////////////////////////////////////////////////////

//
// Variables
////////////////////////////////////////


//
// Widths
//
$first_sidebar_width: 245px;
$second_sidebar_width: 200px;
$page_width: 1000px;

//
// Breakpoints
//
$tablet: 1000px; //This is set to page width by default
$mobile: 600px;
$max: '';

// Additional sidebar break
$sidebar_width_break: 760px;
$sidebar_width_below: 300px;

//
// Heights
//
$navigation_height: 40px;
$footer_height: auto;

//
// Padding
//
$section_padding: 0;
$mobile_padding: 0;

//
// Fonts
//
$main_font: "Source Sans Pro", "Helvetica Neue", helvetica, Arial, sans-serif;
$monospace_font: "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

$fontsize_text: 18;
$fontsize_small: 14;


//
// Colors
//

$c07: #61686e;
$c08: #d2d2d4;

$color_text: #282828;
$color_text_meta: #b09d8a;
$color_text_pressed: #8c8c8c;
$color_text_small: #8c8c8c;
$color_headline: #686868;
$color_headline_border: #d2d2d4;
$color_headline_dark: #252629;

// Tiles
$color_bg_footer: #252629;
$color_tdark: #dd4931;
$color_tmedium: #d2d2d4;
$color_tbright: #f1f1f1;

// $color_bg: #ebebeb;
$color_bg: #e3e3e3;

$color_bg_grey: #cfcecb;
$color_bg_grey2: #61686e;

$color_bg_nav: #373d3e;
$color_text_nav: #f0f0f1;
$color_text_nav_active: #858585;

$color_mhome: #f98100;
$color_minfo: #f98100;
$color_mmitmachen: #dd4931;
$color_mmusik: #245773;
$color_mprogramm: #19735f;
$color_mprojekte: #96b15a;

$color_hl1: #61686e;

$color_link: #61686e;
$color_link_visited: $color_link;
// $color_link_hover: #dd4931;
$color_link_hover: $color_link;
$color_link_focus: $color_link_hover;
$color_link_active: $color_link_hover;

$color_line_grey: #e5e5e5;

$color_label_red: #dd4931;
$color_label_orange: #f98100;
$color_label_green: #19735f;


//
// Partials to be shared with all .scss files.
////////////////////////////////////////
@import "compass";                  // See http://compass-style.org
@import "compass/reset/utilities";
@import "mixins";                   // Import our custom mixins last.
