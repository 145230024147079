.ui-widget {
	font-family: $main_font;
}

ul[data-sa-theme="minimal"].ui-menu {
	right: 0 !important;
	left: auto !important;
	border: none;
	border-radius: 0;
	background: rgba(255,255,255,1);
	box-shadow: -2px 2px 8px 0 #61686e;
	.ui-autocomplete-field-group {
		border: none;
		width: 100%;
		clear: left;
		// float: none;
		padding: 5px;
		@include font-size(16);
		font-weight: bold;
		// border: none;
		color: $color_text_meta;
		right: 0;
		// background: $color_minfo;
		// color: #fff;
		border-bottom: 1px solid $color_line_grey;
		text-transform: uppercase;
		&.all_results.ui-menu-divider {
			margin: 0;
			padding: 0;
			border: none;
			border-bottom: 1px solid $color_line_grey;
			& + .ui-menu-item-first a {
				color: $color_text_meta;
				font-weight: 600;
			}
		}
	}

	.ui-menu-item {
		@include font-size(16);
		cursor: pointer;
		border-bottom: 1px solid $color_line_grey;
	}

	.ui-menu-item a {
		margin: 0;
	}

	.ui-menu-item-first {
		border: none;
	}

	.ui-autocomplete-field-name {
		display: none;
	}

	.ui-state-focus {
		border-radius: 0;
		border: none;
		// background: $color_bg_grey;
		background: #ebebeb;
	}
}