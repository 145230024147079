@charset "UTF-8";
.freefm-front .freefm-front-contenttop .panel-pane:first-child h2.pane-title, .freefm-front .freefm-front-midmain .panel-pane:first-child h2.pane-title, .square-title {
  font-size: 25px;
  line-height: 35px;
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 0;
  padding: 0;
  width: 100px;
  height: 100px;
  color: #fff;
  background: #61686e;
  line-height: 100px;
  vertical-align: middle;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center; }

.freefm-front .freefm-front-contenttop .panel-pane:first-child h2.pane-title, .freefm-front .freefm-front-midmain .panel-pane:first-child h2.pane-title {
  top: -50px; }

.freefm-front .freefm-front-contenttop .panel-pane:first-child .pane-content, .freefm-front .freefm-front-midmain .panel-pane:first-child .pane-content {
  margin-top: -50px; }

#page,
#persistent {
  width: 1000px;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden; }
  @media only screen and (max-width: 1000px) {
    #page,
    #persistent {
      width: 100%; } }
  @media only screen and (max-width: 600px) {
    #page,
    #persistent {
      width: 100%;
      margin: 0; } }

body, #page {
  height: 100%; }

@media only screen and (max-width: 1000px) {
  body {
    background: #fff;
    word-wrap: break-word;
    -webkit-text-size-adjust: none; } }

@media only screen and (max-width: 1000px) {
  html, body {
    margin: 0;
    padding: 0;
    border: 0; } }

@media only screen and (max-width: 600px) {
  html, body {
    margin: 0;
    padding: 0;
    border: 0; } }

#main {
  position: relative; }
  @media only screen and (max-width: 1000px) {
    #main {
      padding-bottom: 0px; } }
  @media only screen and (max-width: 600px) {
    #main {
      padding-bottom: 0px;
      position: static; } }

#content {
  float: left;
  width: 100%;
  margin-right: -100%; }

.sidebar {
  float: left; }

#sidebar-second {
  float: right; }
  @media only screen and (max-width: 1000px) {
    #sidebar-second {
      float: none;
      clear: both;
      width: auto;
      margin: 0px; } }

#footer {
  width: 100%; }
  #footer .region-footer {
    width: 1000px;
    margin: 0 auto; }
    @media only screen and (max-width: 1000px) {
      #footer .region-footer {
        width: 100%;
        margin: 0; } }
    @media only screen and (max-width: 600px) {
      #footer .region-footer {
        width: 100%;
        margin: 0; } }

@media only screen and (max-width: 600px) {
  .sidebar, #sidebar-first, #sidebar-second {
    float: none;
    clear: both;
    width: auto;
    margin: 0px; } }

@media only screen and (max-width: 600px) {
  #content #content-inner.center {
    margin-left: 0px;
    margin-right: 0px; } }

#header,
#footer,
.mission,
.breadcrumb,
.node {
  clear: both; }

.two-sidebars .center,
.sidebar-first .center {
  margin-left: 24.5%; }
  @media only screen and (max-width: 760px) {
    .two-sidebars .center,
    .sidebar-first .center {
      margin-left: 30%; } }

#sidebar-first {
  width: 24.5%;
  margin-right: -245px; }
  @media only screen and (max-width: 760px) {
    #sidebar-first {
      width: 30%;
      margin-right: -300px; } }
  @media only screen and (max-width: 600px) {
    #sidebar-first {
      margin-right: 0;
      width: 100%; } }

.two-sidebars .center,
.sidebar-second .center {
  margin-right: 200px; }

#sidebar-second {
  width: 200px; }
  @media only screen and (max-width: 600px) {
    #sidebar-second {
      margin-right: 0;
      width: 100%; } }

a {
  color: #61686e;
  text-decoration: none; }
  a:visited {
    color: #61686e; }
  a:hover {
    color: #61686e;
    text-decoration: underline; }
  a:focus {
    color: #61686e;
    outline: thin dotted; }
  a:active {
    color: #61686e;
    text-decoration: underline;
    outline: 0; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3em;
  color: #686868;
  padding: 0;
  margin: 0;
  letter-spacing: 0; }
  @media only screen and (max-width: 600px) {
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.5;
      font-size: 16px;
      line-height: 22.4px;
      font-size: 1.6rem;
      line-height: 2.24rem;
      font-weight: 600;
      word-wrap: break-word;
      -webkit-text-size-adjust: none; } }

h1, h2 {
  font-size: 25px;
  line-height: 35px;
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: 600;
  color: #686868;
  text-transform: uppercase;
  border-bottom: 1px solid #d2d2d4;
  padding: 0 0 10px 0;
  margin: 0 0 40px 0; }

h1, h2 {
  border-bottom-color: #61686e; }

h2 {
  margin-top: 40px; }

h2.page-title {
  margin-top: 0; }

h3 {
  font-size: 20px;
  line-height: 28px;
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 1em; }

h4 {
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  margin-bottom: 0.8em; }

h5 {
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-weight: 600;
  margin-bottom: 0; }

h6 {
  font-size: 13px;
  line-height: 18.2px;
  font-size: 1.3rem;
  line-height: 1.82rem;
  font-weight: bold; }

p {
  margin: 0 0 1em 0; }

abbr {
  border-bottom: 1px dotted #666;
  cursor: help;
  white-space: nowrap; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp, tt {
  font-family: "Courier New", "DejaVu Sans Mono", monospace, sans-serif;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem;
  line-height: 1.5em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #efefef;
  display: block;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #aaa; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 2em; }

dd {
  margin: 0 0 0 2em; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

ul {
  list-style-type: disc; }
  ul ul {
    list-style-type: circle; }
    ul ul ul {
      list-style-type: square; }
      ul ul ul ul {
        list-style-type: circle; }

ol {
  list-style-type: decimal; }
  ol ol {
    list-style-type: lower-alpha; }
    ol ol ol {
      list-style-type: decimal; }

@media only screen and (max-width: 1000px) {
  img {
    border: 0;
    max-width: 100%; } }

@media only screen and (max-width: 1000px) {
  table {
    width: auto;
    border-collapse: collapse;
    border-spacing: 0; } }

* {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(../js/boxsizing.htc); }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

#skip a {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px; }
  #skip a:active, #skip a:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static !important;
    width: auto; }

div.view div.views-admin-links {
  width: auto; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }
  .ir br {
    display: none; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px; }
  .visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static !important;
    width: auto; }

.invisible {
  visibility: hidden; }

.clearfix, #header #navigation {
  *zoom: 1; }
  .clearfix:before, #header #navigation:before {
    content: "";
    display: table; }
  .clearfix:after, #header #navigation:after {
    content: "";
    display: table;
    clear: both; }

.clearleft, .clearl, .cleft {
  clear: left; }

.clearright, .clearr, .cright {
  clear: right; }

.clear, .clearboth, .clearall {
  clear: both; }

.floatleft, .fleft, .floatl {
  display: inline;
  float: left;
  margin: 0 10px 5px 0; }

.floatright, .fright, .floatr {
  display: inline;
  float: right;
  margin: 0 0 5px 10px; }

#system-themes-form img {
  width: 100px; }

.form-item .description {
  font-style: italic;
  font-size: 12px;
  line-height: 16.8px;
  font-size: 1.2rem;
  line-height: 1.68rem;
  margin-top: 5px;
  color: #777; }

#edit-delete {
  color: #c00; }

div.messages {
  padding: 9px;
  margin: 1em 0;
  color: #036;
  background: #bdf;
  border: 1px solid #ace; }

div.warning {
  color: #840;
  background: #fe6;
  border-color: #ed5; }

div.error {
  color: white;
  background: #e63;
  border-color: #d52; }

div.status {
  color: #360;
  background: #cf8;
  border-color: #bbee77; }

html {
  font-size: 62.5%;
  /* Sets up the Base 10 stuff */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: "Source Sans Pro", "Helvetica Neue", helvetica, Arial, sans-serif;
  color: #282828; }

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #e3e3e3;
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  font-family: "Source Sans Pro", "Helvetica Neue", helvetica, Arial, sans-serif; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid,
textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 99%; }

tbody {
  border: none; }

td {
  vertical-align: top; }

tr.even td,
tr.odd td {
  padding: 10px 5px; }

tr.odd {
  background-color: white; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

.wireframe-mode #header,
.wireframe-mode #content-top,
.wireframe-mode #content-top > *,
.wireframe-mode #content-header,
.wireframe-mode #content-header > *,
.wireframe-mode #content-area,
.wireframe-mode #content-area > *,
.wireframe-mode #content-bottom,
.wireframe-mode #content-bottom > *,
.wireframe-mode #primary,
.wireframe-mode #secondary,
.wireframe-mode #sidebar-left-inner,
.wireframe-mode #sidebar-right-inner,
.wireframe-mode .block {
  padding: 2px;
  border: 1px solid #bbbbbb; }

.wireframe-mode .node {
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 10px; }

.wireframe-mode .block {
  margin-bottom: 3px; }

.wireframe-mode #content-top,
.wireframe-mode #content-header,
.wireframe-mode #content-area,
.wireframe-mode #content-bottom {
  margin: 0 3px 3px; }

.wireframe-mode #footer {
  margin-top: 3px;
  padding: 2px;
  border: 1px solid #cccccc; }

.wireframe-mode .with-navigation #content,
.wireframe-mode .with-navigation .sidebar {
  margin-top: 50px; }

.wireframe-mode #navigation {
  height: 50px; }

/*****
 * Content: Editor classes.
 ****/
p.highlighted {
  color: #b09d8a;
  font-size: 24px;
  line-height: 33.6px;
  font-size: 2.4rem;
  line-height: 3.36rem;
  font-style: italic; }

.columns-wrapper {
  overflow: hidden;
  *zoom: 1;
  margin-bottom: 1em; }
  .columns-wrapper .col {
    margin-bottom: 1em; }
  @media only screen and (min-width: 600px) {
    .columns-wrapper > .col {
      width: 50%;
      float: left;
      margin-bottom: 0;
      padding-left: 1em; }
    .columns-wrapper .col:first-child {
      padding-left: 0; }
    .columns-wrapper.threecol > .col {
      width: 33.3%; }
    .columns-wrapper.twocol-wide > .col:first-child {
      width: 33.3%; }
    .columns-wrapper.twocol-wide > .col:last-child {
      width: 66.6%; }
    .columns-wrapper.twocol-wide2 > .col:first-child {
      width: 66.6%; }
    .columns-wrapper.twocol-wide2 > .col:last-child {
      width: 33.3%; } }

.columns-wrapper.twocol-auto {
  display: flex; }
  .columns-wrapper.twocol-auto .col {
    flex: 1 1 auto;
    float: none;
    width: auto; }
  .columns-wrapper.twocol-auto .col:first-child {
    flex: 0 0 auto; }

p.highlight,
p.citation-block {
  font-size: 1.3em;
  line-height: 1.4em;
  font-style: italic;
  color: #b09d8a;
  margin: .5em 0 1em 0; }

p.caption {
  color: #b09d8a;
  font-weight: 600; }
  p.caption img {
    margin-bottom: 5px; }

span.inline-right {
  margin: 10px 0 10px 10px;
  display: block;
  float: right; }

span.inline-left {
  margin: 10px 10px 10px 0;
  display: block;
  float: left; }

span.smallbold {
  font-size: 0.9em;
  font-weight: bold; }

h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 30.8px;
  font-size: 2.2rem;
  line-height: 3.08rem;
  color: #282828; }

h3 strong {
  font-size: 0.83em; }

h3 a, a h3 {
  font-style: italic; }

.item-list ul.pager {
  text-align: center; }
  .item-list ul.pager li {
    margin: 0 8px 0 0;
    padding: 0;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    overflow: hidden; }
  .item-list ul.pager li, .item-list ul.pager a, .item-list ul.pager a:visited {
    color: #383737; }
  .item-list ul.pager a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px; }
    .item-list ul.pager a:hover {
      text-decoration: none; }
  .item-list ul.pager .pager-current,
  .item-list ul.pager .pager-item:hover,
  .item-list ul.pager .pager-next:hover,
  .item-list ul.pager .pager-previous:hover,
  .item-list ul.pager .pager-first:hover,
  .item-list ul.pager .pager-last:hover {
    background-color: #282828; }
    .page-freefm-info .item-list ul.pager .pager-current, .page-freefm-info
    .item-list ul.pager .pager-item:hover, .page-freefm-info
    .item-list ul.pager .pager-next:hover, .page-freefm-info
    .item-list ul.pager .pager-previous:hover, .page-freefm-info
    .item-list ul.pager .pager-first:hover, .page-freefm-info
    .item-list ul.pager .pager-last:hover {
      background-color: #f98100; }
    .page-freefm-mediathek .item-list ul.pager .pager-current, .page-freefm-mediathek
    .item-list ul.pager .pager-item:hover, .page-freefm-mediathek
    .item-list ul.pager .pager-next:hover, .page-freefm-mediathek
    .item-list ul.pager .pager-previous:hover, .page-freefm-mediathek
    .item-list ul.pager .pager-first:hover, .page-freefm-mediathek
    .item-list ul.pager .pager-last:hover {
      background-color: #f98100; }
    .page-freefm-mitmachen .item-list ul.pager .pager-current, .page-freefm-mitmachen
    .item-list ul.pager .pager-item:hover, .page-freefm-mitmachen
    .item-list ul.pager .pager-next:hover, .page-freefm-mitmachen
    .item-list ul.pager .pager-previous:hover, .page-freefm-mitmachen
    .item-list ul.pager .pager-first:hover, .page-freefm-mitmachen
    .item-list ul.pager .pager-last:hover {
      background-color: #dd4931; }
    .page-freefm-musik .item-list ul.pager .pager-current, .page-freefm-musik
    .item-list ul.pager .pager-item:hover, .page-freefm-musik
    .item-list ul.pager .pager-next:hover, .page-freefm-musik
    .item-list ul.pager .pager-previous:hover, .page-freefm-musik
    .item-list ul.pager .pager-first:hover, .page-freefm-musik
    .item-list ul.pager .pager-last:hover {
      background-color: #245773; }
    .page-freefm-programm .item-list ul.pager .pager-current, .page-freefm-programm
    .item-list ul.pager .pager-item:hover, .page-freefm-programm
    .item-list ul.pager .pager-next:hover, .page-freefm-programm
    .item-list ul.pager .pager-previous:hover, .page-freefm-programm
    .item-list ul.pager .pager-first:hover, .page-freefm-programm
    .item-list ul.pager .pager-last:hover {
      background-color: #19735f; }
    .page-freefm-projekte .item-list ul.pager .pager-current, .page-freefm-projekte
    .item-list ul.pager .pager-item:hover, .page-freefm-projekte
    .item-list ul.pager .pager-next:hover, .page-freefm-projekte
    .item-list ul.pager .pager-previous:hover, .page-freefm-projekte
    .item-list ul.pager .pager-first:hover, .page-freefm-projekte
    .item-list ul.pager .pager-last:hover {
      background-color: #96b15a; }
    .item-list ul.pager .pager-current, .item-list ul.pager .pager-current a,
    .item-list ul.pager .pager-item:hover,
    .item-list ul.pager .pager-item:hover a,
    .item-list ul.pager .pager-next:hover,
    .item-list ul.pager .pager-next:hover a,
    .item-list ul.pager .pager-previous:hover,
    .item-list ul.pager .pager-previous:hover a,
    .item-list ul.pager .pager-first:hover,
    .item-list ul.pager .pager-first:hover a,
    .item-list ul.pager .pager-last:hover,
    .item-list ul.pager .pager-last:hover a {
      color: #fff; }
  .item-list ul.pager .pager-first,
  .item-list ul.pager .pager-last {
    display: none; }
  .item-list ul.pager .pager-first a,
  .item-list ul.pager .pager-last a,
  .item-list ul.pager .pager-previous a,
  .item-list ul.pager .pager-next a {
    text-indent: 9999px;
    overflow: hidden; }
  .item-list ul.pager .pager-next,
  .item-list ul.pager .pager-previous {
    background-position: center center;
    background-image: url("../images/elements/arrow-widget-grey@1x.png");
    background-size: 15px 28px;
    background-repeat: no-repeat; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .item-list ul.pager .pager-next,
      .item-list ul.pager .pager-previous {
        background-image: url("../images/elements/arrow-widget-grey@1x.png"); } }
    .no-mediaqueries .item-list ul.pager .pager-next, .no-mediaqueries
    .item-list ul.pager .pager-previous {
      background-image: url("../images/elements/arrow-widget-grey@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .item-list ul.pager .pager-next,
      .item-list ul.pager .pager-previous {
        background-image: url("../images/elements/arrow-widget-grey@2x.png"); } }
    .item-list ul.pager .pager-next:hover,
    .item-list ul.pager .pager-previous:hover {
      background-image: url("../images/elements/arrow-widget-white@1x.png");
      background-size: 15px 28px;
      background-repeat: no-repeat; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .item-list ul.pager .pager-next:hover,
        .item-list ul.pager .pager-previous:hover {
          background-image: url("../images/elements/arrow-widget-white@1x.png"); } }
      .no-mediaqueries .item-list ul.pager .pager-next:hover, .no-mediaqueries
      .item-list ul.pager .pager-previous:hover {
        background-image: url("../images/elements/arrow-widget-white@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .item-list ul.pager .pager-next:hover,
        .item-list ul.pager .pager-previous:hover {
          background-image: url("../images/elements/arrow-widget-white@2x.png"); } }
  .item-list ul.pager .pager-next {
    transform: rotate(180deg); }

/**
 * Select2
 */
.select2-container.select2-container--default .select2-selection--single {
  border-radius: 0;
  border-color: #8c8c8c;
  height: 33px;
  font-size: 20px;
  line-height: 28px;
  font-size: 2rem;
  line-height: 2.8rem;
  font-style: italic; }

.select2-container.select2-container--default .select2-selection__rendered {
  border-radius: 0;
  height: 25px;
  padding-top: 2px; }

.select2-container.select2-container--default .select2-dropdown {
  border-radius: 0; }
  .select2-container.select2-container--default .select2-dropdown .select2-search__field {
    border: none;
    outline: none;
    box-shadow: none; }
  .select2-container.select2-container--default .select2-dropdown .select2-results__option--highlighted[aria-selected] {
    background-color: #f98100; }

.select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
  background-image: url("../images/elements/arrow-mediathek@1x.png");
  background-size: 10px 16px;
  background-repeat: no-repeat;
  width: 10px;
  height: 16px;
  transform: rotate(270deg);
  top: 8px;
  right: 9px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
      background-image: url("../images/elements/arrow-mediathek@1x.png"); } }
  .no-mediaqueries .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
    background-image: url("../images/elements/arrow-mediathek@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
      background-image: url("../images/elements/arrow-mediathek@2x.png"); } }
  .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none !important; }

.select2-container.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  transform: rotate(90deg); }

ul.primary {
  border: none;
  margin: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  opacity: .65;
  transition: opacity .2s linear; }
  ul.primary:hover {
    opacity: .8; }
  ul.primary li a {
    border: none;
    border-radius: 10px;
    background: #eee;
    color: #333;
    float: right;
    transition: color .2s ease-out, background-color .2s ease-out;
    font-size: 14px; }
    ul.primary li a:hover {
      background: #222;
      color: #f0f0f0; }
  ul.primary li.active a {
    background: #ddd;
    border: none;
    color: #111; }

input::-ms-clear {
  display: none; }

#page {
  padding-top: 110px;
  position: relative; }
  @media only screen and (max-width: 600px) {
    #page {
      padding-top: 60px; } }

#page-intro {
  width: 100%;
  height: 10px;
  background-color: #111c1f;
  z-index: 90;
  position: absolute;
  left: 0;
  top: 0; }
  @media only screen and (max-width: 600px) {
    #page-intro {
      display: none !important; } }

body.admin-menu.adminimal-menu:before {
  height: 19px; }

#header {
  overflow: hidden;
  *zoom: 1;
  height: 110px;
  width: 1000px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 80;
  border-top: 10px solid transparent;
  overflow: visible; }
  .mobile-nav #header {
    z-index: 200; }
  @media only screen and (max-width: 1000px) {
    #header {
      width: 100%; } }
  @media only screen and (max-width: 600px) {
    #header {
      width: 100%;
      padding-top: 0; } }
  #header #logo {
    width: 100px;
    height: 100px;
    float: left;
    z-index: 90;
    background-color: #fff;
    padding: 6px 0 0 14px; }
    @media only screen and (max-width: 600px) {
      #header #logo {
        display: none; } }
  #header #header-top {
    overflow: hidden;
    *zoom: 1;
    overflow: visible;
    height: 50px;
    background: #e3e3e3; }
    @media only screen and (max-width: 600px) {
      #header #header-top {
        height: 70px; } }
  #header #site-name {
    float: left;
    height: 50px;
    padding: 0;
    margin: 0;
    font-size: 28px;
    line-height: 39.2px;
    font-size: 2.8rem;
    line-height: 3.92rem; }
    #header #site-name a {
      margin-left: 25px;
      margin-top: 14px;
      display: block;
      width: 224px;
      height: 22px;
      background-image: url("../images/elements/freeFM-wordmark@1x.png");
      background-size: 224px 22px; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        #header #site-name a {
          background-image: url("../images/elements/freeFM-wordmark@1x.png"); } }
      .no-mediaqueries #header #site-name a {
        background-image: url("../images/elements/freeFM-wordmark@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        #header #site-name a {
          background-image: url("../images/elements/freeFM-wordmark@2x.png");
          background-size: 224px 22px; } }
      #header #site-name a span {
        display: none; }
      @media only screen and (max-width: 600px) {
        #header #site-name a {
          margin-top: 21px; } }
      @media only screen and (max-width: 400px) {
        #header #site-name a {
          width: 203px;
          height: 20px;
          background-size: 203px 20px;
          margin-left: 10px;
          margin-top: 26px; } }
      @media only screen and (max-width: 360px) {
        #header #site-name a {
          width: 163px;
          height: 16px;
          background-size: 163px 16px;
          margin-left: 5px; } }
  #header #loginlink {
    display: block;
    color: #999a9b;
    font-size: 18px;
    line-height: 25.2px;
    font-size: 1.8rem;
    line-height: 2.52rem;
    text-transform: uppercase;
    font-weight: 600;
    display: none; }
    @media only screen and (min-width: 600px) {
      #header #loginlink {
        display: block;
        position: absolute;
        right: 260px;
        top: 12px; } }
  #header #header-region {
    float: right;
    height: 50px;
    position: relative; }
  #header #search-block-form .form-type-textfield {
    font-size: 18px;
    line-height: 25.2px;
    font-size: 1.8rem;
    line-height: 2.52rem; }
    #header #search-block-form .form-type-textfield label {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static !important;
      width: auto;
      position: absolute !important;
      left: 15px;
      top: 13px;
      text-transform: uppercase;
      font-weight: 600;
      color: #959899; }
    #header #search-block-form .form-type-textfield .form-text {
      padding: 0;
      margin: 0;
      height: 50px;
      border: none;
      border-radius: 0;
      background-color: #fff;
      width: 240px;
      font-weight: 500;
      background-image: url("../images/elements/lupe@1x.png");
      background-size: 19px 19px;
      background-repeat: no-repeat;
      background-position: 210px center;
      padding: 15px;
      color: #282828; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        #header #search-block-form .form-type-textfield .form-text {
          background-image: url("../images/elements/lupe@1x.png"); } }
      .no-mediaqueries #header #search-block-form .form-type-textfield .form-text {
        background-image: url("../images/elements/lupe@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        #header #search-block-form .form-type-textfield .form-text {
          background-image: url("../images/elements/lupe@2x.png"); } }
  #header #search-block-form #edit-submit {
    display: none; }
  #header #search-block-form .form-actions input[type="submit"] {
    display: none; }
  #header #block-search-form.mobile {
    position: absolute;
    width: 100%;
    top: 70px;
    height: 50px;
    z-index: 100; }
    #header #block-search-form.mobile .form-text {
      width: 70%;
      float: left; }
    #header #block-search-form.mobile #edit-submit {
      display: block;
      float: right;
      width: 30%;
      background-color: #f1f1f1;
      border: none;
      padding: 15px;
      height: 50px;
      text-transform: uppercase; }
  #header .mobile-button:after {
    display: block;
    float: left;
    width: 50px;
    height: 50px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    line-height: 36.4px;
    font-size: 2.6rem;
    line-height: 3.64rem;
    font-weight: bold;
    line-height: 44px; }
  #header #header-search-button:after {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    content: ' ';
    background-image: url("../images/icons/icn-search.svg");
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: 18px 16px; }
    @media only screen and (max-width: 600px) {
      #header #header-search-button:after {
        background-color: transparent; } }
  #header #header-search-button.pushed:after {
    background-image: none;
    content: '✕'; }
  #header #header-menu-button:after {
    background: #e3e3e3;
    content: '☰'; }
  #header #header-menu-button.pushed:after {
    content: '✕'; }
  #header #navigation {
    margin-left: 0;
    width: 100%;
    height: 50px;
    padding-left: 100px;
    background-color: rgba(37, 38, 41, 0.8); }
    @media only screen and (max-width: 1000px) {
      #header #navigation {
        clear: both;
        display: none; }
        #header #navigation.mobile {
          display: block;
          margin: 0;
          padding: 0;
          position: absolute;
          z-index: 2000;
          top: 70px;
          height: auto;
          width: 100%;
          background-color: rgba(53, 60, 62, 0.95); }
          #header #navigation.mobile ul {
            display: block; }
            #header #navigation.mobile ul li a {
              float: none;
              text-align: center;
              margin: 0;
              padding: 20px;
              width: 100%;
              font-size: 26px;
              line-height: 36.4px;
              font-size: 2.6rem;
              line-height: 3.64rem;
              font-weight: bold; } }
    #header #navigation ul {
      float: none;
      margin: 0;
      padding: 0 0 0 18px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between; }
      #header #navigation ul li {
        list-style: none; }
        #header #navigation ul li:nth-last-child(3) {
          flex-grow: 2; }
        #header #navigation ul li a {
          display: block;
          padding: 11px 12px;
          margin-right: 10px;
          font-size: 18px;
          line-height: 25.2px;
          font-size: 1.8rem;
          line-height: 2.52rem;
          color: #f0f0f1;
          text-transform: uppercase;
          font-weight: 600; }
          @media only screen and (max-width: 920px) {
            #header #navigation ul li a {
              padding: 11px 6px 11px 12px;
              margin-right: 10px; } }
          @media only screen and (max-width: 750px) {
            #header #navigation ul li a {
              letter-spacing: -0.005em;
              margin-right: 5px; } }
          @media only screen and (max-width: 700px) {
            #header #navigation ul li a {
              font-size: 16px;
              line-height: 22.4px;
              font-size: 1.6rem;
              line-height: 2.24rem; } }
          @media only screen and (max-width: 650px) {
            #header #navigation ul li a {
              letter-spacing: -0.01em;
              margin-right: 0;
              padding: 11px 3px 11px 12px; } }
          #header #navigation ul li a.active, #header #navigation ul li a:hover {
            color: #858585;
            text-decoration: none; }
          #header #navigation ul li a[href="/#kontakt"].active, #header #navigation ul li a[href="/#termine"].active {
            color: #f0f0f1; }
            #header #navigation ul li a[href="/#kontakt"].active:hover, #header #navigation ul li a[href="/#termine"].active:hover {
              color: #858585; }

@media only screen and (max-width: 600px) {
  #main {
    margin-top: 50px; } }

/*****
 * Base styles: Sidebar.
 ****/
#sidebar-first .block h2.block-title, .page-freefm-projekte #sidebar-first .block:first-child h2.block-title, .block-menu-block.mobile h2.block-title, .block-sectiontitle h2,
.page-freefm-projekte .block-sectiontitle h2.block-title, #mediathek-button {
  font-size: 25px;
  line-height: 35px;
  font-size: 2.5rem;
  line-height: 3.5rem;
  background: #686868;
  text-transform: uppercase;
  text-align: center;
  height: 50px;
  padding: 6px;
  margin: 0;
  font-weight: 600;
  border: none; }
  #sidebar-first .block h2.block-title, .page-freefm-projekte #sidebar-first .block:first-child h2.block-title, .block-menu-block.mobile h2.block-title, .block-sectiontitle h2,
  .page-freefm-projekte .block-sectiontitle h2.block-title, #mediathek-button, #sidebar-first .block h2.block-title a, .page-freefm-projekte #sidebar-first .block:first-child h2.block-title a, .block-menu-block.mobile h2.block-title a, .block-sectiontitle h2 a, .page-freefm-projekte .block-sectiontitle h2.block-title a, #mediathek-button a {
    color: #fff; }

.page-freefm-projekte #sidebar-first .block h2.block-title {
  background: #cfcecb;
  color: #fff;
  height: 42px;
  text-align: left;
  padding-left: 30px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  line-height: 30px; }

#sidebar-first .block, .page-freefm-projekte #sidebar-first .block:first-child {
  margin-bottom: 25px; }
  #sidebar-first .block h2.block-title, .page-freefm-projekte #sidebar-first .block:first-child h2.block-title {
    margin-bottom: 25px; }
  #sidebar-first .block ul.menu, .page-freefm-projekte #sidebar-first .block:first-child ul.menu,
  #sidebar-first .block div.item-list ul,
  .page-freefm-projekte #sidebar-first .block:first-child div.item-list ul {
    padding: 0;
    margin: 0; }
    #sidebar-first .block ul.menu li, .page-freefm-projekte #sidebar-first .block:first-child ul.menu li,
    #sidebar-first .block div.item-list ul li,
    .page-freefm-projekte #sidebar-first .block:first-child div.item-list ul li {
      list-style: none;
      margin: 0;
      padding: 0 30px;
      font-size: 18px;
      line-height: 25.2px;
      font-size: 1.8rem;
      line-height: 2.52rem; }
      #sidebar-first .block ul.menu li a, .page-freefm-projekte #sidebar-first .block:first-child ul.menu li a,
      #sidebar-first .block div.item-list ul li a,
      .page-freefm-projekte #sidebar-first .block:first-child div.item-list ul li a {
        display: block;
        padding-top: 7px;
        padding-bottom: 5px;
        border-top: 1px solid #bababa;
        font-weight: 600; }
    #sidebar-first .block ul.menu li.active,
    #sidebar-first .block ul.menu li.active-trail,
    #sidebar-first .block div.item-list ul li.active,
    #sidebar-first .block div.item-list ul li.active-trail {
      background-color: #fff; }
      #sidebar-first .block ul.menu li.active a,
      #sidebar-first .block ul.menu li.active-trail a,
      #sidebar-first .block div.item-list ul li.active a,
      #sidebar-first .block div.item-list ul li.active-trail a {
        border-top: none; }
      #sidebar-first .block ul.menu li.active + li a,
      #sidebar-first .block ul.menu li.active-trail + li a,
      #sidebar-first .block div.item-list ul li.active + li a,
      #sidebar-first .block div.item-list ul li.active-trail + li a {
        border-top: none; }
    #sidebar-first .block ul.menu .views-field-title a.active,
    #sidebar-first .block div.item-list ul .views-field-title a.active {
      background-color: #fff;
      color: #8c8c8c;
      margin: 0 -30px;
      padding-left: 30px;
      padding-right: 30px;
      border-top: none; }
    
    #sidebar-first .block ul.menu li.active-trail + li a,
    #sidebar-first .block div.item-list ul li.active-trail + li a {
      border-top: none; }

.page-freefm-projekte #sidebar-first .block {
  margin-bottom: 0; }
  .page-freefm-projekte #sidebar-first .block ul.menu li:first-child a,
  .page-freefm-projekte #sidebar-first .block div.item-list ul li:first-child a {
    border-top: none; }

#sidebar-first {
  background: #e9e9e9; }
  #sidebar-first a {
    color: #282828; }
  #sidebar-first .block:first-child h2.block-title {
    margin-bottom: 50px; }

/*****
 * Base styles: Content.
 ****/
#content-inner {
  background-color: #fff;
  padding: 30px;
  margin-top: 50px;
  position: relative; }
  @media only screen and (max-width: 600px) {
    #content-inner {
      padding: 20px; } }

#content-inner-top {
  position: relative;
  margin-top: -32px;
  min-height: 32px; }
  #content-inner-top ul.tabs {
    position: relative;
    top: -22px;
    padding: 0;
    margin: 0;
    border: none; }

/*****
 * Base styles: Footer.
 ****/
#footer {
  background: #252629;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  *zoom: 1; }
  #footer .footer-info {
    max-width: 1000px;
    margin: 0 auto; }
  @media only screen and (max-width: 1000px) {
    #footer {
      padding-left: 25px;
      padding-right: 25px; } }
  #footer #block-user-login {
    overflow: hidden;
    *zoom: 1;
    float: left;
    position: relative;
    padding-bottom: 30px; }
    #footer #block-user-login h2.block-title {
      display: none; }
    #footer #block-user-login .form-item, #footer #block-user-login .form-actions {
      float: left;
      position: relative;
      margin: 18px 18px 18px 0; }
    #footer #block-user-login div.item-list {
      position: absolute;
      font-size: 14px;
      line-height: 19.6px;
      font-size: 1.4rem;
      line-height: 1.96rem;
      top: 60px; }
      #footer #block-user-login div.item-list ul, #footer #block-user-login div.item-list li {
        margin: 0;
        padding: 0;
        display: inline; }
    #footer #block-user-login input {
      border: 1px solid #5e656b;
      background: transparent;
      padding: 5px 10px;
      border-radius: 0;
      color: #61686e;
      font-size: 16px;
      line-height: 22.4px;
      font-size: 1.6rem;
      line-height: 2.24rem; }
    #footer #block-user-login input.form-submit {
      background: #61686e;
      color: #252629; }
    #footer #block-user-login label {
      display: none; }
      #footer #block-user-login label .form-required {
        display: none; }

.footer-info {
  padding: 20px 0;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem;
  overflow: hidden;
  *zoom: 1;
  position: relative; }
  @media only screen and (min-width: 1000px) {
    .footer-info {
      padding: 20px 30px 20px 0; } }
  .footer-info .col {
    text-align: center; }
    @media only screen and (min-width: 600px) {
      .footer-info .col {
        width: 33%;
        float: left; }
        .footer-info .col:nth-child(1) {
          text-align: left; }
        .footer-info .col:last-child {
          text-align: right; } }
    .footer-info .col:nth-child(2) a {
      font-weight: bold;
      text-transform: uppercase; }
      @media only screen and (min-width: 1000px) {
        .footer-info .col:nth-child(2) a {
          font-size: 21px; } }
    .footer-info .col:last-child a {
      background-image: url("../images/elements/arrow_impressum@1x.png");
      background-size: 14px 20px;
      padding-right: 30px;
      background-position: center right;
      background-repeat: no-repeat; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .footer-info .col:last-child a {
          background-image: url("../images/elements/arrow_impressum@1x.png"); } }
      .no-mediaqueries .footer-info .col:last-child a {
        background-image: url("../images/elements/arrow_impressum@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .footer-info .col:last-child a {
          background-image: url("../images/elements/arrow_impressum@2x.png");
          background-size: 14px 20px; } }
  .footer-info, .footer-info a {
    color: #b09d8a; }
  .footer-info:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-image: -owg-linear-gradient(to right, rgba(62, 62, 62, 0), #3e3e3e 20%, #3e3e3e 80%, rgba(62, 62, 62, 0));
    background-image: -webkit-linear-gradient(to right, rgba(62, 62, 62, 0), #3e3e3e 20%, #3e3e3e 80%, rgba(62, 62, 62, 0));
    background-image: -moz-linear-gradient(to right, rgba(62, 62, 62, 0), #3e3e3e 20%, #3e3e3e 80%, rgba(62, 62, 62, 0));
    background-image: -o-linear-gradient(to right, rgba(62, 62, 62, 0), #3e3e3e 20%, #3e3e3e 80%, rgba(62, 62, 62, 0));
    background-image: linear-gradient(to right, rgba(62, 62, 62, 0), #3e3e3e 20%, #3e3e3e 80%, rgba(62, 62, 62, 0)); }

.freefm-social {
  overflow: hidden;
  *zoom: 1;
  text-align: right;
  padding-top: 18px;
  padding-right: 30px; }
  .freefm-social a {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-left: 8px; }
    .freefm-social a span {
      display: none; }
  .freefm-social br {
    display: none; }
  .freefm-social .fm-facebook {
    background: url("../images/icons/icn-facebook.svg"); }
  .freefm-social .fm-twitter {
    background: url("../images/icons/icn-twitter.svg"); }
  .freefm-social .fm-soundcloud {
    background: url("../images/icons/icn-soundcloud.svg"); }
  .freefm-social .fm-youtube {
    background: url("../images/icons/icn-youtube.svg"); }

/*****
 * Base styles: Mobile-specific.
 ****/
.block-menu-block.mobile {
  width: 100%;
  margin-top: -50px;
  position: relative;
  top: 50px;
  padding-bottom: 10px;
  overflow: hidden;
  *zoom: 1; }
  .block-menu-block.mobile h2.block-title {
    width: auto;
    max-width: 60%;
    text-align: left;
    padding-left: 20px; }
  .block-menu-block.mobile ul {
    margin: 10px;
    padding: 0; }
    .block-menu-block.mobile ul li {
      list-style: none;
      margin: 0;
      padding: 0; }
      .block-menu-block.mobile ul li a {
        display: block;
        float: left;
        padding: 5px 10px;
        color: #000;
        font-weight: 600; }
        .block-menu-block.mobile ul li a.active {
          background: #fff; }

/*****
 * Page Section Teasers (in Header).
 ****/
.page-freefm-teaser {
  position: relative; }
  .page-freefm-teaser #page-top-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 1000px;
    height: 490px;
    content: '';
    display: block;
    overflow: hidden; }
  .page-freefm-teaser #page-top {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 1000px 430px;
    top: 60px;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute; }
  .page-freefm-teaser #section-teaser {
    height: 330px;
    position: relative; }
    .page-freefm-teaser #section-teaser .block-block {
      position: absolute;
      left: 56%;
      width: 40%;
      top: 130px;
      height: 200px;
      font-size: 40px;
      line-height: 56px;
      font-size: 4rem;
      line-height: 5.6rem;
      line-height: 50px;
      color: #fff;
      text-transform: uppercase;
      opacity: 0.9; }
      @media only screen and (max-width: 600px) {
        .page-freefm-teaser #section-teaser .block-block {
          left: 80px !important;
          width: auto;
          font-size: 30px;
          line-height: 42px;
          font-size: 3rem;
          line-height: 4.2rem; } }
      @media only screen and (max-width: 460px) {
        .page-freefm-teaser #section-teaser .block-block {
          left: 30px !important;
          width: auto;
          font-size: 25px;
          line-height: 35px;
          font-size: 2.5rem;
          line-height: 3.5rem; } }
    .page-freefm-teaser #section-teaser .block-sectiontitle h2 {
      width: 245px;
      position: absolute;
      bottom: -50px;
      color: #fff;
      left: 0; }
  .page-freefm-teaser #content {
    background-image: url("../images/elements/teaser-timeline-overlay@1x.png");
    background-size: 1237px 54px;
    background-repeat: no-repeat; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .page-freefm-teaser #content {
        background-image: url("../images/elements/teaser-timeline-overlay@1x.png"); } }
    .no-mediaqueries .page-freefm-teaser #content {
      background-image: url("../images/elements/teaser-timeline-overlay@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .page-freefm-teaser #content {
        background-image: url("../images/elements/teaser-timeline-overlay@2x.png");
        background-size: 1237px 54px; } }
  .page-freefm-teaser #content-inner {
    margin-top: 100px; }
    @media only screen and (max-width: 600px) {
      .page-freefm-teaser #content-inner {
        margin-top: 50px; } }

.page-freefm-info #page-top {
  background-image: url("../images/teaser/INFOTMP@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-info #page-top {
      background-image: url("../images/teaser/INFOTMP@1x.jpg"); } }
  .no-mediaqueries .page-freefm-info #page-top {
    background-image: url("../images/teaser/INFOTMP@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-info #page-top {
      background-image: url("../images/teaser/INFOTMP@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-info h1, .page-freefm-info h2, .page-freefm-info a:hover {
  color: #f98100; }

.page-freefm-info #sidebar-first .block h2.block-title,
.page-freefm-info .block-sectiontitle h2.block-title {
  background: #f98100; }

.page-freefm-info #section-teaser .block-block {
  padding-top: 54px;
  background-image: url("../images/icons/01_info_white@1x.png");
  background-size: 42px 54px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-info #section-teaser .block-block {
      background-image: url("../images/icons/01_info_white@1x.png"); } }
  .no-mediaqueries .page-freefm-info #section-teaser .block-block {
    background-image: url("../images/icons/01_info_white@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-info #section-teaser .block-block {
      background-image: url("../images/icons/01_info_white@2x.png"); } }

.page-freefm-mitmachen #page-top {
  background-image: url("../images/teaser/04.3_Mitmachen@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-mitmachen #page-top {
      background-image: url("../images/teaser/04.3_Mitmachen@1x.jpg"); } }
  .no-mediaqueries .page-freefm-mitmachen #page-top {
    background-image: url("../images/teaser/04.3_Mitmachen@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-mitmachen #page-top {
      background-image: url("../images/teaser/04.3_Mitmachen@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-mitmachen h1, .page-freefm-mitmachen h2, .page-freefm-mitmachen a:hover {
  color: #dd4931; }

.page-freefm-mitmachen #sidebar-first .block h2.block-title,
.page-freefm-mitmachen .block-sectiontitle h2.block-title {
  background: #dd4931; }

.page-freefm-mitmachen #section-teaser .block-block {
  padding-top: 54px;
  background-image: url("../images/icons/02_mitmachen_white@1x.png");
  background-size: 42px 54px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-mitmachen #section-teaser .block-block {
      background-image: url("../images/icons/02_mitmachen_white@1x.png"); } }
  .no-mediaqueries .page-freefm-mitmachen #section-teaser .block-block {
    background-image: url("../images/icons/02_mitmachen_white@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-mitmachen #section-teaser .block-block {
      background-image: url("../images/icons/02_mitmachen_white@2x.png"); } }

.page-freefm-musik #page-top {
  background-image: url("../images/teaser/04.4_Musik@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-musik #page-top {
      background-image: url("../images/teaser/04.4_Musik@1x.jpg"); } }
  .no-mediaqueries .page-freefm-musik #page-top {
    background-image: url("../images/teaser/04.4_Musik@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-musik #page-top {
      background-image: url("../images/teaser/04.4_Musik@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-musik h1, .page-freefm-musik h2, .page-freefm-musik a:hover {
  color: #245773; }

.page-freefm-musik #sidebar-first .block h2.block-title,
.page-freefm-musik .block-sectiontitle h2.block-title {
  background: #245773; }

.page-freefm-musik #section-teaser .block-block {
  left: 50px;
  width: auto;
  padding-top: 49px;
  background-image: url("../images/icons/03_musik_white@1x.png");
  background-size: 49px 52px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-musik #section-teaser .block-block {
      background-image: url("../images/icons/03_musik_white@1x.png"); } }
  .no-mediaqueries .page-freefm-musik #section-teaser .block-block {
    background-image: url("../images/icons/03_musik_white@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-musik #section-teaser .block-block {
      background-image: url("../images/icons/03_musik_white@2x.png"); } }

.page-freefm-programm #page-top {
  background-image: url("../images/teaser/04.5_Programm@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-programm #page-top {
      background-image: url("../images/teaser/04.5_Programm@1x.jpg"); } }
  .no-mediaqueries .page-freefm-programm #page-top {
    background-image: url("../images/teaser/04.5_Programm@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-programm #page-top {
      background-image: url("../images/teaser/04.5_Programm@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-programm h1, .page-freefm-programm h2, .page-freefm-programm a:hover {
  color: #19735f; }

.page-freefm-programm #sidebar-first .block h2.block-title,
.page-freefm-programm .block-sectiontitle h2.block-title {
  background: #19735f; }

.page-freefm-programm #section-teaser .block-block {
  padding-top: 54px;
  left: 118px;
  width: auto;
  background-image: url("../images/icons/04_programm_white@1x.png");
  background-size: 66px 48px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-programm #section-teaser .block-block {
      background-image: url("../images/icons/04_programm_white@1x.png"); } }
  .no-mediaqueries .page-freefm-programm #section-teaser .block-block {
    background-image: url("../images/icons/04_programm_white@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-programm #section-teaser .block-block {
      background-image: url("../images/icons/04_programm_white@2x.png"); } }

.page-freefm-mediathek #page-top {
  background-image: url("../images/teaser/banner-mediathek@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-mediathek #page-top {
      background-image: url("../images/teaser/banner-mediathek@1x.jpg"); } }
  .no-mediaqueries .page-freefm-mediathek #page-top {
    background-image: url("../images/teaser/banner-mediathek@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-mediathek #page-top {
      background-image: url("../images/teaser/banner-mediathek@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-mediathek h1, .page-freefm-mediathek h2, .page-freefm-mediathek a:hover {
  color: #f98100; }

.page-freefm-mediathek #sidebar-first .block h2.block-title,
.page-freefm-mediathek .block-sectiontitle h2.block-title {
  background: #f98100; }

.page-freefm-mediathek #section-teaser .block-block {
  padding-top: 54px;
  left: 52px;
  width: auto; }

.page-freefm-projekte #page-top {
  background-image: url("../images/teaser/04.6_Projekte@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-projekte #page-top {
      background-image: url("../images/teaser/04.6_Projekte@1x.jpg"); } }
  .no-mediaqueries .page-freefm-projekte #page-top {
    background-image: url("../images/teaser/04.6_Projekte@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-projekte #page-top {
      background-image: url("../images/teaser/04.6_Projekte@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-projekte h1, .page-freefm-projekte h2, .page-freefm-projekte a:hover {
  color: #96b15a; }

.page-freefm-projekte #sidebar-first .block:first-child {
  margin-bottom: 0; }

.page-freefm-projekte #sidebar-first .block:first-child h2.block-title,
.page-freefm-projekte .block-sectiontitle h2.block-title {
  margin-bottom: 50px;
  background: #96b15a; }

.page-freefm-projekte #section-teaser .block-block {
  padding-top: 54px;
  background-image: url("../images/icons/05_projekte_white@1x.png");
  background-size: 52px 52px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-projekte #section-teaser .block-block {
      background-image: url("../images/icons/05_projekte_white@1x.png"); } }
  .no-mediaqueries .page-freefm-projekte #section-teaser .block-block {
    background-image: url("../images/icons/05_projekte_white@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-projekte #section-teaser .block-block {
      background-image: url("../images/icons/05_projekte_white@2x.png"); } }

.page-freefm-home #page-top {
  background-image: url("../images/teaser/04.1_Home@1x.jpg");
  background-size: 1000px 480px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-home #page-top {
      background-image: url("../images/teaser/04.1_Home@1x.jpg"); } }
  .no-mediaqueries .page-freefm-home #page-top {
    background-image: url("../images/teaser/04.1_Home@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-home #page-top {
      background-image: url("../images/teaser/04.1_Home@2x.jpg");
      background-size: 1000px 480px; } }

.page-freefm-home #section-teaser .block-block {
  left: 50px;
  width: auto; }

.page-freefm-home .freefm-front .freefm-front-container:last-child {
  margin-bottom: 0; }

.page-freefm-suche #page-top {
  background-image: url("../images/teaser/teaserbild-suchergebnisse@1x.jpg");
  background-size: 1000px 430px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-freefm-suche #page-top {
      background-image: url("../images/teaser/teaserbild-suchergebnisse@1x.jpg"); } }
  .no-mediaqueries .page-freefm-suche #page-top {
    background-image: url("../images/teaser/teaserbild-suchergebnisse@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-freefm-suche #page-top {
      background-image: url("../images/teaser/teaserbild-suchergebnisse@2x.jpg");
      background-size: 1000px 430px; } }

/*****
 * Search results.
 ****/
.page-search h1.title {
  display: none; }

.page-search #content-area #search-form {
  display: none; }

.page-search h2 {
  color: #b09d8a;
  text-transform: none;
  font-weight: 700;
  margin-top: 0; }
  .page-search h2:before {
    content: "»";
    display: inline-block;
    padding-right: .2em; }

.page-search .search-results {
  padding-left: 0; }
  .page-search .search-results h3.title {
    font-size: 25px;
    line-height: 35px;
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 600;
    font-style: italic; }
    .page-search .search-results h3.title a {
      color: #282828; }
      .page-search .search-results h3.title a:after {
        content: "weiterlesen →";
        position: absolute;
        right: 0;
        bottom: 15px;
        display: block;
        font-size: 16px;
        line-height: 22.4px;
        font-size: 1.6rem;
        line-height: 2.24rem;
        font-weight: bold;
        color: #b09d8a;
        font-style: normal; }
  .page-search .search-results .search-snippet-info {
    padding-left: 0; }
  .page-search .search-results .search-info, .page-search .search-results .search-info a {
    color: #b09d8a; }
  .page-search .search-results .search-result {
    border-bottom: 1px solid #e5e5e5;
    position: relative; }

/*****
 * Airplan.
 ****/
.airplan-onair {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  padding: 20px; }
  .airplan-onair h3 {
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-style: italic;
    margin-top: 10px; }
    .airplan-onair h3, .airplan-onair h3 a {
      color: #19735f; }
    .airplan-onair h3 a {
      background-image: url("../images/elements/arrow_sendeblog-green@1x.png");
      background-size: 20px 14px;
      background-repeat: no-repeat;
      background-position: 100% 8px;
      padding-right: 30px; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .airplan-onair h3 a {
          background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); } }
      .no-mediaqueries .airplan-onair h3 a {
        background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .airplan-onair h3 a {
          background-image: url("../images/elements/arrow_sendeblog-green@2x.png"); } }

.airplan-day .airplan-row {
  position: relative;
  padding: 10px 20px 10px 20px;
  cursor: pointer; }
  .airplan-day .airplan-row:nth-child(2n+1) {
    background-color: #f4f4f3; }
  @media only screen and (min-width: 1000px) {
    .airplan-day .airplan-row {
      padding-left: 50px; } }
  @media only screen and (min-width: 600px) {
    .airplan-day .airplan-row .airplan-time {
      float: left;
      width: 200px; } }
  .airplan-day .airplan-row h3 {
    margin: 0;
    font-style: italic;
    font-weight: 600; }
  .airplan-day .airplan-row .text {
    overflow: hidden;
    transition: max-height .5s ease-out;
    max-height: 0; }
    .airplan-day .airplan-row .text .field-name-body {
      font-size: 14px;
      line-height: 19.6px;
      font-size: 1.4rem;
      line-height: 1.96rem;
      color: #8c8c8c;
      padding: 20px 0 10px 0; }
      @media only screen and (min-width: 1000px) {
        .airplan-day .airplan-row .text .field-name-body {
          padding-left: 200px; } }
  .airplan-day .airplan-row .airplan-link {
    padding-right: 30px;
    text-align: right;
    background-image: url("../images/elements/arrow_sendeblog-green@1x.png");
    background-size: 20px 14px;
    background-repeat: no-repeat;
    background-position: 100% 5px;
    margin-bottom: 20px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .airplan-day .airplan-row .airplan-link {
        background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); } }
    .no-mediaqueries .airplan-day .airplan-row .airplan-link {
      background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .airplan-day .airplan-row .airplan-link {
        background-image: url("../images/elements/arrow_sendeblog-green@2x.png"); } }
    .airplan-day .airplan-row .airplan-link a {
      color: #19735f;
      font-weight: bold; }
  .airplan-day .airplan-row:hover h3, .airplan-day .airplan-row.active h3 {
    text-decoration: underline; }
  .airplan-day .airplan-row.active .text {
    max-height: 500px; }

h1.page-title {
  position: relative;
  border-bottom: 1px solid #d2d2d4; }

.freefm-back-link {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 7px; }
  .freefm-back-link span {
    display: none; }
  .freefm-back-link svg path {
    fill: #f98100;
    fill: #282828; }
    .page-freefm-info .freefm-back-link svg path {
      fill: #f98100; }
    .page-freefm-mediathek .freefm-back-link svg path {
      fill: #f98100; }
    .page-freefm-mitmachen .freefm-back-link svg path {
      fill: #dd4931; }
    .page-freefm-musik .freefm-back-link svg path {
      fill: #245773; }
    .page-freefm-programm .freefm-back-link svg path {
      fill: #19735f; }
    .page-freefm-projekte .freefm-back-link svg path {
      fill: #96b15a; }

.page-node-artikel .freefm-back-link {
  display: block;
  padding: 0 0 16px 0;
  height: 33px;
  width: 100%;
  position: relative; }
  .page-node-artikel .freefm-back-link:after {
    content: '';
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #d2d2d4;
    position: absolute; }
    @media only screen and (min-width: 600px) {
      .page-node-artikel .freefm-back-link:after {
        right: 305px; } }

.pagetitle-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: center center; }
  .pagetitle-icon span {
    display: none; }

.close-link {
  background-image: url("../images/elements/close@1x.png");
  background-size: 19px 19px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .close-link {
      background-image: url("../images/elements/close@1x.png"); } }
  .no-mediaqueries .close-link {
    background-image: url("../images/elements/close@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .close-link {
      background-image: url("../images/elements/close@2x.png");
      background-size: 19px 19px; } }
  .close-link.left-link {
    background-image: url("../images/elements/arrow-widget-grey@1x.png");
    background-size: 15px 28px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .close-link.left-link {
        background-image: url("../images/elements/arrow-widget-grey@1x.png"); } }
    .no-mediaqueries .close-link.left-link {
      background-image: url("../images/elements/arrow-widget-grey@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .close-link.left-link {
        background-image: url("../images/elements/arrow-widget-grey@2x.png");
        background-size: 15px 28px; } }

.plus-link {
  background-image: url("../images/elements/close@1x.png");
  background-size: 19px 19px;
  transform: rotate(45deg);
  transition: transform .3s linear; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .plus-link {
      background-image: url("../images/elements/close@1x.png"); } }
  .no-mediaqueries .plus-link {
    background-image: url("../images/elements/close@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .plus-link {
      background-image: url("../images/elements/close@2x.png");
      background-size: 19px 19px; } }
  .plus-link.is-shown {
    transform: rotate(0); }

.freefm-hide.do-hide {
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s ease-out; }
  .freefm-hide.do-hide.is-shown {
    max-height: 1000px; }

.airplan-schedule {
  height: 820px;
  width: 1000px;
  overflow-x: scroll; }
  .airplan-schedule > .inner {
    position: absolute;
    left: 0;
    height: auto;
    width: 1000px;
    background: #fff; }
  .airplan-schedule .column {
    width: 130px;
    float: left;
    text-align: center; }
    .airplan-schedule .column * {
      text-align: center; }
    .airplan-schedule .column.times {
      width: 90px; }
    .airplan-schedule .column h3 {
      height: 50px;
      font-size: 16px;
      line-height: 22.4px;
      font-size: 1.6rem;
      line-height: 2.24rem;
      font-weight: normal;
      border-right: 1px solid #e8e8e7;
      background-color: #f4f4f3;
      margin: 0;
      padding: 2px 0; }
      .airplan-schedule .column h3 strong {
        display: block;
        font-weight: bold; }
      .airplan-schedule .column h3.today {
        color: #19735f; }
    .airplan-schedule .column a, .airplan-schedule .column .cell {
      font-size: 14px;
      line-height: 19.6px;
      font-size: 1.4rem;
      line-height: 1.96rem;
      font-style: italic;
      text-align: center;
      display: block;
      border-right: 1px solid #b2b2b2; }
      .airplan-schedule .column a:nth-child(2n+1), .airplan-schedule .column .cell:nth-child(2n+1) {
        background: #f3f3f3; }
      .airplan-schedule .column a.onair, .airplan-schedule .column .cell.onair {
        color: #fff;
        background-color: #19735f; }
    .airplan-schedule .column .span1 {
      height: 15px;
      line-height: 15px; }
    .airplan-schedule .column .span2 {
      height: 30px;
      line-height: 30px; }
    .airplan-schedule .column .span3 {
      height: 45px;
      line-height: 45px; }
    .airplan-schedule .column .span4 {
      height: 60px;
      line-height: 60px; }
    .airplan-schedule .column .span5 {
      height: 75px;
      line-height: 75px; }
    .airplan-schedule .column .span6 {
      height: 90px;
      line-height: 90px; }
    .airplan-schedule .column .span7 {
      height: 105px;
      line-height: 105px; }
    .airplan-schedule .column .span8 {
      height: 120px;
      line-height: 120px; }
    .airplan-schedule .column .span9 {
      height: 135px;
      line-height: 135px; }
    .airplan-schedule .column .span10 {
      height: 150px;
      line-height: 150px; }
    .airplan-schedule .column .span11 {
      height: 165px;
      line-height: 165px; }
    .airplan-schedule .column .span12 {
      height: 180px;
      line-height: 180px; }
    .airplan-schedule .column .span13 {
      height: 195px;
      line-height: 195px; }
    .airplan-schedule .column .span14 {
      height: 210px;
      line-height: 210px; }
    .airplan-schedule .column .span15 {
      height: 225px;
      line-height: 225px; }
    .airplan-schedule .column .span16 {
      height: 240px;
      line-height: 240px; }
    .airplan-schedule .column .span17 {
      height: 255px;
      line-height: 255px; }
    .airplan-schedule .column .span18 {
      height: 270px;
      line-height: 270px; }
    .airplan-schedule .column .span19 {
      height: 285px;
      line-height: 285px; }
    .airplan-schedule .column .span20 {
      height: 300px;
      line-height: 300px; }
    .airplan-schedule .column .span21 {
      height: 315px;
      line-height: 315px; }
    .airplan-schedule .column .span22 {
      height: 330px;
      line-height: 330px; }
    .airplan-schedule .column .span23 {
      height: 345px;
      line-height: 345px; }
    .airplan-schedule .column .span24 {
      height: 360px;
      line-height: 360px; }
    .airplan-schedule .column .span25 {
      height: 375px;
      line-height: 375px; }
    .airplan-schedule .column .span26 {
      height: 390px;
      line-height: 390px; }
    .airplan-schedule .column .span27 {
      height: 405px;
      line-height: 405px; }
    .airplan-schedule .column .span28 {
      height: 420px;
      line-height: 420px; }
    .airplan-schedule .column .span29 {
      height: 435px;
      line-height: 435px; }
    .airplan-schedule .column .span30 {
      height: 450px;
      line-height: 450px; }
    .airplan-schedule .column.times .cell {
      font-style: normal; }
      .airplan-schedule .column.times .cell:nth-child(2n) {
        background: #e9e9e9; }

.airplan-links {
  overflow: hidden;
  *zoom: 1; }
  .airplan-links li {
    list-style-type: none;
    list-style-image: none;
    float: left;
    color: #b09d8a; }
    .airplan-links li:after {
      content: '|';
      display: inline-block;
      padding: 0 5px; }
    .airplan-links li:last-child:after {
      content: ''; }
  .airplan-links a {
    color: #b09d8a; }
    .airplan-links a.active {
      color: #282828; }

.view-sendungen {
  position: relative; }
  .view-sendungen h3 {
    font-size: 27px;
    line-height: 37.8px;
    font-size: 2.7rem;
    line-height: 3.78rem;
    color: #19735f;
    position: absolute;
    left: 0;
    font-weight: bold;
    background: #19735f;
    color: #fff;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 36px;
    z-index: 10; }
  .view-sendungen .views-row {
    padding: 5px 20px 5px 10px;
    position: relative;
    cursor: pointer;
    background-color: #f4f4f3;
    margin-bottom: 5px;
    margin: 0 0 4px 40px; }
    .view-sendungen .views-row:hover {
      background-color: #e3e3e3; }
  .view-sendungen .views-field-title {
    font-style: italic; }
  .view-sendungen .views-field-body {
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: #8c8c8c;
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease-out; }
    .view-sendungen .views-field-body .field-content {
      padding: 10px; }
  .view-sendungen .views-field-view-node {
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease-out;
    padding-right: 30px;
    text-align: right;
    background-image: url("../images/elements/arrow_sendeblog-green@1x.png");
    background-size: 20px 14px;
    background-repeat: no-repeat;
    background-position: 100% 5px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .view-sendungen .views-field-view-node {
        background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); } }
    .no-mediaqueries .view-sendungen .views-field-view-node {
      background-image: url("../images/elements/arrow_sendeblog-green@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .view-sendungen .views-field-view-node {
        background-image: url("../images/elements/arrow_sendeblog-green@2x.png"); } }
    .view-sendungen .views-field-view-node a {
      color: #19735f;
      font-weight: bold;
      margin-bottom: 20px; }
  .view-sendungen .views-row.active .views-field-body, .view-sendungen .views-row.active .views-field-view-node {
    max-height: 500px; }

iframe {
  max-width: 100%; }

/**
 * Page: Projekte
 */
.item-list ul.projektlogos {
  list-style-type: none;
  margin: 2rem 0; }
  .item-list ul.projektlogos li {
    list-style-type: none;
    margin: 10px;
    padding: 10px 0;
    overflow: hidden;
    *zoom: 1; }
    @media only screen and (min-width: 1000px) {
      .item-list ul.projektlogos li {
        display: flex; } }
    .item-list ul.projektlogos li .projekt-logo {
      width: 270px;
      flex-grow: 0;
      flex-shrink: 0; }
      @media only screen and (min-width: 1000px) {
        .item-list ul.projektlogos li .projekt-logo {
          padding-right: 20px; } }
      .item-list ul.projektlogos li .projekt-logo img {
        max-width: 100%;
        margin: 0 auto;
        display: block; }
    .item-list ul.projektlogos li h4 {
      float: left;
      margin: auto 0;
      flex-shrink: 1;
      flex-grow: 1;
      font-weight: normal;
      font-style: italic; }

/**
 * Elements.
 */
.readmorelink {
  color: #282828;
  font-weight: 600;
  cursor: pointer;
  font-size: 16pxpx;
  line-height: 22.4pxpx;
  font-size: 1.6pxrem;
  line-height: 2.24pxrem;
  text-align: right;
  display: block; }
  .page-freefm-info .readmorelink {
    color: #f98100; }
  .page-freefm-mediathek .readmorelink {
    color: #f98100; }
  .page-freefm-mitmachen .readmorelink {
    color: #dd4931; }
  .page-freefm-musik .readmorelink {
    color: #245773; }
  .page-freefm-programm .readmorelink {
    color: #19735f; }
  .page-freefm-projekte .readmorelink {
    color: #96b15a; }
  .readmorelink:after {
    display: inline-block;
    content: '→';
    font-size: 23px;
    position: relative;
    top: 1px;
    margin-left: 8px; }
  .readmorelink.expanded:after {
    content: '↑'; }

#freelight {
  position: fixed;
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 90000;
  background: rgba(0, 0, 0, 0.93);
  cursor: pointer; }
  #freelight .freelight-inner {
    margin: 5vh;
    height: 90vh;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center; }
  #freelight img {
    max-width: 100%;
    max-height: 90vh;
    margin: auto; }

/**
 * FreeFM aside
 *
 * Layout for Artikel and Sendung.
 */
.freefm-aside {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem; }

.freefm-aside-aside {
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem; }
  .freefm-aside-aside > .panel-pane {
    margin-bottom: 20px;
    background-color: #ebebeb;
    padding: 0; }
    .freefm-aside-aside > .panel-pane:last-child {
      margin-bottom: 0; }
    .freefm-aside-aside > .panel-pane .pane-content {
      padding: 0 18px 18px 18px; }
  .freefm-aside-aside h2.pane-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    text-transform: uppercase;
    margin: 0;
    padding: 18px;
    color: #000;
    border: none; }

.freefm-aside-meta .panel-pane {
  margin-bottom: 10px;
  background-color: transparent; }

.freefm-aside-meta h2.pane-title {
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem; }

.freefm-aside-meta .field-name-field-externe-website .field-item {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; }

.freefm-aside-aside .pane-views-panes {
  padding: 0 !important;
  position: relative; }
  .freefm-aside-aside .pane-views-panes .pane-content {
    padding: 0; }
  .freefm-aside-aside .pane-views-panes .pane-title {
    margin: 0;
    padding: 18px;
    position: relative; }
  .freefm-aside-aside .pane-views-panes .views-row {
    padding: 14px 18px;
    position: relative; }
  .freefm-aside-aside .pane-views-panes .views-row:nth-child(2n+1) {
    background-color: #e2e2e1; }
  .freefm-aside-aside .pane-views-panes .views-field-title {
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    margin-top: .5rem;
    font-style: italic; }
    .freefm-aside-aside .pane-views-panes .views-field-title, .freefm-aside-aside .pane-views-panes .views-field-title a {
      color: #282828; }
  .freefm-aside-aside .pane-views-panes .view-empty {
    padding: 0 18px 18px 18px; }
    .freefm-aside-aside .pane-views-panes .view-empty p {
      padding: 0;
      margin: 0; }
  .freefm-aside-aside .pane-views-panes a.airplan-podcast {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px; }
  .freefm-aside-aside .pane-views-panes .podcast-all {
    padding: 18px 25px 18px 18px;
    border-right: 18px solid transparent;
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: #f98100;
    background-image: url("../images/elements/arrow_sendeblog-orange@1x.png");
    background-size: 20px 14px;
    background-repeat: no-repeat;
    background-position: 100% 20px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .freefm-aside-aside .pane-views-panes .podcast-all {
        background-image: url("../images/elements/arrow_sendeblog-orange@1x.png"); } }
    .no-mediaqueries .freefm-aside-aside .pane-views-panes .podcast-all {
      background-image: url("../images/elements/arrow_sendeblog-orange@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .freefm-aside-aside .pane-views-panes .podcast-all {
        background-image: url("../images/elements/arrow_sendeblog-orange@2x.png"); } }
    .freefm-aside-aside .pane-views-panes .podcast-all:hover {
      color: #f98100; }
  .freefm-aside-aside .pane-views-panes .view-header {
    position: relative; }
  .freefm-aside-aside .pane-views-panes .podcast-rss-link {
    position: absolute;
    right: 18px;
    top: -38px;
    width: 20px;
    height: 20px;
    background-image: url("../images/elements/rss-brown@1x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat; }
    .freefm-aside-aside .pane-views-panes .podcast-rss-link span {
      display: none; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .freefm-aside-aside .pane-views-panes .podcast-rss-link {
        background-image: url("../images/elements/rss-brown@1x.png"); } }
    .no-mediaqueries .freefm-aside-aside .pane-views-panes .podcast-rss-link {
      background-image: url("../images/elements/rss-brown@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .freefm-aside-aside .pane-views-panes .podcast-rss-link {
        background-image: url("../images/elements/rss-brown@2x.png"); } }

/*****
 * Nodes: General.
 ****/
h1.node-title {
  margin-bottom: 27px; }

.node .content img {
  max-width: 100%;
  height: auto; }

.node .field-item > h2:first-child {
  margin-top: 0; }

.node .submitted {
  color: #b09d8a;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem; }
  .node .submitted a {
    color: #b09d8a; }

.node .teaser-image {
  float: left;
  padding: 0 20px 20px 0; }
  .node .teaser-image.teaser-image-wd {
    float: none;
    padding: 0 0 20px 0; }
  @media only screen and (max-width: 430px) {
    .node .teaser-image {
      float: none;
      padding: 0;
      margin: 0 auto; } }

.node .field-name-field-images {
  overflow: hidden;
  *zoom: 1;
  margin-top: 1em; }
  .node .field-name-field-images .field-item {
    float: left;
    width: 115px;
    height: 115px;
    margin-right: 10px;
    margin-bottom: 10px; }
  .node .field-name-field-images img {
    width: 100%;
    height: 100%; }

.node .field-name-field-audio {
  padding: 1em;
  margin: 1em 0;
  background: #f1f1f1; }

.node.node-teaser {
  overflow: hidden;
  *zoom: 1; }

.node-is-page {
  position: relative; }
  .node-is-page .submitted {
    position: absolute;
    right: 0;
    top: -30px;
    width: auto;
    height: auto; }
  .node-is-page .node .submitted {
    position: static;
    right: auto;
    top: auto; }

/**
 * Artikel
 */
.node-artikel.node-is-page .node-top {
  overflow: hidden;
  *zoom: 1;
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem;
  padding: 18px 0; }
  .node-artikel.node-is-page .node-top .field-name-field-sendung {
    float: left; }
    .node-artikel.node-is-page .node-top .field-name-field-sendung a {
      color: #282828;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      line-height: 25.2px;
      font-size: 1.8rem;
      line-height: 2.52rem;
      font-style: italic; }
  .node-artikel.node-is-page .node-top .submitted {
    display: block;
    float: right;
    position: static;
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem; }

.node-artikel.node-is-page h1.node-title {
  border: none;
  color: #282828;
  text-transform: none;
  font-size: 25px;
  line-height: 35px;
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: normal; }

.node-artikel.node-is-page .field-name-field-tags {
  border-top: 1px solid #d2d2d4;
  color: #19735f;
  padding-top: 16px;
  margin-top: 16px; }
  .node-artikel.node-is-page .field-name-field-tags * {
    display: inline-block; }
  .node-artikel.node-is-page .field-name-field-tags a {
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 600;
    color: #19735f;
    margin-right: .85em; }
    .node-artikel.node-is-page .field-name-field-tags a:before {
      content: '→ ';
      display: inline-block;
      font-size: 16px;
      margin-right: 5px; }

.freefm-aside-main .node-is-page .content .pane-node-field-audio.mobile h2.pane-title {
  display: none; }

/*****
 * Nodes: Platte der Woche.
 ****/
.node-platte-der-woche .node-intro {
  padding-bottom: 45px;
  margin-bottom: 45px;
  border-bottom: 1px solid #e5e5e5;
  position: relative; }
  .node-platte-der-woche .node-intro .submitted {
    position: absolute;
    right: 0;
    top: -20px;
    width: auto;
    height: auto; }
  .node-platte-der-woche .node-intro .field-name-field-coverbild {
    float: left; }
    .node-platte-der-woche .node-intro .field-name-field-coverbild img {
      max-width: 220px; }
    @media only screen and (max-width: 600px) {
      .node-platte-der-woche .node-intro .field-name-field-coverbild {
        float: none;
        width: auto;
        margin: 0 auto; } }
    .node-platte-der-woche .node-intro .field-name-field-coverbild .field-label {
      display: none; }
  @media only screen and (min-width: 600px) {
    .node-platte-der-woche .node-intro.has-coverbild .node-intro-right,
    .node-platte-der-woche .node-intro.has-coverbild .node-intro-bottom {
      position: absolute; }
    .node-platte-der-woche .node-intro.has-coverbild .node-intro-bottom {
      bottom: 40px; }
    .node-platte-der-woche .node-intro.has-coverbild .node-intro-right,
    .node-platte-der-woche .node-intro.has-coverbild .node-intro-bottom {
      margin-left: 250px; } }
  .node-platte-der-woche .node-intro h2 {
    color: #282828;
    font-weight: normal;
    font-size: 24px;
    line-height: 33.6px;
    font-size: 2.4rem;
    line-height: 3.36rem;
    text-transform: none;
    margin: 10px 0;
    padding: 0;
    border: none; }
  .node-platte-der-woche .node-intro h2 a {
    color: #282828;
    display: inline;
    text-decoration: none;
    border-bottom: 1px solid #282828;
    font-style: italic; }
  .node-platte-der-woche .node-intro .field-name-field-artist,
  .node-platte-der-woche .node-intro .field-name-field-v-datum,
  .node-platte-der-woche .node-intro .field-name-field-label {
    overflow: hidden;
    *zoom: 1; }
    .node-platte-der-woche .node-intro .field-name-field-artist .field-label,
    .node-platte-der-woche .node-intro .field-name-field-v-datum .field-label,
    .node-platte-der-woche .node-intro .field-name-field-label .field-label {
      font-weight: normal;
      width: 100px;
      float: left; }
    .node-platte-der-woche .node-intro .field-name-field-artist .field-items,
    .node-platte-der-woche .node-intro .field-name-field-v-datum .field-items,
    .node-platte-der-woche .node-intro .field-name-field-label .field-items {
      font-weight: 600;
      float: left; }
  .node-platte-der-woche .node-intro .field-name-field-artist .field-label {
    width: auto; }

.node-platte-der-woche .field-name-body p {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  -ms-column-gap: 30px;
  -o-column-gap: 30px;
  column-gap: 30px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 600px) {
    .node-platte-der-woche .field-name-body p {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      -ms-column-count: 1;
      -o-column-count: 1;
      column-count: 1; } }

.node-platte-der-woche .field-name-field-tracklist, .node-platte-der-woche .field-name-field-tourdates {
  overflow: hidden;
  *zoom: 1;
  padding-bottom: 40px; }
  .node-platte-der-woche .field-name-field-tracklist .field-label, .node-platte-der-woche .field-name-field-tourdates .field-label {
    font-size: 24px;
    line-height: 33.6px;
    font-size: 2.4rem;
    line-height: 3.36rem;
    font-weight: normal;
    color: #245773;
    padding-bottom: 1rem; }
  @media only screen and (min-width: 600px) {
    .node-platte-der-woche .field-name-field-tracklist, .node-platte-der-woche .field-name-field-tourdates {
      float: left;
      width: 49%; } }

.node-platte-der-woche iframe {
  margin-bottom: 1em; }

.playlisttable {
  width: 100%; }
  .playlisttable tr.even, .playlisttable tr.odd {
    background: transparent;
    border: none; }
    .playlisttable tr.even td, .playlisttable tr.odd td {
      padding: 6px 3px 0 3px; }
      .playlisttable tr.even td:first-child, .playlisttable tr.odd td:first-child {
        padding-left: 0; }
      .playlisttable tr.even td:last-child, .playlisttable tr.odd td:last-child {
        padding-right: 0; }
  .playlisttable td:first-child {
    font-weight: bold; }
  .playlisttable td {
    padding: 0;
    color: #8c8c8c; }
  .playlisttable td.playlist-meta {
    color: #245773;
    font-weight: normal;
    padding-top: 0 !important; }
  .playlisttable th {
    font-weight: normal;
    border: none;
    background: transparent;
    padding: 3px; }
    .playlisttable th:first-child {
      padding-left: 0; }
    .playlisttable th:last-child {
      padding-right: 0; }
  .playlisttable h3 {
    margin: 0;
    padding: 20px 0;
    color: #282828;
    position: relative; }
    .playlisttable h3:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      left: 50px;
      border-bottom: 1px solid #e5e5e5; }
  @media only screen and (max-width: 600px) {
    .playlisttable tr, .playlisttable td, .playlisttable th {
      display: block; }
    .playlisttable tr.odd {
      background: #f1f1f1; }
    .playlisttable tr {
      border-bottom: 1px solid #e5e5e5; }
    .playlisttable th {
      display: inline-block; }
      .playlisttable th:after {
        content: '/';
        padding: 8px 0; }
      .playlisttable th:last-child:after {
        content: ''; }
    .playlisttable tr {
      padding: 5px; }
    .playlisttable td, .playlisttable th {
      padding: 0; } }

.node-playlist h2 {
  margin: 20px 0;
  border: none; }
  .node-playlist h2 a {
    font-weight: normal;
    font-style: italic;
    color: #282828; }

.node-playlist.node-teaser, .node-playlist.node-vm-expand {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding-bottom: 20px; }
  .node-playlist.node-teaser .field-name-body, .node-playlist.node-vm-expand .field-name-body {
    color: #8c8c8c; }
    .node-playlist.node-teaser .field-name-body p:last-child, .node-playlist.node-vm-expand .field-name-body p:last-child {
      margin-bottom: 0; }
  .node-playlist.node-teaser .field-name-field-sendung, .node-playlist.node-vm-expand .field-name-field-sendung {
    display: none; }

/*****
 * Nodes: Sendungen.
 ****/
.page-node-sendung h1.title, .page-node-sendung h2.page-title {
  font-size: 24px;
  line-height: 33.6px;
  font-size: 2.4rem;
  line-height: 3.36rem;
  margin: 20px 0 10px 0;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  border: none; }

.node-sendung .field-name-body {
  color: #8c8c8c; }

.node-sendung .field-name-field-redaktion .field-item {
  display: inline; }
  .node-sendung .field-name-field-redaktion .field-item:after {
    content: ',\a0'; }
  .node-sendung .field-name-field-redaktion .field-item:last-child:after {
    content: ''; }

.node-type-sendung .pane-sendung-body {
  border-top: 1px solid #d2d2d4;
  border-bottom: 1px solid #d2d2d4;
  padding: 18px 0 0 0;
  margin: 0 0 1em 0;
  position: relative;
  overflow: hidden;
  *zoom: 1; }
  .node-type-sendung .pane-sendung-body .togglebody {
    float: right; }
  .node-type-sendung .pane-sendung-body .rss-link {
    float: right;
    background-image: url("../images/elements/rss-brown@1x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 3px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .node-type-sendung .pane-sendung-body .rss-link {
        background-image: url("../images/elements/rss-brown@1x.png"); } }
    .no-mediaqueries .node-type-sendung .pane-sendung-body .rss-link {
      background-image: url("../images/elements/rss-brown@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .node-type-sendung .pane-sendung-body .rss-link {
        background-image: url("../images/elements/rss-brown@2x.png"); } }
    .node-type-sendung .pane-sendung-body .rss-link span {
      display: none; }
  .node-type-sendung .pane-sendung-body .field-name-body {
    float: none;
    clear: both;
    padding-bottom: 18px; }
  .node-type-sendung .pane-sendung-body .togglebody {
    cursor: pointer;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    font-weight: bold;
    margin-left: 2em;
    margin-top: 3px;
    color: #19735f; }
    .node-type-sendung .pane-sendung-body .togglebody:after {
      content: '+';
      display: inline-block;
      margin-left: 5px; }

.node-type-sendung h1.node-title {
  color: #282828;
  text-transform: none;
  padding: 0 0 18px 0;
  border: none;
  margin: 0;
  float: left;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  font-size: 2.5rem;
  line-height: 3.5rem; }

.node-type-sendung .pane-views-sendungsblog-block h2.pane-title {
  display: none; }

.node-type-sendung .pane-views-sendungsblog-block h2 {
  font-size: 21px;
  line-height: 29.4px;
  font-size: 2.1rem;
  line-height: 2.94rem; }

.node-type-sendung .pane-views-sendungsblog-block .submitted {
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem; }

.node-type-sendung .pane-views-sendungsblog-block .node-vm-expand .field-name-field-sendung {
  display: none; }

.node-type-sendung .freefm-aside .freefm-aside-meta .pane-node-field-airtime .field-item {
  margin-bottom: .5rem; }

.node-type-sendung .freefm-aside .freefm-aside-meta .pane-node-field-airtime small {
  display: block; }

.node-type-sendung .freefm-aside .pane-node-field-airtime.airtime-playout {
  padding: 0 !important; }
  .node-type-sendung .freefm-aside .pane-node-field-airtime.airtime-playout .pane-title {
    margin: 0;
    padding: 18px; }
  .node-type-sendung .freefm-aside .pane-node-field-airtime.airtime-playout .pane-content {
    padding: 0; }
  .node-type-sendung .freefm-aside .pane-node-field-airtime.airtime-playout .airplan-listen {
    background-color: transparent;
    color: #282828;
    padding: 14px 18px;
    margin: 0; }
  .node-type-sendung .freefm-aside .pane-node-field-airtime.airtime-playout .field-item:nth-child(2n+1) .airplan-listen {
    background-color: #e2e2e1; }

/**
 * Nodes: teasers.
 */
.view-sendungsblog .node-teaser, .view-sendungsblog .node-vm-expand,
.view-inhalt-nach-projekt .node-teaser,
.view-inhalt-nach-projekt .node-vm-expand,
.view-taxonomy-term .node-teaser,
.view-taxonomy-term .node-vm-expand,
.view-content-by-term .node-teaser,
.view-content-by-term .node-vm-expand {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  *zoom: 1; }
  .view-sendungsblog .node-teaser h2, .view-sendungsblog .node-vm-expand h2,
  .view-inhalt-nach-projekt .node-teaser h2,
  .view-inhalt-nach-projekt .node-vm-expand h2,
  .view-taxonomy-term .node-teaser h2,
  .view-taxonomy-term .node-vm-expand h2,
  .view-content-by-term .node-teaser h2,
  .view-content-by-term .node-vm-expand h2 {
    border: none;
    font-style: italic;
    font-weight: normal;
    text-transform: none;
    margin: 20px 0;
    padding: 0; }
  .view-sendungsblog .node-teaser .field-name-field-sendung, .view-sendungsblog .node-vm-expand .field-name-field-sendung,
  .view-inhalt-nach-projekt .node-teaser .field-name-field-sendung,
  .view-inhalt-nach-projekt .node-vm-expand .field-name-field-sendung,
  .view-taxonomy-term .node-teaser .field-name-field-sendung,
  .view-taxonomy-term .node-vm-expand .field-name-field-sendung,
  .view-content-by-term .node-teaser .field-name-field-sendung,
  .view-content-by-term .node-vm-expand .field-name-field-sendung {
    display: none; }

/**
 * Audio details.
 */
.audio-details {
  margin: 0 0 2rem 0; }
  .audio-details .airplan-podcast {
    float: right;
    margin-top: 5px;
    margin-left: 20px; }
  .audio-details h3 {
    font-size: 18px;
    line-height: 25.2px;
    font-size: 1.8rem;
    line-height: 2.52rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-style: italic;
    margin: 0 0 20px 0;
    color: #282828; }
  .audio-details a.download {
    color: #f98100;
    margin: 0 0 20px 0;
    display: block;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    font-weight: 600; }
  .audio-details .audio-meta {
    margin: 0 -20px;
    padding: 20px;
    background-color: #e3e3e3;
    color: #282828; }
  .audio-details h4 {
    font-weight: 600;
    color: #282828;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem; }
  .audio-details dl {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem; }
  .audio-details dt {
    width: 33%; }
  .audio-details dd {
    width: 66%;
    margin: 0; }

.field-item:last-child > .audio-details {
  margin-bottom: 0; }

/**
 * Gallery.
 */
.image-gallery .item {
  float: left;
  width: 33%;
  height: 310px;
  padding: 0 20px 0 0; }

.view-freefm-galleries .view-content {
  overflow: hidden;
  *zoom: 1; }

.view-freefm-galleries .views-row {
  padding-right: 20px;
  padding-bottom: 20px;
  height: 320px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 770px) {
    .view-freefm-galleries .views-row {
      width: 50%;
      float: left; } }
  @media only screen and (min-width: 1000px) {
    .view-freefm-galleries .views-row {
      width: 33%; } }

.view-freefm-galleries .views-field-title {
  font-size: 20px;
  font-style: italic;
  height: 4em;
  vertical-align: bottom;
  display: table; }
  .view-freefm-galleries .views-field-title .field-content {
    display: table-cell;
    vertical-align: bottom;
    height: 100%;
    padding-bottom: 5px; }

.view-freefm-galleries .views-field-field-images-1 {
  color: #b09d8a;
  font-weight: bold; }

.field-name-field-file-attachements {
  background-color: #ebebeb;
  width: 300px; }
  .field-name-field-file-attachements .field-label, .field-name-field-file-attachements .field-item {
    padding: 15px; }
  .field-name-field-file-attachements .field-item:nth-child(n+1) {
    background-color: #e2e2e1; }
  .field-name-field-file-attachements .field-item {
    position: relative;
    padding-right: 30px; }

.ui-widget {
  font-family: "Source Sans Pro", "Helvetica Neue", helvetica, Arial, sans-serif; }

ul[data-sa-theme="minimal"].ui-menu {
  right: 0 !important;
  left: auto !important;
  border: none;
  border-radius: 0;
  background: white;
  box-shadow: -2px 2px 8px 0 #61686e; }
  ul[data-sa-theme="minimal"].ui-menu .ui-autocomplete-field-group {
    border: none;
    width: 100%;
    clear: left;
    padding: 5px;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    font-weight: bold;
    color: #b09d8a;
    right: 0;
    border-bottom: 1px solid #e5e5e5;
    text-transform: uppercase; }
    ul[data-sa-theme="minimal"].ui-menu .ui-autocomplete-field-group.all_results.ui-menu-divider {
      margin: 0;
      padding: 0;
      border: none;
      border-bottom: 1px solid #e5e5e5; }
      ul[data-sa-theme="minimal"].ui-menu .ui-autocomplete-field-group.all_results.ui-menu-divider + .ui-menu-item-first a {
        color: #b09d8a;
        font-weight: 600; }
  ul[data-sa-theme="minimal"].ui-menu .ui-menu-item {
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5; }
  ul[data-sa-theme="minimal"].ui-menu .ui-menu-item a {
    margin: 0; }
  ul[data-sa-theme="minimal"].ui-menu .ui-menu-item-first {
    border: none; }
  ul[data-sa-theme="minimal"].ui-menu .ui-autocomplete-field-name {
    display: none; }
  ul[data-sa-theme="minimal"].ui-menu .ui-state-focus {
    border-radius: 0;
    border: none;
    background: #ebebeb; }

.views-scrollabletabs {
  position: relative; }
  .views-scrollabletabs > .item-list > .pager {
    display: none; }
  .views-scrollabletabs div.next,
  .views-scrollabletabs div.prev {
    position: absolute;
    width: 340px;
    right: 0;
    height: 40px;
    z-index: 70;
    background: #f5f5f5;
    cursor: pointer; }
    .views-scrollabletabs div.next:before,
    .views-scrollabletabs div.prev:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 28px;
      top: 6px;
      background-image: url("../images/elements/arrow-widget-grey@1x.png");
      background-size: 15px 28px;
      background-repeat: no-repeat;
      left: 162px; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .views-scrollabletabs div.next:before,
        .views-scrollabletabs div.prev:before {
          background-image: url("../images/elements/arrow-widget-grey@1x.png"); } }
      .no-mediaqueries .views-scrollabletabs div.next:before, .no-mediaqueries
      .views-scrollabletabs div.prev:before {
        background-image: url("../images/elements/arrow-widget-grey@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .views-scrollabletabs div.next:before,
        .views-scrollabletabs div.prev:before {
          background-image: url("../images/elements/arrow-widget-grey@2x.png"); } }
    .views-scrollabletabs div.next span,
    .views-scrollabletabs div.prev span {
      display: none; }
    .views-scrollabletabs div.next.disabled,
    .views-scrollabletabs div.prev.disabled {
      cursor: auto; }
      .views-scrollabletabs div.next.disabled:before,
      .views-scrollabletabs div.prev.disabled:before {
        opacity: 0.2; }
  .views-scrollabletabs div.next {
    bottom: 0; }
    .views-scrollabletabs div.next:before {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg); }
  .views-scrollabletabs div.prev {
    top: 0; }
    .views-scrollabletabs div.prev:before {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg); }
  .views-scrollabletabs .vtabs-readmore {
    color: #61686e;
    font-weight: 600;
    position: absolute;
    right: 360px;
    bottom: 15px;
    cursor: pointer;
    font-size: 16pxpx;
    line-height: 22.4pxpx;
    font-size: 1.6pxrem;
    line-height: 2.24pxrem; }
    .views-scrollabletabs .vtabs-readmore:after {
      content: "";
      background-image: url("../images/elements/arrow_sendeblog-grey@1x.png");
      background-size: 20px 14px;
      background-repeat: no-repeat;
      display: inline-block;
      width: 20px;
      height: 14px;
      margin-left: 8px;
      position: relative;
      top: 2px; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .views-scrollabletabs .vtabs-readmore:after {
          background-image: url("../images/elements/arrow_sendeblog-grey@1x.png"); } }
      .no-mediaqueries .views-scrollabletabs .vtabs-readmore:after {
        background-image: url("../images/elements/arrow_sendeblog-grey@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .views-scrollabletabs .vtabs-readmore:after {
          background-image: url("../images/elements/arrow_sendeblog-grey@2x.png"); } }
    .views-scrollabletabs .vtabs-readmore.expanded:after {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg); }
  .views-scrollabletabs .vtabs-readmore-fade {
    position: absolute;
    bottom: 40px;
    height: 30px;
    background-image: -owg-linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 70%);
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 70%);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 70%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 70%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 70%); }
  .views-scrollabletabs .vertical-tabs {
    padding: 0 340px 0 0;
    margin: 0;
    border: none; }
    .views-scrollabletabs .vertical-tabs .vertical-tabs-pane {
      padding-top: 42px;
      padding-left: 0; }
      .views-scrollabletabs .vertical-tabs .vertical-tabs-pane:after {
        content: "";
        height: 30px;
        display: inline-block;
        background-image: -owg-linear-gradient(#fff, rgba(255, 255, 255, 0));
        background-image: -webkit-linear-gradient(#fff, rgba(255, 255, 255, 0));
        background-image: -moz-linear-gradient(#fff, rgba(255, 255, 255, 0));
        background-image: -o-linear-gradient(#fff, rgba(255, 255, 255, 0));
        background-image: linear-gradient(#fff, rgba(255, 255, 255, 0)); }
    .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper {
      margin: 0 -340px 0 0;
      width: 340px;
      float: right;
      border: none;
      background: #f5f5f5;
      padding: 35px 0;
      max-height: 420px;
      position: relative;
      overflow: hidden; }
      .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper ul.vertical-tabs-list {
        height: 10000px;
        padding: 0;
        margin: 0;
        width: 340px;
        border: none;
        float: none; }
      .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li {
        border: none;
        background: #f5f5f5; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li a {
          margin: 0 30px;
          padding: 17px 0;
          border-top: 1px solid #c4c4c4; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li a:focus {
          outline: none; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li strong {
          color: #282828;
          font-weight: 600; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li .summary {
          color: #b09d8a;
          font-size: 14px;
          line-height: 19.6px;
          font-size: 1.4rem;
          line-height: 1.96rem; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li.selected {
          background: #fff; }
          .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li.selected strong {
            color: #8c8c95; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li.first a {
          border-top: none; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li.selected a,
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper li.selected + li a {
          border-top-color: #fff; }
    .views-scrollabletabs .vertical-tabs .node {
      overflow: hidden;
      max-height: 337px;
      position: static; }
      .views-scrollabletabs .vertical-tabs .node h2 {
        border: none;
        font-style: italic;
        text-transform: none;
        font-weight: normal;
        margin: 25px 0;
        min-height: 30px;
        padding: 0; }
        .views-scrollabletabs .vertical-tabs .node h2, .views-scrollabletabs .vertical-tabs .node h2 a {
          color: #252629; }
        @media only screen and (max-width: 1000px) {
          .views-scrollabletabs .vertical-tabs .node h2 {
            margin: 25px 30px; } }
      .views-scrollabletabs .vertical-tabs .node .submitted {
        display: block;
        height: 25px;
        position: absolute;
        top: 25px;
        left: 90px; }
      .views-scrollabletabs .vertical-tabs .node .field-name-field-sendung {
        display: none; }
      .views-scrollabletabs .vertical-tabs .node .content {
        padding-bottom: 30px;
        color: #8c8c8c; }
  @media only screen and (max-width: 600px) {
    .views-scrollabletabs .vtabs-readmore {
      right: 10px; }
    .views-scrollabletabs .vertical-tabs {
      padding-right: 0; }
      .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper {
        width: 1px;
        position: static; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .next, .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .prev {
          width: 15px;
          height: 28px;
          top: 40px;
          z-index: 100;
          background: transparent; }
          .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .next:before, .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .prev:before {
            width: 15px;
            height: 28px;
            left: 0;
            top: 39px; }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .next {
          right: 10px;
          left: auto; }
          .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .next:before {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg); }
        .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .prev {
          left: -10px;
          right: auto; }
          .views-scrollabletabs .vertical-tabs .vertical-tabs-list-wrapper .prev:before {
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -ms-transform: rotate(0);
            -o-transform: rotate(0);
            transform: rotate(0); }
      .views-scrollabletabs .vertical-tabs .vertical-tabs-list {
        opacity: 0; }
      .views-scrollabletabs .vertical-tabs .node header h2 {
        text-align: center;
        padding: 0 20px; } }

#mediathek #mediathek-inner {
  display: none; }

#mediathek #mediathek-header {
  display: none; }

body.mediathek-is-active .page-freefm-teaser #page-top {
  filter: blur(5px);
  overflow: hidden; }

body.mediathek-is-active .page-freefm-teaser #section-teaser .block-block {
  display: none; }

body.mediathek-is-active #mediathek #mediathek-inner, body.mediathek-is-active #mediathek #mediathek-header {
  display: block; }

body:not(.mediathek-is-active) #mediathek.state-playing #mediathek-header {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 75px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4)); }
  body:not(.mediathek-is-active) #mediathek.state-playing #mediathek-header:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), white); }

body:not(.mediathek-is-active) #mediathek.state-playing #mediathek-controls .live, body:not(.mediathek-is-active) #mediathek.state-playing #mediathek-controls .audio {
  display: none; }

#persistent {
  position: relative;
  overflow: visible; }

#mediathek-button {
  width: 240px;
  position: absolute;
  right: 0;
  top: 440px;
  background: #f98100;
  cursor: pointer;
  z-index: 80;
  padding: 0; }
  #mediathek-button > span {
    display: inline-block;
    padding: 6px;
    height: 50px;
    text-align: center;
    vertical-align: top;
    font-weight: bold;
    letter-spacing: 0;
    position: relative; }
  #mediathek-button .button-page {
    width: 190px; }
  #mediathek-button .button-toggle {
    width: 49px;
    border-left: 1px solid #e3e3e3; }
    #mediathek-button .button-toggle:after {
      position: relative;
      top: 4px;
      right: 0;
      width: 25px;
      height: 25px;
      content: '';
      display: inline-block;
      background-image: url("../images/elements/plus@1x.png");
      background-size: 25px 25px;
      background-repeat: no-repeat; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        #mediathek-button .button-toggle:after {
          background-image: url("../images/elements/plus@1x.png"); } }
      .no-mediaqueries #mediathek-button .button-toggle:after {
        background-image: url("../images/elements/plus@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        #mediathek-button .button-toggle:after {
          background-image: url("../images/elements/plus@2x.png"); } }

body.mediathek-is-active #mediathek-button .button-toggle:after {
  transform: rotate(45deg); }

#mediathek {
  position: absolute;
  width: 100%;
  height: 380px;
  top: 110px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 79; }
  @media only screen and (min-width: 1000px) {
    #mediathek {
      width: 1000px; } }

#mediathek-inner {
  background: rgba(20, 20, 20, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

#mediathek li, #mediathek ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block; }

#mediathek #mediathek-zoom {
  position: absolute;
  top: 0;
  left: 0;
  height: 93px;
  width: 100px;
  background-color: rgba(200, 200, 200, 0.2);
  background-image: url("../images/elements/zoom-out@1x.png");
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  z-index: 84; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    #mediathek #mediathek-zoom {
      background-image: url("../images/elements/zoom-out@1x.png"); } }
  .no-mediaqueries #mediathek #mediathek-zoom {
    background-image: url("../images/elements/zoom-out@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    #mediathek #mediathek-zoom {
      background-image: url("../images/elements/zoom-out@2x.png"); } }
  #mediathek #mediathek-zoom:hover {
    background-color: rgba(200, 200, 200, 0.5); }

#mediathek.zoomout #mediathek-zoom {
  background-image: url("../images/elements/zoom-in@1x.png");
  background-size: 30px 30x;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    #mediathek.zoomout #mediathek-zoom {
      background-image: url("../images/elements/zoom-in@1x.png"); } }
  .no-mediaqueries #mediathek.zoomout #mediathek-zoom {
    background-image: url("../images/elements/zoom-in@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    #mediathek.zoomout #mediathek-zoom {
      background-image: url("../images/elements/zoom-in@2x.png"); } }

#mediathek.mode-podcast #mediathek-zoom {
  visibility: hidden; }

#mediathek #mediathek-header {
  width: 100%;
  height: 93px;
  position: relative;
  z-index: 83; }

#mediathek #mediathek-info {
  float: right;
  margin-right: 40px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px rgba(255, 255, 255, 0.2);
  max-width: 430px;
  margin-top: 14px; }
  #mediathek #mediathek-info span {
    display: block;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right; }
  #mediathek #mediathek-info .info-main {
    font-size: 20px;
    line-height: 28px;
    font-size: 2rem;
    line-height: 2.8rem;
    font-style: italic;
    height: 28px; }
    #mediathek #mediathek-info .info-main.singlerow {
      margin-top: 14px; }

.mediathek-is-active #mediathek.mode-podcast #mediathek-info {
  display: none; }

#mediathek #mediathek-controls {
  float: right;
  margin-top: 30px;
  margin-right: 30px;
  width: auto; }

#mediathek .mediathek-control {
  display: block;
  float: left;
  margin-right: 18px;
  cursor: pointer; }
  #mediathek .mediathek-control:last-child {
    margin-right: 0; }

#mediathek .mediathek-control.playpause {
  background-image: url("../images/elements/play@1x.png");
  background-size: 18px 23px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 18px;
  height: 23px; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    #mediathek .mediathek-control.playpause {
      background-image: url("../images/elements/play@1x.png"); } }
  .no-mediaqueries #mediathek .mediathek-control.playpause {
    background-image: url("../images/elements/play@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    #mediathek .mediathek-control.playpause {
      background-image: url("../images/elements/play@2x.png"); } }

#mediathek .mediathek-control.next,
#mediathek .mediathek-control.prev {
  background-image: url("../images/elements/skip@1x.png");
  background-size: 14px 15px;
  background-repeat: no-repeat;
  width: 14px;
  height: 23px;
  background-position: center center;
  visibility: hidden; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    #mediathek .mediathek-control.next,
    #mediathek .mediathek-control.prev {
      background-image: url("../images/elements/skip@1x.png"); } }
  .no-mediaqueries #mediathek .mediathek-control.next, .no-mediaqueries
  #mediathek .mediathek-control.prev {
    background-image: url("../images/elements/skip@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    #mediathek .mediathek-control.next,
    #mediathek .mediathek-control.prev {
      background-image: url("../images/elements/skip@2x.png"); } }

#mediathek.mode-live .mediathek-control.prev,
#mediathek.mode-audio .mediathek-control.next,
#mediathek.mode-audio .mediathek-control.prev {
  visibility: visible; }

#mediathek .mediathek-control.next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

#mediathek .mediathek-control.live, #mediathek .mediathek-control.audio {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-right: 20px; }

#mediathek .mediathek-control.live {
  margin-right: 40px; }

#mediathek.state-playing .mediathek-control.playpause {
  background-image: url("../images/elements/pause@1x.png");
  background-size: 16px 23px;
  background-repeat: no-repeat; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    #mediathek.state-playing .mediathek-control.playpause {
      background-image: url("../images/elements/pause@1x.png"); } }
  .no-mediaqueries #mediathek.state-playing .mediathek-control.playpause {
    background-image: url("../images/elements/pause@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    #mediathek.state-playing .mediathek-control.playpause {
      background-image: url("../images/elements/pause@2x.png"); } }

#mediathek.state-playing.mode-live .mediathek-control.live,
#mediathek.mode-audio .mediathek-control.audio {
  color: #ff9614; }

#mediathek #mediathek-slider-wrapper {
  position: absolute;
  top: 91px;
  left: 0;
  right: 0;
  bottom: 50px;
  overflow: hidden; }

#mediathek #mediathek-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer; }
  #mediathek #mediathek-slider #mediathek-items, #mediathek #mediathek-slider #mediathek-timeline {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 82; }
  #mediathek #mediathek-slider #mediathek-items {
    z-index: 83;
    top: 80px; }
  #mediathek #mediathek-slider #mediathek-timeline > li {
    height: 100%;
    float: left;
    position: relative; }
    #mediathek #mediathek-slider #mediathek-timeline > li h4 {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      padding: 30px 10px 10px 10px;
      color: #eee;
      font-size: 16px;
      font-weight: bold; }
    #mediathek #mediathek-slider #mediathek-timeline > li .mediathek-hours {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 83; }
      #mediathek #mediathek-slider #mediathek-timeline > li .mediathek-hours > li {
        float: left;
        border-left: 1px solid rgba(255, 255, 255, 0.7);
        border-image: linear-gradient(transparent 0%, rgba(255, 255, 255, 0.7) 20%, rgba(255, 255, 255, 0.7) 80%, transparent 100%) 1 100%;
        background-image: linear-gradient(to bottom, transparent 10%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 60%, transparent 90%), linear-gradient(to bottom, transparent 10%, rgba(255, 255, 255, 0.35) 40%, rgba(255, 255, 255, 0.35) 60%, transparent 90%), linear-gradient(to bottom, transparent 10%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 60%, transparent 90%);
        background-size: 1px 100%, 1px 100%, 1px 100%;
        background-position: 25% 0, 50% 0, 75% 0;
        background-repeat: no-repeat;
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
        text-align: left;
        height: 100%;
        padding: 10px 0 0 10px; }
  #mediathek #mediathek-slider #mediathek-items > li {
    float: left; }
    #mediathek #mediathek-slider #mediathek-items > li a {
      position: relative;
      display: block;
      background: rgba(248, 248, 248, 0.9);
      color: rgba(0, 0, 0, 0.95);
      padding: 10px;
      font-size: 20px;
      font-style: italic;
      height: 100px;
      margin: 0;
      transition: background .2s ease-in-out;
      line-height: 1.25em;
      border-left: 1px solid rgba(0, 0, 0, 0.7);
      border-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.7) 80%, transparent 100%) 1 100%; }
      #mediathek #mediathek-slider #mediathek-items > li a:hover {
        text-decoration: none;
        background: rgba(253, 253, 240, 0.9);
        box-shadow: 0 0 8px 3px rgba(250, 250, 100, 0.8) inset; }
      #mediathek #mediathek-slider #mediathek-items > li a .meta {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-size: 12px;
        font-style: normal; }
    #mediathek #mediathek-slider #mediathek-items > li.live a .meta {
      text-transform: uppercase;
      font-weight: bold; }
    #mediathek #mediathek-slider #mediathek-items > li:nth-child(2n) a {
      background: rgba(233, 233, 233, 0.9); }
      #mediathek #mediathek-slider #mediathek-items > li:nth-child(2n) a:hover {
        background: rgba(240, 240, 220, 0.9); }
    #mediathek #mediathek-slider #mediathek-items > li.active a, #mediathek #mediathek-slider #mediathek-items > li.active a:hover {
      text-decoration: underline;
      box-shadow: 0 0 10px 5px rgba(255, 155, 100, 0.4) inset;
      background: rgba(255, 250, 245, 0.95); }
    #mediathek #mediathek-slider #mediathek-items > li:nth-child(2n).active a, #mediathek #mediathek-slider #mediathek-items > li:nth-child(2n).active a:hover {
      background: rgba(255, 245, 235, 0.95); }
    #mediathek #mediathek-slider #mediathek-items > li.future:not(.active) a {
      background: rgba(190, 190, 190, 0.8);
      cursor: default; }
      #mediathek #mediathek-slider #mediathek-items > li.future:not(.active) a:hover {
        background: rgba(190, 190, 190, 0.8);
        box-shadow: none; }

#mediathek #mediathek-cursor {
  position: absolute;
  width: 30px;
  margin-left: -15px;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 84;
  background: transparent;
  cursor: col-resize;
  transition: background .2s ease-in-out; }
  #mediathek #mediathek-cursor:hover {
    background: rgba(255, 200, 100, 0.2); }
    #mediathek #mediathek-cursor:hover .mediathek-cursor-inner {
      border-color: #ff9614;
      box-shadow: 0 0 3px 1px rgba(255, 200, 70, 0.5); }
  #mediathek #mediathek-cursor.ui-draggable-dragging {
    background: rgba(255, 150, 100, 0.2); }
  #mediathek #mediathek-cursor .mediathek-cursor-inner {
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 0;
    border-color: rgba(255, 150, 20, 0.9);
    border-style: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    box-shadow: 0 0 3px 1px rgba(255, 200, 70, 0.3);
    z-index: 85; }

#mediathek #mediathek-days {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 240px;
  height: 50px;
  background: rgba(200, 200, 200, 0.9);
  display: flex; }
  #mediathek #mediathek-days > li {
    flex: 1;
    width: 14.2%;
    max-width: 100px;
    float: left;
    display: block;
    color: #282828;
    padding: 5px;
    line-height: 40px;
    height: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    overflow: hidden; }
    #mediathek #mediathek-days > li:last-child {
      max-width: 100%;
      flex-grow: 1; }
    #mediathek #mediathek-days > li:hover, #mediathek #mediathek-days > li.active {
      background: white; }
    #mediathek #mediathek-days > li:last-child {
      border-right: none; }

#mediathek #mediathek-slider {
  width: 25200px; }
  #mediathek #mediathek-slider #mediathek-timeline > li {
    width: 3600px; }
  #mediathek #mediathek-slider .mediathek-hours > li {
    width: 150px; }
  #mediathek #mediathek-slider #mediathek-items > li.span1 {
    width: 75px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin1 {
    margin-left: 75px; }
  #mediathek #mediathek-slider #mediathek-items > li.span2 {
    width: 150px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin2 {
    margin-left: 150px; }
  #mediathek #mediathek-slider #mediathek-items > li.span3 {
    width: 225px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin3 {
    margin-left: 225px; }
  #mediathek #mediathek-slider #mediathek-items > li.span4 {
    width: 300px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin4 {
    margin-left: 300px; }
  #mediathek #mediathek-slider #mediathek-items > li.span5 {
    width: 375px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin5 {
    margin-left: 375px; }
  #mediathek #mediathek-slider #mediathek-items > li.span6 {
    width: 450px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin6 {
    margin-left: 450px; }
  #mediathek #mediathek-slider #mediathek-items > li.span7 {
    width: 525px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin7 {
    margin-left: 525px; }
  #mediathek #mediathek-slider #mediathek-items > li.span8 {
    width: 600px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin8 {
    margin-left: 600px; }
  #mediathek #mediathek-slider #mediathek-items > li.span9 {
    width: 675px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin9 {
    margin-left: 675px; }
  #mediathek #mediathek-slider #mediathek-items > li.span10 {
    width: 750px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin10 {
    margin-left: 750px; }
  #mediathek #mediathek-slider #mediathek-items > li.span11 {
    width: 825px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin11 {
    margin-left: 825px; }
  #mediathek #mediathek-slider #mediathek-items > li.span12 {
    width: 900px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin12 {
    margin-left: 900px; }
  #mediathek #mediathek-slider #mediathek-items > li.span13 {
    width: 975px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin13 {
    margin-left: 975px; }
  #mediathek #mediathek-slider #mediathek-items > li.span14 {
    width: 1050px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin14 {
    margin-left: 1050px; }
  #mediathek #mediathek-slider #mediathek-items > li.span15 {
    width: 1125px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin15 {
    margin-left: 1125px; }
  #mediathek #mediathek-slider #mediathek-items > li.span16 {
    width: 1200px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin16 {
    margin-left: 1200px; }
  #mediathek #mediathek-slider #mediathek-items > li.span17 {
    width: 1275px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin17 {
    margin-left: 1275px; }
  #mediathek #mediathek-slider #mediathek-items > li.span18 {
    width: 1350px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin18 {
    margin-left: 1350px; }
  #mediathek #mediathek-slider #mediathek-items > li.span19 {
    width: 1425px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin19 {
    margin-left: 1425px; }
  #mediathek #mediathek-slider #mediathek-items > li.span20 {
    width: 1500px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin20 {
    margin-left: 1500px; }
  #mediathek #mediathek-slider #mediathek-items > li.span21 {
    width: 1575px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin21 {
    margin-left: 1575px; }
  #mediathek #mediathek-slider #mediathek-items > li.span22 {
    width: 1650px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin22 {
    margin-left: 1650px; }
  #mediathek #mediathek-slider #mediathek-items > li.span23 {
    width: 1725px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin23 {
    margin-left: 1725px; }
  #mediathek #mediathek-slider #mediathek-items > li.span24 {
    width: 1800px; }
  #mediathek #mediathek-slider #mediathek-items > li.margin24 {
    margin-left: 1800px; }

#mediathek.zoomout #mediathek-slider {
  width: 13440px; }
  #mediathek.zoomout #mediathek-slider #mediathek-timeline > li {
    width: 1920px; }
  #mediathek.zoomout #mediathek-slider .mediathek-hours > li {
    width: 80px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span1 {
    width: 40px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin1 {
    margin-left: 40px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span2 {
    width: 80px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin2 {
    margin-left: 80px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span3 {
    width: 120px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin3 {
    margin-left: 120px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span4 {
    width: 160px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin4 {
    margin-left: 160px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span5 {
    width: 200px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin5 {
    margin-left: 200px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span6 {
    width: 240px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin6 {
    margin-left: 240px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span7 {
    width: 280px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin7 {
    margin-left: 280px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span8 {
    width: 320px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin8 {
    margin-left: 320px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span9 {
    width: 360px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin9 {
    margin-left: 360px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span10 {
    width: 400px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin10 {
    margin-left: 400px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span11 {
    width: 440px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin11 {
    margin-left: 440px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span12 {
    width: 480px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin12 {
    margin-left: 480px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span13 {
    width: 520px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin13 {
    margin-left: 520px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span14 {
    width: 560px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin14 {
    margin-left: 560px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span15 {
    width: 600px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin15 {
    margin-left: 600px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span16 {
    width: 640px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin16 {
    margin-left: 640px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span17 {
    width: 680px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin17 {
    margin-left: 680px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span18 {
    width: 720px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin18 {
    margin-left: 720px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span19 {
    width: 760px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin19 {
    margin-left: 760px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span20 {
    width: 800px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin20 {
    margin-left: 800px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span21 {
    width: 840px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin21 {
    margin-left: 840px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span22 {
    width: 880px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin22 {
    margin-left: 880px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span23 {
    width: 920px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin23 {
    margin-left: 920px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.span24 {
    width: 960px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li.margin24 {
    margin-left: 960px; }

#mediathek.zoomout #mediathek-slider #mediathek-items > li a {
  font-size: 14px;
  padding: 10px 5px; }
  #mediathek.zoomout #mediathek-slider #mediathek-items > li a .meta {
    left: 5px;
    font-size: 10px; }

#mediathek #mediathek-debug {
  display: none; }

#mediathek.mode-debug #mediathek-debug {
  display: block;
  position: absolute;
  z-index: 83;
  top: 0;
  left: 100px;
  right: 400px;
  width: auto;
  height: 92px;
  padding: 5px;
  text-align: right;
  background: rgba(10, 10, 10, 0.5);
  color: rgba(255, 255, 255, 0.95);
  font-size: 13px;
  line-height: 18px; }
  #mediathek.mode-debug #mediathek-debug .date, #mediathek.mode-debug #mediathek-debug .file, #mediathek.mode-debug #mediathek-debug .link {
    display: block; }

@media only screen and (max-width: 1000px) {
  body.mediathek-is-active {
    background: #000; }
    body.mediathek-is-active #header {
      display: none; }
    body.mediathek-is-active #page-wrapper {
      display: none; }
    body.mediathek-is-active #persistent {
      height: 100%;
      width: 100%;
      position: absolute; }
    body.mediathek-is-active #mediathek-button {
      bottom: 0;
      right: 0;
      top: auto; }
    body.mediathek-is-active #mediathek {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: auto; }
      body.mediathek-is-active #mediathek #mediathek-days > li {
        line-height: 20px; }
        body.mediathek-is-active #mediathek #mediathek-days > li strong {
          display: block; }
      body.mediathek-is-active #mediathek #mediathek-zoom {
        background-color: transparent; } }

@media only screen and (max-width: 600px) {
  .mediathek-is-active #mediathek-button {
    width: 150px;
    font-size: 20px;
    letter-spacing: -1px; }
    .mediathek-is-active #mediathek-button span {
      display: block; }
  #mediathek #mediathek-days {
    right: 150px; } }

@media only screen and (max-width: 600px) {
  #mediathek #mediathek-days {
    bottom: 50px;
    width: 100%; }
  #mediathek-button {
    width: 200px; }
    #mediathek-button .button-page {
      width: 145px; }
  .mediathek-is-active #mediathek-button {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; }
    .mediathek-is-active #mediathek-button .button-toggle {
      position: absolute;
      right: 0;
      bottom: 0; }
    .mediathek-is-active #mediathek-button span {
      display: block; }
  #mediathek #mediathek-slider, #mediathek #mediathek-slider #mediathek-items, #mediathek #mediathek-slider #mediathek-timeline, #mediathek #mediathek-slider #mediathek-cursor {
    bottom: 50px; } }

@media only screen and (max-width: 430px) {
  #mediathek #mediathek-days > li {
    font-size: 14px;
    padding: 5px 3px; } }

@media only screen and (max-height: 380px) {
  #mediathek #mediathek-zoom {
    height: 45px;
    width: 45px; }
  #mediathek #mediathek-controls {
    top: 10px; }
  #mediathek #mediathek-slider-wrapper {
    top: 46px; }
    #mediathek #mediathek-slider-wrapper #mediathek-items {
      top: 80px; }
  #mediathek #mediathek-slider #mediathek-items li a {
    height: 80px; } }

@media only screen and (max-height: 290px) {
  #mediathek #mediathek-slider-wrapper #mediathek-items {
    top: 65px; }
  #mediathek #mediathek-slider #mediathek-items li a {
    height: 70px; } }

@media only screen and (max-height: 290px) {
  #mediathek #mediathek-slider-wrapper #mediathek-items {
    top: 60px; } }

@media only screen and (max-height: 240px) {
  #mediathek #mediathek-slider-wrapper #mediathek-items {
    top: 60px; }
  #mediathek #mediathek-slider #mediathek-items li a {
    height: 60px;
    padding-top: 5px;
    padding-bottom: 5px; } }

/**
 * Podcast mode.
 */
#mediathek-podcast {
  display: none; }

#mediathek.mode-podcast #mediathek-podcast {
  display: block; }

#mediathek.mode-podcast #mediathek-items {
  display: none; }

#mediathek.mode-podcast #mediathek-timeline {
  display: none; }

#mediathek.mode-podcast #mediathek-days {
  display: none; }

#mediathek.mode-podcast #mediathek-slider {
  width: 80%;
  margin: 0 10%;
  border-top: none; }

#mediathek.mode-podcast #mediathek-podcast {
  z-index: 82;
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 0;
  right: 0;
  background: rgba(248, 248, 248, 0.2);
  color: rgba(0, 0, 0, 0.95);
  padding: 10px;
  font-size: 20px; }
  #mediathek.mode-podcast #mediathek-podcast .sendung {
    font-size: 16px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px; }
  #mediathek.mode-podcast #mediathek-podcast .title {
    text-align: center;
    color: #fff;
    font-weight: bold; }
  #mediathek.mode-podcast #mediathek-podcast .duration,
  #mediathek.mode-podcast #mediathek-podcast .time {
    position: absolute;
    font-size: 16px;
    color: #fff;
    right: 20px;
    bottom: 20px; }
  #mediathek.mode-podcast #mediathek-podcast .time {
    left: 20px; }

/**
 * In-page styles.
 */
.airplan-listen {
  position: relative;
  cursor: pointer; }
  .airplan-listen:after {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    content: '';
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("../images/elements/circle-play@1x.png");
    background-size: 28px 28px;
    background-repeat: no-repeat; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .airplan-listen:after {
        background-image: url("../images/elements/circle-play@1x.png"); } }
    .no-mediaqueries .airplan-listen:after {
      background-image: url("../images/elements/circle-play@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .airplan-listen:after {
        background-image: url("../images/elements/circle-play@2x.png"); } }

a.airplan-podcast {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url("../images/elements/circle-play@1x.png");
  background-size: 28px 28px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-color: transparent; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    a.airplan-podcast {
      background-image: url("../images/elements/circle-play@1x.png"); } }
  .no-mediaqueries a.airplan-podcast {
    background-image: url("../images/elements/circle-play@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    a.airplan-podcast {
      background-image: url("../images/elements/circle-play@2x.png"); } }
  a.airplan-podcast span {
    display: none; }

/*****
 * Frontpage: General.
 ****/
.page-start #content-inner {
  padding: 0;
  background-color: transparent; }

.page-start h1.title {
  display: none; }

.freefm-front .panel-pane {
  background-color: #fff; }
  .freefm-front .panel-pane h2.pane-title {
    text-transform: uppercase;
    text-align: center;
    border: none;
    margin: 0;
    padding: 30px 0;
    font-weight: bold; }
  .freefm-front .panel-pane .pane-content {
    padding: 0 30px 30px 30px; }

.freefm-front .freefm-front-midside h2.pane-title {
  padding: 25px 0; }

.freefm-front .freefm-front-contentbottom .panel-pane {
  background-color: #61686e; }
  .freefm-front .freefm-front-contentbottom .panel-pane h2.pane-title {
    color: #fff;
    text-align: center; }

/*****
 * Frontpage: News Block & Scrollable Vertical Tabs.
 ****/
.freefm-front .panel-pane.pane-views-frontpage-news-block {
  background: transparent; }
  @media only screen and (min-width: 600px) {
    .freefm-front .panel-pane.pane-views-frontpage-news-block {
      position: relative; } }
  .freefm-front .panel-pane.pane-views-frontpage-news-block .pane-content {
    margin-top: -100px !important;
    padding: 0 0 0 30px;
    background: #fff; }
    @media only screen and (max-width: 600px) {
      .freefm-front .panel-pane.pane-views-frontpage-news-block .pane-content {
        padding-left: 20px; } }
  .freefm-front .panel-pane.pane-views-frontpage-news-block:after {
    width: 100%;
    height: 300px;
    content: '';
    display: block;
    background: url("../images/other/icon_explanation.jpg");
    margin-top: 50px; }
  @media only screen and (max-width: 600px) {
    .freefm-front .panel-pane.pane-views-frontpage-news-block h2.pane-title {
      top: 0; } }

/*****
 * Frontpage: Other panes.
 ****/
.pane-views-frontpage-blog-block .pane-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  position: relative; }
  .pane-views-frontpage-blog-block .pane-content .view {
    overflow: hidden;
    *zoom: 1; }
    .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 {
      width: auto; }
      .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 td {
        width: 50%;
        min-width: 50%;
        border: 1px solid #ebe9e6; }
      .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 tr:last-child td {
        border-bottom: 0; }
      @media only screen and (max-width: 600px) {
        .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2, .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 tbody, .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 td, .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 tr {
          display: block;
          width: 100%; }
        .pane-views-frontpage-blog-block .pane-content .view .views-view-grid.cols-2 td {
          border-top: none; } }
    .pane-views-frontpage-blog-block .pane-content .view .blog-item {
      padding: 30px; }
      @media only screen and (max-width: 600px) {
        .pane-views-frontpage-blog-block .pane-content .view .blog-item {
          padding: 20px; } }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item h2 {
        font-size: 21px;
        line-height: 29.4px;
        font-size: 2.1rem;
        line-height: 2.94rem;
        font-weight: 600;
        font-style: italic;
        text-decoration: none;
        text-transform: none;
        margin: 25px 0;
        padding: 0;
        border: none; }
        @media only screen and (max-width: 600px) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item h2 {
            font-size: 18px;
            line-height: 25.2px;
            font-size: 1.8rem;
            line-height: 2.52rem; } }
        .pane-views-frontpage-blog-block .pane-content .view .blog-item h2, .pane-views-frontpage-blog-block .pane-content .view .blog-item h2 a {
          color: #3c3c3c; }
        .pane-views-frontpage-blog-block .pane-content .view .blog-item h2 a:hover {
          color: #dd4931;
          text-decoration: underline; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item .blog-header {
        padding-left: 45px;
        background-position: 0 4px;
        min-height: 44px; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item .blog-slogan {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600; }
        .pane-views-frontpage-blog-block .pane-content .view .blog-item .blog-slogan a {
          color: #282828; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item .submitted {
        color: #b09d8a;
        font-size: 14px;
        line-height: 19.6px;
        font-size: 1.4rem;
        line-height: 1.96rem;
        font-weight: 300; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item p {
        font-size: 16px;
        line-height: 22.4px;
        font-size: 1.6rem;
        line-height: 2.24rem;
        color: #8c8c8c; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.sendung .blog-header {
        background-image: url("../images/icons/04_programm_color@1x.png");
        background-size: 45px 33px;
        background-repeat: no-repeat;
        padding-left: 60px; }
        @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.sendung .blog-header {
            background-image: url("../images/icons/04_programm_color@1x.png"); } }
        .no-mediaqueries .pane-views-frontpage-blog-block .pane-content .view .blog-item.sendung .blog-header {
          background-image: url("../images/icons/04_programm_color@1x.png"); }
        @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.sendung .blog-header {
            background-image: url("../images/icons/04_programm_color@2x.png"); } }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.sendung h2 a:hover {
        color: #19735f; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.rotation .blog-header {
        background-image: url("../images/icons/03_musik_color@1x.png");
        background-size: 36px 39px;
        background-repeat: no-repeat; }
        @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.rotation .blog-header {
            background-image: url("../images/icons/03_musik_color@1x.png"); } }
        .no-mediaqueries .pane-views-frontpage-blog-block .pane-content .view .blog-item.rotation .blog-header {
          background-image: url("../images/icons/03_musik_color@1x.png"); }
        @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.rotation .blog-header {
            background-image: url("../images/icons/03_musik_color@2x.png"); } }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.rotation h2 a:hover {
        color: #245773; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.machmit .blog-header {
        background-image: url("../images/icons/02_mitmachen_color@1x.png");
        background-size: 33px 46px;
        background-repeat: no-repeat; }
        @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.machmit .blog-header {
            background-image: url("../images/icons/02_mitmachen_color@1x.png"); } }
        .no-mediaqueries .pane-views-frontpage-blog-block .pane-content .view .blog-item.machmit .blog-header {
          background-image: url("../images/icons/02_mitmachen_color@1x.png"); }
        @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.machmit .blog-header {
            background-image: url("../images/icons/02_mitmachen_color@2x.png"); } }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.machmit h2 a:hover {
        color: #dd4931; }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.info .blog-header {
        background-image: url("../images/icons/01_info_color@1x.png");
        background-size: 31px 41px;
        background-repeat: no-repeat; }
        @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.info .blog-header {
            background-image: url("../images/icons/01_info_color@1x.png"); } }
        .no-mediaqueries .pane-views-frontpage-blog-block .pane-content .view .blog-item.info .blog-header {
          background-image: url("../images/icons/01_info_color@1x.png"); }
        @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          .pane-views-frontpage-blog-block .pane-content .view .blog-item.info .blog-header {
            background-image: url("../images/icons/01_info_color@2x.png"); } }
      .pane-views-frontpage-blog-block .pane-content .view .blog-item.info h2 a:hover {
        color: #f98100; }

.freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single {
  background-color: #61686e;
  text-align: center;
  overflow: hidden;
  *zoom: 1; }
  .freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single, .freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single a, .freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single h2.pane-title {
    color: #fff; }
  .freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single .pane-content {
    padding: 0 40px 30px 40px; }

.view-platte-der-woche.view-display-id-block_single article {
  text-align: center; }
  .view-platte-der-woche.view-display-id-block_single article h2 {
    margin: 0;
    border: none;
    background-color: #61686e;
    line-height: 1em;
    font-style: italic;
    text-transform: none;
    font-size: 21px;
    line-height: 29.4px;
    font-size: 2.1rem;
    line-height: 2.94rem; }
    .view-platte-der-woche.view-display-id-block_single article h2, .view-platte-der-woche.view-display-id-block_single article h2 a {
      color: #fff; }
  .view-platte-der-woche.view-display-id-block_single article .field-name-field-coverbild {
    padding: 10px; }
  .view-platte-der-woche.view-display-id-block_single article .field-name-field-artist,
  .view-platte-der-woche.view-display-id-block_single article .node-intro-kw {
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem; }

.view-platte-der-woche.view-display-id-block_single .flexslider {
  background: transparent;
  border: none;
  margin-bottom: 0; }
  .view-platte-der-woche.view-display-id-block_single .flexslider .flex-control-nav {
    display: none; }

.view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a,
.view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a {
  opacity: 1;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  display: block !important;
  width: 15px;
  height: 28px; }
  .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before,
  .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
    content: '' !important;
    background-image: url("../images/elements/arrow-widget-white@1x.png");
    background-size: 15px 28px;
    background-repeat: no-repeat;
    width: 15px;
    height: 28px; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before,
      .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
        background-image: url("../images/elements/arrow-widget-white@1x.png"); } }
    .no-mediaqueries .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before, .no-mediaqueries
    .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
      background-image: url("../images/elements/arrow-widget-white@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before,
      .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
        background-image: url("../images/elements/arrow-widget-white@2x.png"); } }
    .view-display-id-block_triple .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before, .view-display-id-block_triple
    .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
      background-image: url("../images/elements/arrow-widget-grey@1x.png");
      background-size: 15px 28px;
      background-repeat: no-repeat; }
      @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
        .view-display-id-block_triple .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before, .view-display-id-block_triple
        .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
          background-image: url("../images/elements/arrow-widget-grey@1x.png"); } }
      .no-mediaqueries .view-display-id-block_triple .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before, .no-mediaqueries .view-display-id-block_triple
      .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
        background-image: url("../images/elements/arrow-widget-grey@1x.png"); }
      @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        .view-display-id-block_triple .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a:before, .view-display-id-block_triple
        .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a:before {
          background-image: url("../images/elements/arrow-widget-grey@2x.png"); } }
  .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a.flex-prev,
  .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a.flex-prev {
    left: -22px; }
  .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a.flex-next,
  .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a.flex-next {
    right: -22px; }
    .view-platte-der-woche.view-display-id-block_single .flexslider .flex-direction-nav a.flex-next:before,
    .view-platte-der-woche.view-display-id-block_single .flexslider:hover .flex-direction-nav a.flex-next:before {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg); }

.freefm-front .pane-views-frontpage-sendungen-block {
  background-color: #d2d2d4 !important; }
  .freefm-front .pane-views-frontpage-sendungen-block, .freefm-front .pane-views-frontpage-sendungen-block h2, .freefm-front .pane-views-frontpage-sendungen-block a {
    color: #282828; }
  .freefm-front .pane-views-frontpage-sendungen-block .filterinput {
    width: 100%;
    margin: 0 0 1em 0;
    height: 30px;
    padding: 2px 5px 2px 30px;
    background-image: url("../images/elements/lupe@1x.png");
    background-size: 19px 19px;
    background-repeat: no-repeat;
    background-position: 6px 5px;
    border: none;
    color: #8c8c8c; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .freefm-front .pane-views-frontpage-sendungen-block .filterinput {
        background-image: url("../images/elements/lupe@1x.png"); } }
    .no-mediaqueries .freefm-front .pane-views-frontpage-sendungen-block .filterinput {
      background-image: url("../images/elements/lupe@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .freefm-front .pane-views-frontpage-sendungen-block .filterinput {
        background-image: url("../images/elements/lupe@2x.png"); } }
  .freefm-front .pane-views-frontpage-sendungen-block .view-content {
    height: 230px;
    overflow: hidden;
    -ms-overflow-y: scroll;
    /* IE8 */
    overflow-y: scroll; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content::-webkit-scrollbar-thumb {
      background: #fff; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content::-webkit-scrollbar-track {
      background: #8c8c8c; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content {
      scrollbar-face-color: #fff;
      scrollbar-track-color: #8c8c8c; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content li.views-row {
      list-style-type: none;
      margin: 0 0 2px 0; }
    .freefm-front .pane-views-frontpage-sendungen-block .view-content a {
      font-style: italic;
      font-size: 21px;
      line-height: 29.4px;
      font-size: 2.1rem;
      line-height: 2.94rem; }

.freefm-front .pane-block-7 {
  background-image: url("../images/teaser/04.8_teaser-small02@1x.jpg");
  background-size: "1000px" "330px";
  height: 330px;
  padding: 90px 40px;
  font-size: 40px;
  line-height: 56px;
  font-size: 4rem;
  line-height: 5.6rem;
  color: #fff; }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .freefm-front .pane-block-7 {
      background-image: url("../images/teaser/04.8_teaser-small02@1x.jpg"); } }
  .no-mediaqueries .freefm-front .pane-block-7 {
    background-image: url("../images/teaser/04.8_teaser-small02@1x.jpg"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .freefm-front .pane-block-7 {
      background-image: url("../images/teaser/04.8_teaser-small02@2x.jpg");
      background-size: "1000px" "330px"; } }
  .freefm-front .pane-block-7 p {
    margin: 0; }
  @media only screen and (max-width: 500px) {
    .freefm-front .pane-block-7 {
      font-size: 30px;
      line-height: 42px;
      font-size: 3rem;
      line-height: 4.2rem;
      padding: 70px 30px;
      height: 250px;
      background-position: center center; } }
  @media only screen and (max-width: 400px) {
    .freefm-front .pane-block-7 {
      font-size: 25px;
      line-height: 35px;
      font-size: 2.5rem;
      line-height: 3.5rem;
      padding: 50px 20px;
      height: 200px;
      background-position: center center; } }

.freefm-front .freefm-front-bottomleft {
  min-width: 500px;
  margin-top: -50px; }
  .freefm-front .freefm-front-bottomleft .pane-map .pane-content {
    padding: 0; }

.freefm-front .freefm-front-bottomright .panel-pane {
  background-color: #61686e;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem; }
  .freefm-front .freefm-front-bottomright .panel-pane .pane-content {
    overflow: hidden;
    *zoom: 1; }
    @media only screen and (max-width: 600px) {
      .freefm-front .freefm-front-bottomright .panel-pane .pane-content .columns-wrapper .col {
        float: none;
        width: 100%;
        padding: 0;
        overflow: hidden;
        *zoom: 1; } }
  .freefm-front .freefm-front-bottomright .panel-pane h3, .freefm-front .freefm-front-bottomright .panel-pane h4 {
    margin: 5px 0 0 0; }
  .freefm-front .freefm-front-bottomright .panel-pane td:first-child {
    width: 80px; }
  .freefm-front .freefm-front-bottomright .panel-pane, .freefm-front .freefm-front-bottomright .panel-pane a, .freefm-front .freefm-front-bottomright .panel-pane h2, .freefm-front .freefm-front-bottomright .panel-pane h3, .freefm-front .freefm-front-bottomright .panel-pane h4 {
    color: #fff; }
  .freefm-front .freefm-front-bottomright .panel-pane p {
    margin: 0; }

.pane-views-frontpage-termine-block {
  margin-bottom: 50px; }
  .pane-views-frontpage-termine-block .pane-content {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.freefm-views-slider {
  position: relative; }
  .freefm-views-slider .scroll-left,
  .freefm-views-slider .scroll-right {
    position: absolute;
    top: 93px;
    width: 15px;
    height: 28px;
    background-image: url("../images/elements/arrow-widget-grey@1x.png");
    background-size: 15px 28px;
    background-repeat: no-repeat;
    cursor: pointer; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .freefm-views-slider .scroll-left,
      .freefm-views-slider .scroll-right {
        background-image: url("../images/elements/arrow-widget-grey@1x.png"); } }
    .no-mediaqueries .freefm-views-slider .scroll-left, .no-mediaqueries
    .freefm-views-slider .scroll-right {
      background-image: url("../images/elements/arrow-widget-grey@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .freefm-views-slider .scroll-left,
      .freefm-views-slider .scroll-right {
        background-image: url("../images/elements/arrow-widget-grey@2x.png"); } }
    .freefm-views-slider .scroll-left span,
    .freefm-views-slider .scroll-right span {
      display: none; }
    .freefm-views-slider .scroll-left.disabled,
    .freefm-views-slider .scroll-right.disabled {
      opacity: .3; }
  .freefm-views-slider .scroll-left {
    left: -30px; }
  .freefm-views-slider .scroll-right {
    right: -39px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .freefm-views-slider .expand {
    position: absolute;
    right: -30px;
    top: -68px;
    cursor: pointer;
    background-image: url("../images/icons/icn_sprite.svg");
    background-repeat: no-repeat;
    background-position: -32px 0px;
    width: 32px;
    height: 20px; }
    .freefm-views-slider .expand span {
      display: none; }
    .freefm-views-slider .expand.expanded {
      background-image: url("../images/icons/icn_sprite.svg");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      width: 32px;
      height: 20px; }
  .freefm-views-slider .view-content {
    overflow: hidden;
    *zoom: 1;
    overflow: hidden;
    height: 200px;
    position: relative;
    width: 100%; }
    @media only screen and (max-width: 600px) {
      .freefm-views-slider .view-content {
        height: 240px; } }
    .freefm-views-slider .view-content .item-list {
      overflow: hidden;
      *zoom: 1; }
  .freefm-views-slider li.views-row {
    list-style-type: none;
    display: block;
    float: left;
    background: #fff;
    padding: 10px;
    width: 200px;
    height: 200px;
    text-align: center;
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem;
    position: relative;
    margin: 0 20px 0 0; }
    @media only screen and (max-width: 600px) {
      .freefm-views-slider li.views-row {
        height: 240px; } }
  .freefm-views-slider.expanded {
    height: auto; }
    .freefm-views-slider.expanded > .view-content {
      height: auto; }
      .freefm-views-slider.expanded > .view-content > .item-list {
        height: auto; }
    .freefm-views-slider.expanded li.views-row {
      width: auto;
      float: none;
      height: auto;
      border-bottom: 1px solid #61686e;
      font-size: 18px;
      line-height: 25.2px;
      font-size: 1.8rem;
      line-height: 2.52rem;
      padding: 15px 35px 15px 190px; }
      .freefm-views-slider.expanded li.views-row.views-row-even {
        background-color: #f1f1f1; }
    @media only screen and (max-width: 600px) {
      .freefm-views-slider.expanded li.views-row {
        padding: 15px; } }

.view-frontpage-termine {
  margin: 0 60px;
  height: 220px; }
  .view-frontpage-termine .scroll-left,
  .view-frontpage-termine .scroll-right {
    background-image: url("../images/elements/arrow-widget-white@1x.png");
    background-size: 15px 28px;
    background-repeat: no-repeat; }
    @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
      .view-frontpage-termine .scroll-left,
      .view-frontpage-termine .scroll-right {
        background-image: url("../images/elements/arrow-widget-white@1x.png"); } }
    .no-mediaqueries .view-frontpage-termine .scroll-left, .no-mediaqueries
    .view-frontpage-termine .scroll-right {
      background-image: url("../images/elements/arrow-widget-white@1x.png"); }
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      .view-frontpage-termine .scroll-left,
      .view-frontpage-termine .scroll-right {
        background-image: url("../images/elements/arrow-widget-white@2x.png"); } }
  .view-frontpage-termine .expand {
    background-image: url("../images/icons/icn_sprite.svg");
    background-repeat: no-repeat;
    background-position: -32px -20px;
    width: 32px;
    height: 20px; }
    .view-frontpage-termine .expand.expanded {
      background-image: url("../images/icons/icn_sprite.svg");
      background-repeat: no-repeat;
      background-position: 0px -20px;
      width: 32px;
      height: 20px; }
  @media only screen and (min-width: 1000px) {
    .view-frontpage-termine > .view-filters {
      position: absolute;
      left: 0;
      top: -74px; } }
  .view-frontpage-termine > .view-filters .views-widget-filter-field_termin_typ_tid label {
    display: none; }
  .view-frontpage-termine > .view-filters .views-widget-filter-field_termin_typ_tid select {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    width: 240px;
    padding: 3px;
    font-size: 18px;
    line-height: 25.2px;
    font-size: 1.8rem;
    line-height: 2.52rem;
    font-style: italic; }
    .view-frontpage-termine > .view-filters .views-widget-filter-field_termin_typ_tid select option {
      color: #61686e;
      background: #fff;
      padding: 5px 8px; }
  .view-frontpage-termine > .view-content > .item-list {
    height: 200px; }
  .view-frontpage-termine > .view-content li.views-row {
    padding-top: 30px; }
  .view-frontpage-termine .views-field {
    text-align: center; }
  .view-frontpage-termine .views-field-field-datum {
    font-size: 21px;
    line-height: 29.4px;
    font-size: 2.1rem;
    line-height: 2.94rem;
    font-weight: bold; }
  .view-frontpage-termine .views-field-field-datum-1,
  .view-frontpage-termine .views-field-field-ort {
    color: #b09d8a;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .view-frontpage-termine .views-field-field-datum-1 {
    bottom: 30px; }
  .view-frontpage-termine .views-field-title {
    font-size: 21px;
    line-height: 29.4px;
    font-size: 2.1rem;
    line-height: 2.94rem;
    font-style: italic; }
    .view-frontpage-termine .views-field-title a {
      color: #282828; }
      .view-frontpage-termine .views-field-title a:focus {
        outline: none;
        text-decoration: underline; }
  .view-frontpage-termine .views-field-name span {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: #f98100;
    color: #fff;
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 3px 8px; }
    .view-frontpage-termine .views-field-name span.termin-tid-21, .view-frontpage-termine .views-field-name span.termin-tid-22 {
      background: #dd4931; }
    .view-frontpage-termine .views-field-name span.termin-tid-20 {
      background: #19735f; }
  .view-frontpage-termine .views-field-body {
    display: none; }
  .view-frontpage-termine.expanded > .view-content li.views-row {
    overflow: hidden;
    *zoom: 1;
    padding-top: 22px;
    padding-bottom: 22px; }
  .view-frontpage-termine.expanded .views-field {
    text-align: left; }
  .view-frontpage-termine.expanded .views-field-field-datum {
    position: absolute;
    left: 35px;
    top: 22px; }
    @media only screen and (max-width: 600px) {
      .view-frontpage-termine.expanded .views-field-field-datum {
        position: static; } }
  .view-frontpage-termine.expanded .views-field-field-ort {
    position: static; }
  .view-frontpage-termine.expanded .views-field-field-datum-1, .view-frontpage-termine.expanded .views-field-field-ort {
    float: left;
    color: #b09d8a;
    font-size: 14px;
    line-height: 19.6px;
    font-size: 1.4rem;
    line-height: 1.96rem; }
  .view-frontpage-termine.expanded .views-field-field-datum-1 {
    margin-right: .5em;
    position: static; }
    .view-frontpage-termine.expanded .views-field-field-datum-1 .field-content:after {
      content: ' | ';
      display: inline-block;
      margin-left: .5em; }
  .view-frontpage-termine.expanded .views-field-body {
    clear: left;
    width: 100%;
    color: #8c8c8c;
    margin-top: 40px;
    margin-left: -155px;
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem; }
    .view-frontpage-termine.expanded .views-field-body p {
      margin: 0; }

.view-platte-der-woche.view-display-id-block_triple {
  height: 280px;
  margin: 20px 30px; }
  .view-platte-der-woche.view-display-id-block_triple .view-content {
    height: 280px; }
  .view-platte-der-woche.view-display-id-block_triple li.views-row {
    height: 280px; }
  .view-platte-der-woche.view-display-id-block_triple article {
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    bottom: 0; }
  .view-platte-der-woche.view-display-id-block_triple .node-intro-kw {
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem;
    text-align: left; }
  .view-platte-der-woche.view-display-id-block_triple h2 {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 21px;
    line-height: 29.4px;
    font-size: 2.1rem;
    line-height: 2.94rem;
    font-weight: normal;
    font-style: italic;
    text-align: left;
    color: #282828;
    border: none;
    text-transform: none;
    max-width: 100%; }
  .view-platte-der-woche.view-display-id-block_triple .field-name-field-artist {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-size: 21px;
    line-height: 29.4px;
    font-size: 2.1rem;
    line-height: 2.94rem; }
  .view-platte-der-woche.view-display-id-block_triple .field-name-field-coverbild {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    max-width: 180px; }
    .view-platte-der-woche.view-display-id-block_triple .field-name-field-coverbild img {
      width: 100%;
      height: auto; }
  .view-platte-der-woche.view-display-id-block_triple.expanded {
    height: auto; }
    @media only screen and (max-width: 600px) {
      .view-platte-der-woche.view-display-id-block_triple.expanded {
        margin: 10px -20px; } }
    .view-platte-der-woche.view-display-id-block_triple.expanded > .view-content {
      height: auto; }
    .view-platte-der-woche.view-display-id-block_triple.expanded .views-row {
      overflow: hidden;
      *zoom: 1;
      height: 100px;
      padding: 0; }
      @media only screen and (max-width: 600px) {
        .view-platte-der-woche.view-display-id-block_triple.expanded .views-row {
          width: 100% !important; }
          .view-platte-der-woche.view-display-id-block_triple.expanded .views-row article {
            right: 0; } }
    .view-platte-der-woche.view-display-id-block_triple.expanded .node-intro-kw {
      margin: 10px; }
    .view-platte-der-woche.view-display-id-block_triple.expanded h2,
    .view-platte-der-woche.view-display-id-block_triple.expanded .field-name-field-artist {
      position: static; }
    .view-platte-der-woche.view-display-id-block_triple.expanded h2,
    .view-platte-der-woche.view-display-id-block_triple.expanded .field-name-field-artist {
      float: left;
      margin: 10px 10px;
      font-size: 21px;
      line-height: 29.4px;
      font-size: 2.1rem;
      line-height: 2.94rem; }
    .view-platte-der-woche.view-display-id-block_triple.expanded h2 {
      margin-right: 0; }
    .view-platte-der-woche.view-display-id-block_triple.expanded .field-name-field-coverbild {
      width: 80px;
      height: 80px;
      right: 10px;
      top: 10px;
      left: auto; }
      .view-platte-der-woche.view-display-id-block_triple.expanded .field-name-field-coverbild img {
        width: 100%;
        height: 100%; }

.page-mediathek #content-inner {
  background: transparent;
  padding: 0; }

.page-mediathek #content-header {
  padding: 30px 30px 0 30px;
  background: #fff; }

.page-mediathek .mediathek-content,
.page-mediathek .mediathek-podcasts {
  background: #fff;
  position: relative; }

.page-mediathek .mediathek-content {
  padding: 20px 30px 30px 30px; }

.page-mediathek .mediathek-show-link {
  color: #f98100;
  font-weight: bold;
  display: block;
  padding-right: 30px;
  background-image: url("../images/elements/arrow_sendeblog-orange@1x.png");
  background-size: 20px 14px;
  background-repeat: no-repeat;
  background-position: right center; }
  @media only screen and (min-width: 600px) {
    .page-mediathek .mediathek-show-link {
      position: absolute;
      bottom: 30px;
      right: 30px; } }
  @media (min--moz-device-pixel-ratio: 1), (-o-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx) {
    .page-mediathek .mediathek-show-link {
      background-image: url("../images/elements/arrow_sendeblog-orange@1x.png"); } }
  .no-mediaqueries .page-mediathek .mediathek-show-link {
    background-image: url("../images/elements/arrow_sendeblog-orange@1x.png"); }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .page-mediathek .mediathek-show-link {
      background-image: url("../images/elements/arrow_sendeblog-orange@2x.png"); } }

@media only screen and (min-width: 600px) {
  .page-mediathek .mediathek-showtime {
    position: absolute;
    top: -35px;
    right: 30px; } }

.page-mediathek .square-title {
  position: absolute;
  top: -50px;
  left: 0; }

.page-mediathek h1.title {
  margin: 50px 0 0 0;
  font-style: italic;
  border: none;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  letter-spacing: .2px;
  color: #686868; }
  @media only screen and (min-width: 600px) {
    .page-mediathek h1.title {
      padding-right: 400px; } }

.page-mediathek .mediathek-content .node {
  position: static; }

.page-mediathek .mediathek-content .node-artikel .podcast-main > h2 {
  display: none; }

.page-mediathek .mediathek-content .node-artikel .field-name-body {
  color: #8c8c8c; }

.mediathek-podcasts {
  overflow: hidden;
  *zoom: 1;
  padding: 30px 0 27px 0;
  margin: 30px 0; }
  @media only screen and (max-width: 600px) {
    .mediathek-podcasts {
      margin: 20px 0; } }
  .mediathek-podcasts > h2 {
    border-bottom: 1px solid #d2d2d4;
    margin: 0 30px; }
    @media only screen and (max-width: 600px) {
      .mediathek-podcasts > h2 {
        margin: 0 20px; } }
  .mediathek-podcasts ul.pager {
    padding-top: 30px;
    margin-bottom: 0; }
  @media only screen and (max-width: 600px) {
    .mediathek-podcasts .view-filters {
      margin: 10px 20px; } }
  @media only screen and (min-width: 600px) {
    .mediathek-podcasts .view-filters {
      position: absolute;
      top: 22px;
      right: 82px;
      height: 35px; } }
  .mediathek-podcasts .view-filters .views-exposed-widget {
    padding: 0; }
  .mediathek-podcasts .view-filters label, .mediathek-podcasts .view-filters .views-widget, .mediathek-podcasts .view-filters .views-submit-button {
    float: left;
    margin: 0; }
  .mediathek-podcasts .view-filters label {
    font-weight: 600;
    text-transform: uppercase;
    margin: 4px 10px 0 0; }

.mediathek-podcasts #podcast-view-toggle {
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  background-image: url("../images/icons/icn_sprite.svg");
  background-repeat: no-repeat;
  background-position: -32px 0px;
  width: 32px;
  height: 20px; }
  @media only screen and (max-width: 600px) {
    .mediathek-podcasts #podcast-view-toggle {
      display: none; } }

.mediathek-podcasts.podcast-list #podcast-view-toggle {
  background-image: url("../images/icons/icn_sprite.svg");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 32px;
  height: 20px; }

.mediathek-podcasts .views-row {
  border-bottom: 1px solid #d2d2d4;
  position: relative;
  padding: 20px; }
  @media only screen and (min-width: 600px) {
    .mediathek-podcasts .views-row {
      float: left;
      width: 33%;
      border-right: 1px solid #d2d2d4;
      height: 300px;
      padding: 20px 30px; } }
  .mediathek-podcasts .views-row > article {
    position: static; }
  .mediathek-podcasts .views-row:nth-child(3n) {
    border-right: none; }
  .mediathek-podcasts .views-row .podcast-main {
    max-height: 173px;
    overflow: hidden; }
  .mediathek-podcasts .views-row .podcast-footer {
    overflow: hidden;
    *zoom: 1; }
    @media only screen and (min-width: 600px) {
      .mediathek-podcasts .views-row .podcast-footer {
        position: absolute;
        bottom: 20px;
        left: 30px;
        right: 30px; } }
    .mediathek-podcasts .views-row .podcast-footer a.airplan-podcast {
      float: right; }

.mediathek-podcasts.podcast-list .views-row {
  width: 100%;
  border-right: none;
  float: none;
  height: auto;
  padding: 18px 30px;
  overflow: hidden;
  *zoom: 1; }
  .mediathek-podcasts.podcast-list .views-row .node-vm-podcast {
    display: flex;
    flex-wrap: nowrap; }
  .mediathek-podcasts.podcast-list .views-row:nth-child(2n) {
    background-color: #f4f4f3; }
  .mediathek-podcasts.podcast-list .views-row .podcast-header {
    flex: 0 0 230px;
    flex-grow: 0; }
  .mediathek-podcasts.podcast-list .views-row .podcast-main {
    flex-grow: 1;
    flex: 1;
    padding: 0 20px; }
    .mediathek-podcasts.podcast-list .views-row .podcast-main h2 {
      margin-top: 0 !important; }
  .mediathek-podcasts.podcast-list .views-row .podcast-footer {
    position: static;
    flex: 0 0 50px; }
  .mediathek-podcasts.podcast-list .views-row .field-name-body {
    display: none; }

.mediathek-podcasts .view-empty {
  padding: 30px; }

.mediathek-podcasts .node-vm-podcast .field-name-field-sendung {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22.4px;
  font-size: 1.6rem;
  line-height: 2.24rem; }
  .mediathek-podcasts .node-vm-podcast .field-name-field-sendung a {
    color: #282828; }

.mediathek-podcasts .node-vm-podcast .submitted {
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem; }

.mediathek-podcasts .node-vm-podcast h2 {
  font-weight: normal;
  border: none;
  margin: 10px 0;
  font-size: 21px;
  line-height: 29.4px;
  font-size: 2.1rem;
  line-height: 2.94rem;
  color: #282828;
  text-transform: none;
  font-style: italic; }
  .mediathek-podcasts .node-vm-podcast h2 a {
    color: #282828; }

.mediathek-podcasts .node-vm-podcast .field-name-body {
  font-size: 14px;
  line-height: 19.6px;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #8c8c8c; }

.mediathek-podcasts .node-vm-podcast .field-name-field-audio {
  margin: 0;
  padding: 0;
  background: transparent; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .sidebar,
  #navigation,
  #header-region,
  #search-theme-form,
  #footer,
  .breadcrumb,
  .tabs,
  .feed-icon,
  .links {
    display: none; }
  #page {
    width: 100%; }
  #content,
  #content #content-inner,
  .content,
  .title {
    margin: 20px 0;
    width: auto; }
  a:hover, a:active, a:link, a:visited {
    color: black; }
  #content a:link:after, #content a:visited:after {
    content: " (" attr(href) ") ";
    font-size: 11px;
    line-height: 15.4px;
    font-size: 1.1rem;
    line-height: 1.54rem;
    font-weight: normal; } }

.inline-table, .inline-table tr, .inline-table th, .inline-table tr.odd, .inline-table tr.even, .inline-table tbody, .inline-table thead {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0; }

.inline-table td, .inline-table tr.odd td, .inline-table tr.even td {
  border: none;
  background: transparent;
  padding: 0 3rem 0 0; }
  .inline-table td:first-child, .inline-table tr.odd td:first-child, .inline-table tr.even td:first-child {
    white-space: nowrap;
    width: 0%; }
  .inline-table td:last-child, .inline-table tr.odd td:last-child, .inline-table tr.even td:last-child {
    padding-right: 0;
    width: 100%; }

#sidebar-first #block-menu_block-3 ul.menu li {
  padding: 0; }
  #sidebar-first #block-menu_block-3 ul.menu li > a {
    padding-left: 30px;
    padding-right: 30px; }

#sidebar-first #block-menu_block-3 ul.menu li.active-trail {
  background: transparent; }

#sidebar-first #block-menu_block-3 ul.menu li.active > a {
  background: #fff; }

#sidebar-first #block-menu_block-3 ul.menu ul.menu {
  padding-left: 20px; }
  #sidebar-first #block-menu_block-3 ul.menu ul.menu li {
    font-size: 16px;
    line-height: 22.4px;
    font-size: 1.6rem;
    line-height: 2.24rem; }
    #sidebar-first #block-menu_block-3 ul.menu ul.menu li a {
      padding-top: 5px;
      padding-bottom: 3px; }

.freefm-team h3 {
  margin-top: 1em;
  border-top: 1px solid #ccc;
  padding-top: 1em; }

.freefm-team > div:first-child > h3:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0; }

.freefm-team h4 {
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  font-weight: strong;
  color: #282828;
  margin-top: 1em; }

.freefm-team h5 {
  font-size: 18px;
  line-height: 25.2px;
  font-size: 1.8rem;
  line-height: 2.52rem;
  font-weight: strong;
  color: #b09d8a; }

.freefm-team .nodes {
  overflow: hidden;
  *zoom: 1; }
  @media only screen and (min-width: 600px) {
    .freefm-team .nodes article {
      width: 33%;
      float: left;
      clear: none; } }
