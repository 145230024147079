////////////////////////////////////////////////////////////////////////////////
// Custom Sass Mixins
// --------------------
// This file contains custom Sass mixins and functions. It will be included 
// in any files that import the "base" partial. To use a mixin from this 
// file, add this line to the top of your .scss file:
//   @import "base";
// Then to use a mixin for a particular rule, add this inside the ruleset's
// curly brackets:
//   @include mix-in-name;
////////////////////////////////////////////////////////////////////////////////

//
// Custom Functions
////////////////////////////////////////



//
// Custom Mixins
////////////////////////////////////////

// Mixins for retina-img handling.

@mixin non-retina {
  @media (min--moz-device-pixel-ratio: 1),
  (-o-min-device-pixel-ratio: 1),
  (-webkit-min-device-pixel-ratio: 1),
  (min-device-pixel-ratio: 1),
  (min-resolution: 1dppx) {
    @content;
  }
  .no-mediaqueries & {
    @content;
  }
}

@mixin retina {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    @content;
  }
}

@mixin bgimg($image, $width: 50%, $height: auto, $type: 'png') {
  background-image: url("../images/#{$image}@1x.#{$type}");
  background-size: $width $height;
  @include non-retina {
    background-image: url("../images/#{$image}@1x.#{$type}");
  }
  @include retina {
    background-image: url("../images/#{$image}@2x.#{$type}");
    background-size: $width $height;
  }
}

@mixin bgicon($image, $width, $height, $type: 'png') {
  background-image: url("../images/#{$image}@1x.#{$type}");
  @include non-retina {
    background-image: url("../images/#{$image}@1x.#{$type}");
  }
  @include retina {
    background-image: url("../images/#{$image}@2x.#{$type}");
  }
  background-size: $width $height;
  background-repeat: no-repeat;
}

@mixin svgicon($image, $width, $height) {
  background-image: url("../images/#{$image}.svg");
  background-size: $width $height;
  background-repeat: no-repeat;
}

// syntax is (x, y, w, h)
$svgicons: (
  'grid':       ( 0,  0, 32, 20),
  'list':        (32,  0, 32, 20),
  'grid-white': ( 0, 20, 32, 20),
  'list-white':  (32, 20, 32, 20),
  'search':  (0, 40, 42, 42),
);

@mixin svgicon-sprite($image) {
  background-image: url("../images/icons/icn_sprite.svg");
  background-repeat: no-repeat;
  $icon: map-get($svgicons, $image);
  background-position: nth($icon, 1) * (-1) + px + ' ' + nth($icon, 2) * (-1) + 'px';
  width: nth($icon, 3) + px;
  height: nth($icon, 4) + px;
}


//
// REM sizing
///////////////////////////////////
@mixin font-size($sizeValue: 1.6, $line: $sizeValue * 1.4){
  font-size: ($sizeValue * 1) + px;
  line-height: ($line * 1) + px;
  font-size: ($sizeValue / 10) + rem;
  line-height: ($line / 10) + rem;
}

@mixin pad-size($sizeValue: 1){
  padding: ($sizeValue * 1) + px;
  padding: ($sizeValue / 10) + rem;
}
// Breakpoint mixin
@mixin breakpoint-min($min) {
  @include breakpoint('', $min) {
    @content;
  }
}
@mixin breakpoint-max($max) {
  @include breakpoint($max) {
    @content;
  }
}
@mixin breakpoint($max, $min: '') {
  @if ($max != '' and $min != '') {
    @media only screen and (min-width: $min) and (max-width: $max) { @content; }
  }
  @if ($max != '' and $min == '') {
    @media only screen and (max-width: $max) { @content; }
  }
  @if ($max == '' and $min != '') {
    @media only screen and (min-width: $min) { @content; }
  }

  // @if ($min != '' && $max != '') {
  //   @media only screen and (min-width: $min) and (max-width: $max) { @content; }
  // } 
  // @else {
  //   @media only screen and (max-width: $max) { @content; }
  // }
}

// Hide element visually, but have it available for screenreaders.
@mixin element-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static !important;
  width: auto;
}

// Hide element visually, but have it available for screenreaders 
// and make it focusable
@mixin element-invisible-focusable {
  @include element-invisible;
  &:active,
  &:focus { // Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
    @include element-invisible-off;
  }
}

// Clearfix to contain floats: h5bp.com/q
@mixin my-clearfix {
  &:before { // prevent top margin colapse
    content: "";
    display: table;
  }
  @include pie-clearfix;
}

// Float element left with margins
@mixin my-float-left {
  @include float-left;
  margin: 0 10px 5px 0;
}

// Float element right with margins
@mixin my-float-right {
  @include float-right;
  margin: 0 0 5px 10px;
}


// Color based on page type.
@mixin pagecolor($property: color) {
  #{$property}: $color_text;
  .page-freefm-info & {
    #{$property}: $color_minfo;
  }
  .page-freefm-mediathek & {
    #{$property}: $color_minfo;
  }
  .page-freefm-mitmachen & {
    #{$property}: $color_mmitmachen;
  }
  .page-freefm-musik & {
    #{$property}: $color_mmusik;
  }
  .page-freefm-programm & {
    #{$property}: $color_mprogramm;
  }
  .page-freefm-projekte & {
    #{$property}: $color_mprojekte;
  }
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($primary, white, 75%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  &::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  &::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}


%buttonlink {
  background: $color_minfo;
  text-align: center;
  display: inline-block;
  &, &:visited {
    color: #fff;
  }
  padding: 5px 10px;
  text-decoration: none;
  &:hover {
    background: darken($color_minfo, 10%);
    text-decoration: none;
  }
}

%square-title {
  @include font-size(25);
  margin: 0;
  padding: 0;
  width: 100px;
  height: 100px;
  // TODO: Replace with vars.
  color: #fff;
  background: $c07;
  line-height: 100px;
  vertical-align: middle;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

%pane-square-title {
  h2.pane-title {
    @extend %square-title;
    top: -50px;
    // @include breakpoint($mobile) {
    //   top: 0;
    // }
  }
  .pane-content {
    margin-top: -50px;
  }
}

