#mediathek #mediathek-inner {
  display: none;
}

#mediathek #mediathek-header {
  display: none;
}

body.mediathek-is-active {
  .page-freefm-teaser {
    #page-top {
      filter: blur(5px);
      overflow: hidden;
    }
    #section-teaser .block-block {
      display: none;
    }
  }

  #mediathek {
    #mediathek-inner, #mediathek-header {
      display: block;
    }
  }
}

body:not(.mediathek-is-active) {
  #mediathek.state-playing {
    #mediathek-header {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 75px;
      background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.4));
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,1));
      }

    }
    #mediathek-controls {
      .live, .audio {
        display: none;
        // opacity: 0;
      }
    }
  }
}

#persistent {
  position: relative;
  overflow: visible;
}

#mediathek-button {
  @extend %sidebar-block-title;
  width: 240px;
  position: absolute;
  right: 0;
  top: 440px;
  background: $color_minfo;
  cursor: pointer;
  z-index: 80;
  padding: 0;
  > span {
    display: inline-block;
    padding: 6px;
    height: 50px;
    text-align: center;
    vertical-align: top;
    font-weight: bold;
    letter-spacing: 0;
    position: relative;
  }
  .button-page {
    width: 190px;
  }
  .button-toggle {
    width: 49px;
    border-left: 1px solid #e3e3e3;
    &:after {
      position: relative;
      top: 4px;
      right: 0;
      width: 25px;
      height: 25px;
      content: '';
      display: inline-block;
      @include bgicon("elements/plus", 25px, 25px);
    }
  }
}

body.mediathek-is-active #mediathek-button .button-toggle:after {
  //content: ' ×';
  transform: rotate(45deg);
}

#mediathek {
  position: absolute;
  width: 100%;
  @include breakpoint-min($tablet) {
    width: $page-width;
  }
  height: 380px;
  top: 110px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 79;
}

#mediathek-inner {
  background: rgba(20, 20, 20, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //padding-top: 91px;

  body.adminimal-menu & {
    //top: 138px;
  }
}

#mediathek {

  li, ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
  }

  #mediathek-zoom {
    position: absolute;
    top: 0;
    left: 0;
    height: 93px;
    width: 100px;
    background-color: rgba(200, 200, 200, 0.2);
    @include bgicon("elements/zoom-out", 30px, 30px);
    background-position: center center;
    cursor: pointer;
    &:hover {
      background-color: rgba(200, 200, 200, 0.5);
    }
    z-index: 84;
  }
  &.zoomout #mediathek-zoom {
    @include bgicon("elements/zoom-in", 30px, 30x);
  }

  &.mode-podcast #mediathek-zoom {
    visibility: hidden;
  }

  #mediathek-header {
    width: 100%;
    height: 93px;
    position: relative;
    z-index: 83;
  }

  #mediathek-info {
    float: right;
    margin-right: 40px;
    color: rgba(255,255,255,0.9);
    text-shadow: 1px 1px rgba(255,255,255,0.2);
    max-width: 430px;
    margin-top: 14px;
    span {
      display: block;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
    }
    .info-main {
      @include font-size(20)
      font-style: italic;
      height: 28px;
      &.singlerow {
        margin-top: 14px;
      }
    }
  }

  .mediathek-is-active &.mode-podcast #mediathek-info {
    display: none;
  }
  #mediathek-controls {
    // position: absolute;
    // z-index: 83;
    // top: 30px;
    // width: 251px;
    // right: 30px;
    float: right;
    margin-top: 30px;
    margin-right: 30px;
    // width: 251px;
    width: auto;
  }

  .mediathek-control {
    display: block;
    float: left;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
    cursor: pointer;
  }

  .mediathek-control.playpause {
    @include bgicon("elements/play", 18px, 23px);
    background-position: center center;
    width: 18px;
    height: 23px;
  }

  .mediathek-control.next,
  .mediathek-control.prev {
    @include bgicon("elements/skip", 14px, 15px);
    width: 14px;
    height: 23px;
    background-position: center center;
    visibility: hidden;
  }

  &.mode-live .mediathek-control.prev,
  &.mode-audio .mediathek-control.next,
  &.mode-audio .mediathek-control.prev {
    visibility: visible;
  }

  .mediathek-control.next {
    @include transform(rotate(180deg));
  }

  .mediathek-control.live, .mediathek-control.audio {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin-right: 20px;
  }
  .mediathek-control.live {
    margin-right: 40px;
  }

  // Control states.
  &.state-playing .mediathek-control.playpause {
    @include bgicon("elements/pause", 16px, 23px);
  }
  &.state-playing.mode-live .mediathek-control.live,
  &.mode-audio .mediathek-control.audio{
    color: rgb(255, 150, 20);
  }

  #mediathek-slider-wrapper {
    //margin-top: 91px;
    //height: 240px;
    position: absolute;
    top: 91px;
    left: 0;
    right: 0;
    bottom: 50px;
    overflow: hidden;
  }

  #mediathek-slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    #mediathek-items, #mediathek-timeline {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 82;
    }
    #mediathek-items {
      z-index: 83;
      top: 80px;
    }
    #mediathek-timeline > li {
      height: 100%;
      float: left;
      position: relative;
      h4 {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 30px 10px 10px 10px;
        color: #eee;
        font-size: 16px;
        font-weight: bold;
      }
      .mediathek-hours {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 83;
        > li {
          float: left;
          border-left: 1px solid rgba(255, 255, 255, 0.7);
          border-image: linear-gradient(transparent 0%,
              rgba(white, 0.7) 20%,
              rgba(white, 0.7) 80%,
              transparent 100%) 1 100%;
          background-image: linear-gradient(to bottom, transparent 10%, rgba(white, 0.2) 40%, rgba(white, 0.2) 60%, transparent 90%),
          linear-gradient(to bottom, transparent 10%, rgba(white, 0.35) 40%, rgba(white, 0.35) 60%, transparent 90%),
          linear-gradient(to bottom, transparent 10%, rgba(white, 0.2) 40%, rgba(white, 0.2) 60%, transparent 90%);
          background-size: 1px 100%, 1px 100%, 1px 100%;
          background-position: 25% 0, 50% 0, 75% 0;
          background-repeat: no-repeat;
          color: rgba(255, 255, 255, 0.7);
          font-size: 16px;
          text-align: left;
          height: 100%;
          padding: 10px 0 0 10px;
        }
      }
    }
    #mediathek-items > li {
      float: left;
      a {
        position: relative;
        display: block;
        background: rgba(248, 248, 248, 0.9);
        //background: rgba(185, 185, 185, 0.9);
        //color: #fff;
        color: rgba(black, 0.95);
        padding: 10px;
        font-size: 20px;
        font-style: italic;
        height: 100px;
        margin: 0;
        transition: background .2s ease-in-out;
        line-height: 1.25em;

        //border-left-width: 1px;
        //border-left-style: solid;
        //border-color: rgba(black, 0.8);
        //border-image: linear-gradient(to bottom, transparent 0%, rgba(black, 0.8) 20%, rgba(black, 0.8) 80%, transparent 100%);

        border-left: 1px solid rgba(0, 0, 0, 0.7);
        border-image: linear-gradient(transparent 0%,
            rgba(black, 0.7) 20%,
            rgba(black, 0.7) 80%,
            transparent 100%) 1 100%;

        &:hover {
          text-decoration: none;
          background: rgba(253, 253, 240, 0.9);
          //background: rgba(140, 140, 140, 0.9);
          box-shadow: 0 0 8px 3px rgba(250, 250, 100, 0.8) inset;
        }
        .meta {
          display: block;
          position: absolute;
          bottom: 10px;
          left: 10px;
          font-size: 12px;
          font-style: normal;
        }
      }
      &.live a .meta {
        text-transform: uppercase;
        font-weight: bold;
      }
      &:nth-child(2n) {
        a {
          background: rgba(233, 233, 233, 0.9);
          &:hover {
            background: rgba(240, 240, 220, 0.9);
          }
        }
      }
      &.active a, &.active a:hover {
        text-decoration: underline;

        box-shadow: 0 0 10px 5px rgba(255, 155, 100, 0.4) inset;
        background: rgba(255, 250, 245, 0.95);
        //color: #000;
      }
      &:nth-child(2n) {
        &.active a, &.active a:hover {
          background: rgba(255, 245, 235, 0.95);
        }
      }

      &.future:not(.active) a {
        background: rgba(190, 190, 190, 0.8);
        cursor: default;
        &:hover {
          background: rgba(190, 190, 190, 0.8);
          box-shadow: none;
        }
      }
    }
  }

  #mediathek-cursor {
    position: absolute;
    width: 30px;
    margin-left: -15px;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 84;
    background: transparent;
    cursor: col-resize;
    transition: background .2s ease-in-out;
    &:hover {
      background: rgba(255, 200, 100, 0.2);
      .mediathek-cursor-inner {
        border-color: rgba(255, 150, 20, 1);
        box-shadow: 0 0 3px 1px rgba(255, 200, 70, 0.5);
      }
    }
    &.ui-draggable-dragging {
      background: rgba(255, 150, 100, 0.2);
      .mediathek-cursor-inner {
        //border-color: rgba(255,150,150,1);
      }
    }
    .mediathek-cursor-inner {
      position: absolute;
      left: 14px;
      top: 0;
      bottom: 0;
      width: 0;
      border-color: rgba(255, 150, 20, 0.9);
      border-style: solid;
      border-left-width: 1px;
      border-right-width: 1px;
      box-shadow: 0 0 3px 1px rgba(255, 200, 70, 0.3);
      z-index: 85;
    }
  }

  #mediathek-days {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 240px;
    height: 50px;
    background: rgba(200, 200, 200, 0.9);
    display: flex;
    > li {
      //width: 114px;
      flex: 1;
      width: 14.2%;
      max-width: 100px;
      &:last-child {
        max-width: 100%;
        flex-grow: 1;
      }
      float: left;
      display: block;
      color: #282828;
      padding: 5px;
      line-height: 40px;
      height: 50px;
      border-right: 1px solid rgba(0, 0, 0, 0.4);
      font-size: 15px;
      cursor: pointer;
      text-align: center;
      overflow: hidden;

      &:hover, &.active {
        background: rgba(255, 255, 255, 1);
      }
      &:last-child {
        border-right: none;
      }
    }
  }

  $hourWidth: 150px;
  $hourWidthSmall: 80px;
  #mediathek-slider {
    width: (24 * 7) * $hourWidth;
    //padding-left: $hourWidth;
    #mediathek-timeline > li{
      width: $hourWidth * 24;
    }
    .mediathek-hours > li {
      width: $hourWidth;
    }
    #mediathek-items > li {
      @for $i from 1 through 24 {
        &.span#{$i} {
          width: $i * ($hourWidth / 2);
        }
        &.margin#{$i} {
          margin-left: $i * ($hourWidth / 2);
        }
      }
    }
  }
  #mediathek-cursor {
    //left: $hourWidth * 1;
  }

  &.zoomout {
    #mediathek-slider {
      width: (24 * 7) * $hourWidthSmall ;
      //padding-left: $hourWidthSmall;
      #mediathek-timeline > li {
        width: $hourWidthSmall * 24;
      }
      .mediathek-hours > li {
        width: $hourWidthSmall;
      }
      #mediathek-items > li {
        @for $i from 1 through 24 {
          &.span#{$i} {
            width: $i * ($hourWidthSmall / 2);
          }
          &.margin#{$i} {
            margin-left: $i * ($hourWidthSmall / 2);
          }
        }
      }
    }
    #mediathek-cursor {
      //left: $hourWidthSmall * 1;
    }
  }

  &.zoomout {
    #mediathek-slider {
      #mediathek-items > li {
        a {
          font-size: 14px;
          padding: 10px 5px;
          .meta {
            left: 5px;
            font-size: 10px;
          }
        }
      }
    }
  }



  #mediathek-debug {
    display: none;
  }

  &.mode-debug #mediathek-debug {
    display: block;
    position: absolute;
    z-index: 83;
    top: 0;
    left: 100px;
    right: 400px;
    width: auto;
    height: 92px;
    padding: 5px;
    text-align: right;
    background: rgba(10, 10, 10, 0.5);
    color: rgba(white, 0.95);
    font-size: 13px;
    line-height: 18px;
    //line-height: 16px;
    .date,.file,.link {
      display: block;
    }
  }
}

// Mobile mediathek.
@include breakpoint($tablet) {
  body.mediathek-is-active {
    #header {
      display: none;
    }
    #page-wrapper {
      display: none;
    }
    background: #000;
    #persistent {
      height: 100%;
      width: 100%;
      position: absolute;
    }
    #mediathek-button {
      bottom: 0;
      right: 0;
      top: auto;
    }
    #mediathek {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: auto;
      #mediathek-days > li {
        line-height: 20px;
        strong {
          display: block;
        }
      }
      #mediathek-zoom {
        background-color: transparent;
      }
    }
  }
}

@include breakpoint(600px) {
  .mediathek-is-active #mediathek-button {
    span {
      display: block;
    }
    width: 150px;
    font-size: 20px;
    letter-spacing: -1px;
  }
  #mediathek {
    #mediathek-days {
      right: 150px;
    }
  }
}

@include breakpoint-max(600px) {
  #mediathek #mediathek-days {
    bottom: 50px;
    width: 100%;
  }
  #mediathek-button {
    width: 200px;
    .button-page {
      width: 145px;
    }
  }
  .mediathek-is-active #mediathek-button {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    .button-toggle {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    span {
      display: block;
    }
  }
  #mediathek #mediathek-slider {
    &, #mediathek-items, #mediathek-timeline, #mediathek-cursor {
      bottom: 50px;
    }
  }
}

@include breakpoint(500px) {
  // .mediathek-is-active #mediathek-button {
  //   span.button-page {
  //     // display: none;
  //   }
  //   width: 50px;
  // }
  // #mediathek {
  //   #mediathek-days {
  //     right: 50px;
  //   }
  // }
}
@include breakpoint(430px) {
  #mediathek {
    #mediathek-days {
      > li {
        font-size: 14px;
        padding: 5px 3px;
      }
    }
  }
}

@media only screen and (max-height: 380px) {
  #mediathek {
    #mediathek-zoom {
      height: 45px;
      width: 45px;
    }

    #mediathek-controls {
      top: 10px;
    }

    #mediathek-slider-wrapper {
      top: 46px;
      #mediathek-items {
        top: 80px;
      }
    }
    #mediathek-slider #mediathek-items li a {
      height: 80px;
    }
  }
}

@media only screen and (max-height: 290px) {
  #mediathek {
    #mediathek-slider-wrapper {
      //height: 160px;
      #mediathek-items {
        top: 65px;
      }
    }
    #mediathek-slider #mediathek-items li a {
      height: 70px;
    }
  }
}

@media only screen and (max-height: 290px) {
  #mediathek {
    #mediathek-slider-wrapper {
      #mediathek-items {
        top: 60px;
      }
    }
  }
}

@media only screen and (max-height: 240px) {
  #mediathek {
    #mediathek-slider-wrapper {
      //height: 120px;
      #mediathek-items {
        top: 60px;
      }
    }
    #mediathek-slider #mediathek-items li a {
      height: 60px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

/**
 * Podcast mode.
 */
#mediathek-podcast {
  display: none;
}
#mediathek.mode-podcast {
  #mediathek-podcast {
    display: block;
  }
  #mediathek-items {
    display: none;
  }
  #mediathek-timeline {
    display: none;
  }
  #mediathek-days {
    display: none;
  }


  #mediathek-slider {
    width: 80%;
    margin: 0 10%;
    border-top: none;
  }

  #mediathek-podcast {
    z-index: 82;
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 0;
    right: 0;
    background: rgba(248, 248, 248, 0.2);
    color: rgba(black, 0.95);
    padding: 10px;
    font-size: 20px;
    .sendung {
      font-size: 16px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .title {
      text-align: center;
      color: #fff;
      font-weight: bold;
    }

    .duration,
    .time {
      position: absolute;
      font-size: 16px;
      color: #fff;
      right: 20px;
      bottom: 20px;
    }
    .time {
      left: 20px;
    }

  }
}



/**
 * In-page styles.
 */
.airplan-listen {
  //color: #fff;
  //padding: 3px 5px 3px 25px;
  //margin: 5px 0 10px 0;
  //@include font-size(12);
  ////text-transform: uppercase;
  //font-weight: bold;
  //
  position: relative;
  cursor: pointer;
  &:after {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    content: '';
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-color: transparent;
    @include bgicon("elements/circle-play", 28px, 28px);
  }
  //
  //cursor: pointer;

  //&:hover {
  //  background-color: $color_minfo;
  //}


}

a.airplan-podcast {
  display: inline-block;
  width: 28px;
  height: 28px;
  @include bgicon("elements/circle-play", 28px, 28px);
  background-repeat: no-repeat;
  background-color: transparent;
  span {
    display: none;
  }

}
