
.page-mediathek {
  #content-inner {
    background: transparent;
    padding: 0;
  }

  #content-header {
    padding: 30px 30px 0 30px;
    background: #fff;
  }

  .mediathek-content,
  .mediathek-podcasts {
    background: #fff;
    position: relative;
  }

  .mediathek-content {
    padding: 20px 30px 30px 30px;
  }

  .mediathek-show-link {
    color: $color_minfo;
    @include breakpoint-min($mobile) {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }
    font-weight: bold;
    display: block;
    padding-right: 30px;
    @include bgicon("elements/arrow_sendeblog-orange", 20px, 14px);
    background-position: right center;
  }

  .mediathek-showtime {
    @include breakpoint-min($mobile) {
      position: absolute;
      top: -35px;
      right: 30px;
    }
  }

  .square-title {
    position: absolute;
    top: -50px;
    left: 0;
  }
  h1.title {
    margin: 50px 0 0 0;
    font-style: italic;
    border: none;
    //text-decoration: underline;
    text-decoration: none;
    text-transform: none;
    font-weight: normal;
    letter-spacing: .2px;
    color: $color-headline;
    @include breakpoint-min($mobile) {
      padding-right: 400px;
    }
  }

  .mediathek-content {
    .node {
      position: static;
    }
    .node-artikel {
      .podcast-main > h2 {
        display: none;
      }
      .field-name-body {
        color: $color_text_small;
      }
    }
  }
}

.mediathek-podcasts {
  @include clearfix();
  padding: 30px 0 27px 0;
  margin: 30px 0;
  @include breakpoint($mobile) {
    margin: 20px 0;
  }
  > h2 {
    border-bottom: 1px solid #d2d2d4;
    margin: 0 30px;
    @include breakpoint($mobile) {
      margin: 0 20px;
    }
  }
  ul.pager {
    padding-top: 30px;
    margin-bottom: 0;
  }

  .view-filters {
    @include breakpoint-max($mobile) {
      margin: 10px 20px;

    }
    @include breakpoint-min($mobile) {
      position: absolute;
      top: 22px;
      right: 82px;
      height: 35px;
    }
    .views-exposed-widget {
      padding: 0;
    }
    label, .views-widget, .views-submit-button {
      float: left;
      margin: 0;
    }
    label {
      font-weight: 600;
      text-transform: uppercase;
      margin: 4px 10px 0 0;
    }
  }
  //.views-exposed-widget {
  //	.form-submit {
  //		margin: 0;
  //		border: none;
  //		background: #eee;
  //		color: $color_minfo;
  //		font-weight: bold;
  //		padding: 2px 20px;
  //		&:hover {
  //			background: $color_minfo;
  //			color: #fff;
  //		}
  //	}
  //}
}
.mediathek-podcasts #podcast-view-toggle {
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  @include svgicon-sprite(list);
  @include breakpoint($mobile) {
    display: none;
  }
}
.mediathek-podcasts.podcast-list #podcast-view-toggle {
  @include svgicon-sprite(grid);
}

.mediathek-podcasts .views-row {
  border-bottom: 1px solid #d2d2d4;
  position: relative;
  padding: 20px;
  @include breakpoint-min($mobile) {
    float: left;
    width: 33%;
    border-right: 1px solid #d2d2d4;
    height: 300px;
    padding: 20px 30px;
  }
  > article {
    position: static;
  }
  &:nth-child(3n) {
    border-right: none;
  }
  .podcast-main {
    max-height: 173px;
    overflow: hidden;
  }
  .podcast-footer {
    @include breakpoint-min($mobile) {
      position: absolute;
      bottom: 20px;
      left: 30px;
      right: 30px;
    }
    @include clearfix();
    a.airplan-podcast {
      float: right;
    }
  }
}
.mediathek-podcasts.podcast-list .views-row {
  width: 100%;
  border-right: none;
  float: none;
  height: auto;
  padding: 18px 30px;
  @include clearfix();
  .node-vm-podcast {
    display: flex;
    flex-wrap: nowrap;
  }
  &:nth-child(2n) {
    background-color: #f4f4f3;
  }
  .podcast-header {
    //float: left;
    //width: 250px;
    flex: 0 0 230px;
    flex-grow: 0;
  }
  .podcast-main {
    //float: left;
    flex-grow: 1;
    flex: 1;
    padding: 0 20px;
    h2 {
      margin-top: 0 !important;
    }
  }
  .podcast-footer {
    position: static;
    //flex-grow: 0;
    flex: 0 0 50px;
  }

  .field-name-body {
    display: none;
  }
}

.mediathek-podcasts {
  .view-empty {
    padding: 30px;
  }

  .node-vm-podcast {
    .field-name-field-sendung {
      text-transform: uppercase;
      @include font-size(16);
      //@include font-size($fontsize_small);
      a {
        color: $color-text;
      }
    }
    .submitted {
      @include font-size($fontsize_small);
    }
    h2 {
      font-weight: normal;
      border: none;
      margin: 10px 0;
      @include font-size(21);
      color: $color_text;
      text-transform: none;
      font-style: italic;
      a {
        color: $color-text;
      }
    }
    .field-name-body {
      @include font-size($fontsize_small);
      color: #8c8c8c;
    }

    .field-name-field-audio {
      margin: 0;
      padding: 0;
      background: transparent;
    }

  }
}
//a.airplan-podcast {
//	font-size: 14px;
//	font-weight: bold;
//	display: block;
//	padding: 0 3em;
//	margin: .5em 0 0 0;
//	text-align: center;
//	color: $color_minfo;
//	background: #eee;
//	&:hover {
//		color: #fff;
//		background: $color_minfo;
//		text-decoration: none;
//	}
//}
