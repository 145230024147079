////////////////////////////////////////////////////////////////////////////////
// "base" partial
// --------------------
//
////////////////////////////////////////////////////////////////////////////////

//
// Links
//
a {
  color: $color_link;
  text-decoration: none;
  &:link {
  }
  &:visited {
    color: $color_link_visited;
  }
  &:hover {
    color: $color_link_hover;
    text-decoration: underline;
  }
  &:focus {
    color: $color_link_focus;
    outline: thin dotted;
  }
  &:active {
    color: $color_link_active;
    text-decoration: underline;
    outline: 0; // Improve readability when focused and hovered in all browsers: h5bp.com/h
  }
}

//
// Typography
//
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3em;
  color: $color_headline;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  @include breakpoint($mobile) {
    line-height: 1.5;
    @include font-size(16);
    font-weight: 600;
    word-wrap: break-word;
    -webkit-text-size-adjust: none;
  }
}

h1 {
}

h1, h2 {
  @include font-size(25);
  font-weight: 600;
  color: $color_headline;
  text-transform: uppercase;
  border-bottom: 1px solid $color_headline_border;
  padding: 0 0 10px 0;
  margin: 0 0 40px 0;
}

h1, h2 {
  border-bottom-color: $color_hl1;
}

h2 {
  margin-top: 40px;
}

h2.page-title {
  margin-top: 0;
}

h3 {
  @include font-size(20);
  margin-bottom: 1em;
}

h4 {
  @include font-size(18);
  margin-bottom: 0.8em;
}

h5 {
  @include font-size(16);
  font-weight: 600;
  margin-bottom: 0;
}

h6 {
  @include font-size(13);
  font-weight: bold;
}

p {
  margin: 0 0 1em 0;
}

abbr {
  border-bottom: 1px dotted #666;
  cursor: help;
  white-space: nowrap;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

pre, code, kbd, samp, tt {
  font-family: $monospace_font; // Redeclare monospace font family: h5bp.com/j
  @include font-size(16);
  line-height: 1.5em;
}

pre { // Improve readability of pre-formatted text in all browsers
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #efefef;
  display: block;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #aaa;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub, sup { // Position subscript and superscript content without affecting line-height: h5bp.com/k
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

//
// Lists
//
ul, ol {
  margin: 1em 0;
  padding: 0 0 0 2em;
}

dd {
  margin: 0 0 0 2em;
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: disc;
  ul {
    list-style-type: circle;
    ul {
      list-style-type: square;
      ul {
        list-style-type: circle;
      }
    }
  }
}

ol {
  list-style-type: decimal;
  ol {
    list-style-type: lower-alpha;
    ol {
      list-style-type: decimal;
    }
  }
}

p img {
  @include breakpoint-min($tablet) {
    //float: left;
    //margin: 0 10px 5px 0;
    //padding: 0;
  }
}

img {
  @include breakpoint($tablet) {
    border: 0;
    max-width: 100%;
  }
}
table {
  @include breakpoint($tablet) {
    width: auto;
    border-collapse: collapse;
    border-spacing: 0;
  }
}

