////////////////////////////////////////////////////////////////////////////////
// Layout Styling
// --------------------
// This partial defines CSS classes to create a table-free layout, with 
// 1-3 columns, depending on whether blocks are enabled in the left or right
// columns.
//
// We use a negative margin technique, adapted from the Zen Columns layout 
// method (http://drupal.org/node/201428). The page is loaded in this order:
//   1. Header
//   2. Content
//   3. Navigation menus
//   4. Sidebar Left
//   5. Sideabr Right
//
// Only CSS that affects the layout (positioning) of major elements should be
// listed here. Most visual styling should go in _custom.scss.
////////////////////////////////////////////////////////////////////////////////

#page,
#persistent { // Remove 'auto' and the width to switch to a fluid width.
  width: $page_width;
  margin: 0 auto;
  // @media screen and (max-width : $tablet) {
  @include breakpoint($tablet) {
    width: 100%;
  }
  @include breakpoint($mobile) {
    width: 100%;
    margin: 0;
  }
  max-width: 100%;
  overflow: hidden;
}

body, #page {
  height:100%; // Page height fills the screen and footer at the bottom.
}
body {
  @include breakpoint($tablet) {
    background: #fff;
    word-wrap: break-word;
    -webkit-text-size-adjust: none;
  }
}
html, body {
  @include breakpoint($tablet) {
    margin: 0;
    padding: 0;
    border: 0;
  }
  @include breakpoint($mobile) {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

#main {
  position: relative;
  @include breakpoint($tablet) {
    padding-bottom: 0px;
  }
  @include breakpoint($mobile) {
    padding-bottom: 0px;
    position: static;
  }
}

#content {
  float: left;
  width: 100%;
  margin-right: -100%;
}

.sidebar {
  float: left;
}

#sidebar-second {
  float: right;
  @include breakpoint($tablet) {
    float: none;
    clear: both;
    width: auto;
    margin: 0px;
  }
}

#footer {
  width: 100%;
  .region-footer {
    width: $page-width;
    margin: 0 auto;
    @include breakpoint($tablet) {
      width: 100%;
      margin: 0;
    }
    @include breakpoint($mobile) {
      width: 100%;
      margin: 0;
    }
  }
}

.sidebar, #sidebar-first, #sidebar-second {
  @include breakpoint($mobile) {
    float: none;
    clear: both;
    width: auto;
    margin: 0px;
  }
}
#content #content-inner.center {
  @include breakpoint($mobile) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

//
// Layout Helpers
//
#header,
#footer,
.mission,
.breadcrumb,
.node {
  clear: both;
}

//
// Column widths
//
.two-sidebars,
.sidebar-first {
  .center {
    margin-left: percentage($first_sidebar_width / $page_width);
    @include breakpoint($sidebar_width_break) {
      margin-left: percentage($sidebar_width_below / $page_width);
    }
  }
}

#sidebar-first {
  width: percentage($first_sidebar_width / $page_width);
  margin-right: -$first_sidebar_width;
  @include breakpoint($sidebar_width_break) {
    width: percentage($sidebar_width_below / $page_width);
    margin-right: -$sidebar_width_below;
  }
  @include breakpoint($mobile) {
    margin-right:0;
    width:100%;
  }
}

.two-sidebars,
.sidebar-second {
  .center {
    margin-right: $second_sidebar_width;
  }
}

#sidebar-second {
  width: $second_sidebar_width;
  @include breakpoint($mobile) {
    margin-right:0;
    width:100%;
  }
}

