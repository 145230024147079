/*****
 * Frontpage: General.
 ****/

// Page selector.
.page-start {

  #content-inner {
    padding: 0;
    background-color: transparent;
  }

  h1.title {
    display: none;
  }
}

  // Panel region & generic pane styling.

.freefm-front {
  .panel-pane {
    background-color: #fff;
    h2.pane-title {
      text-transform: uppercase;
      text-align: center;
      border: none;
      margin: 0;
      padding: 30px 0;
      font-weight: bold;
    }
    .pane-content {
      padding: 0 30px 30px 30px;
    }
  }
  .freefm-front-midside {
    h2.pane-title {
      padding: 25px 0;
    }
  }
  .freefm-front-contenttop,
  .freefm-front-midmain {
    .panel-pane:first-child {
      @extend %pane-square-title;
    }
  }

  .freefm-front-contentbottom {
    .panel-pane {
      background-color: #61686e;
      h2.pane-title {
        color: #fff;
        text-align: center;
      }
    }
  }
}


/*****
 * Frontpage: News Block & Scrollable Vertical Tabs.
 ****/

// TODO: Adjust base selector.
.freefm-front .panel-pane.pane-views-frontpage-news-block {
  background: transparent;
  @include breakpoint-min($mobile) {
    position: relative;
  }
  .pane-content {
    margin-top: -100px !important;
    padding: 0 0 0 30px;
    background: #fff;
    @include breakpoint($mobile) {
      padding-left: 20px;
    }
  }
  &:after {
    width: 100%;
    height: 300px;
    content: '';
    display: block;
    background: url("../images/other/icon_explanation.jpg");
    margin-top: 50px;
  }
  @include breakpoint($mobile) {
    h2.pane-title {
      top: 0;
    }
  }
}

/*****
 * Frontpage: Other panes.
 ****/

// Blog

.pane-views-frontpage-blog-block {
  .pane-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    .view {
      .views-view-grid.cols-2 {
        width: auto;
        td {
          width: 50%;
          min-width: 50%;
          border: 1px solid #ebe9e6;
        }
        tr:last-child td {
          border-bottom: 0;
        }
        @include breakpoint($mobile) {
          &, tbody, td, tr {
            display: block;
            width: 100%;
          }
          td {
            border-top: none;
          }
          tr.row-first td.col-first {
            // border-top: none;
          }
        }
      }
      @include clearfix();
      .blog-item {
        padding: 30px;
        @include breakpoint($mobile) {
          padding: 20px;
        }
        h2 {
          @include font-size(21);
          font-weight: 600;
          font-style: italic;
          text-decoration: none;
          text-transform: none;
          margin: 25px 0;
          padding: 0;
          border: none;
          @include breakpoint($mobile) {
            @include font-size(18);
          }
          &, & a {
            color: #3c3c3c;
          }
          a:hover {
            color: #dd4931;
            text-decoration: underline;
          }
        }
        .blog-header {
          padding-left: 45px;
          background-position: 0 4px;
          min-height: 44px;
        }
        .blog-slogan {
          font-size: 16px;
          a {
            color: $color_text;
          }
          text-transform: uppercase;
          font-weight: 600;
        }
        .submitted {
          color: $color_text_meta;
          @include font-size(14);
          font-weight: 300;
        }
        p {
          @include font-size(16);
          color: $color_text_small;
        }
        &.sendung {
          .blog-header {
            @include bgicon("icons/04_programm_color", 45px, 33px);
            padding-left: 60px;
          }
          //&:hover .blog-header {
          //	@include bgicon("icons/04_programm_color", 33px, 46px);
          //}
          h2 a:hover {
            color: $color_mprogramm;
          }
        }
        &.rotation {
          .blog-header {
            @include bgicon("icons/03_musik_color", 36px, 39px);
          }
          //&:hover .blog-header {
          //	@include bgicon("icons/03_musik_color", 33px, 46px);
          //}
          h2 a:hover {
            color: $color_mmusik;
          }
        }
        &.machmit {
          .blog-header {
            @include bgicon("icons/02_mitmachen_color", 33px, 46px);
          }
          //&:hover .blog-header {
          //	@include bgicon("icons/02_mitmachen_color", 33px, 46px);
          //}
          h2 a:hover {
            color: $color_mmitmachen;
          }
        }
        &.info {
          .blog-header {
            @include bgicon("icons/01_info_color", 31px, 41px);
          }
          //&:hover .blog-header {
          //	@include bgicon("icons/01_info_color", 33px, 46px);
          //}
          h2 a:hover {
            color: $color_minfo;
          }
        }
      }
    }
  }
}


// Platte der Woche

.freefm-front .freefm-front-midside .pane-views-platte-der-woche-block-single {
  background-color: #61686e;
  &, a, h2.pane-title {
    color: #fff;
  }
  text-align: center;
  @include clearfix();
  .pane-content {
    padding: 0 40px 30px 40px;
  }
}

.view-platte-der-woche.view-display-id-block_single {
  article {
    text-align: center;
    h2 {
      margin: 0;
      border: none;
      background-color: #61686e;
      line-height: 1em;
      &, a {
        color: #fff;
      }
      font-style: italic;
      text-transform: none;
      @include font-size(21);
    }
    .field-name-field-coverbild {
      padding: 10px;
    }
    .field-name-field-artist,
    .node-intro-kw {
      @include font-size(16);
    }
  }

  .flexslider {
    background: transparent;
    border: none;
    margin-bottom: 0;
    .flex-control-nav {
      display: none;
    }
  }
  .flexslider,
  .flexslider:hover {
    .flex-direction-nav a {
      opacity: 1;
      @include transition(none);
      display: block !important;
      width: 15px;
      height: 28px;
      &:before {
        content: '' !important;
        @include bgicon("elements/arrow-widget-white", 15px, 28px);
        .view-display-id-block_triple & {
          @include bgicon("elements/arrow-widget-grey", 15px, 28px);
        }
        width: 15px;
        height: 28px;
      }
      &.flex-prev {
        left: -22px;
      }
      &.flex-next {
        right: -22px;
        &:before {
          @include transform(rotate(-180deg));
        }
      }
    }
  }

}

// Sendungen

.freefm-front .pane-views-frontpage-sendungen-block {
  background-color: $color_tmedium !important;
  &, h2, a {
    color: $color_text;
  }

  .filterinput {
    width: 100%;
    margin: 0 0 1em 0;
    height: 30px;
    padding: 2px 5px 2px 30px;
    @include bgicon("elements/lupe", 19px, 19px);
    background-position: 6px 5px;
    border: none;
    color: $color_text_small;
  }

  .view-content {
    height: 230px;
    overflow: hidden;
    -ms-overflow-y: scroll; /* IE8 */
    overflow-y: scroll;
    @include scrollbars(.5em, #fff, #8c8c8c);
    li.views-row {
      list-style-type: none;
      margin: 0 0 2px 0;
    }
    a {
      font-style: italic;
      @include font-size(21);
    }
  }

}


// Dein freies Radio...
.freefm-front .pane-block-7 {
  @include bgimg("teaser/04.8_teaser-small02", "1000px", "330px", "jpg");
  height: 330px;
  padding: 90px 40px;
  @include font-size(40);
  color: #fff;
  p {
    margin: 0;
  }
  @include breakpoint(500px) {
    @include font-size(30);
    padding: 70px 30px;
    height: 250px;
    background-position: center center;
  }
  @include breakpoint(400px) {
    @include font-size(25);
    padding: 50px 20px;
    height: 200px;
    background-position: center center;
  }
}

.freefm-front .freefm-front-bottomleft {
  // Special case for maps block (is not responsive).
  min-width: 500px;

  margin-top: -50px;
  .pane-map .pane-content {
    padding: 0;
  }
}

.freefm-front .freefm-front-bottomright {
  .panel-pane {
    background-color: #61686e;
    @include font-size(16);
    .pane-content {
      @include clearfix();
      @include breakpoint($mobile) {
        .columns-wrapper .col {
          float: none;
          width: 100%;
          padding: 0;
          @include clearfix();
        }
      }
    }
    h3, h4 {
      margin: 5px 0 0 0;
    }
    td:first-child {
      width: 80px;
    }
    &, a, h2, h3, h4 {
      color: #fff;
    }
    p {
      margin: 0;
    }
  }
}

// Termine

.pane-views-frontpage-termine-block {
  .pane-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  margin-bottom: 50px;
}

.freefm-views-slider {
  position: relative;
  .scroll-left,
  .scroll-right {
    position: absolute;
    top: 93px;
    width: 15px;
    height: 28px;
    @include bgicon("elements/arrow-widget-grey", 15px, 28px);
    cursor: pointer;
    span {
      display: none;
    }
    &.disabled {
      opacity: .3;
    }
  }
  .scroll-left {
    left: -30px;
    //@include transform(rotate(0));
  }
  .scroll-right {
    right: -39px;
    @include transform(rotate(180deg));
  }
  .expand {
    position: absolute;
    right: -30px;
    top: -68px;
    cursor: pointer;
    @include svgicon-sprite(list);
    span {
      display: none;
    }
    &.expanded {
      @include svgicon-sprite(grid);
    }
  }

  .view-content {
    @include clearfix();
    overflow: hidden;
    height: 200px;
    @include breakpoint($mobile) {
      height: 240px;
    }
    position: relative;
    width: 100%;
    .item-list {
      @include clearfix();
    }
  }
  li.views-row {
    list-style-type: none;
    display: block;
    float: left;
    background: #fff;
    padding: 10px;
    width: 200px;
    height: 200px;
    @include breakpoint($mobile) {
      height: 240px;
    }
    text-align: center;
    @include font-size(14);
    position: relative;
    margin: 0 20px 0 0;

  }

  &.expanded {
    height: auto;
    > .view-content {
      height: auto;
      > .item-list {
        height: auto;
      }
    }
    li.views-row {
      width: auto;
      float: none;
      height: auto;
      border-bottom: 1px solid #61686e;
      @include font-size($fontsize_text);
      &.views-row-even {
        background-color: #f1f1f1;
      }
      padding: 15px 35px 15px 190px;
    }
    @include breakpoint($mobile) {
      li.views-row {
        padding: 15px;
      }
    }
  }
}

.view-frontpage-termine {
  margin: 0 60px;
  height: 220px;
  .scroll-left,
  .scroll-right {
    @include bgicon("elements/arrow-widget-white", 15px, 28px);
  }
  .expand {
    @include svgicon-sprite(list-white);
    &.expanded {
      @include svgicon-sprite(grid-white);
    }
  }

  > .view-filters {
    @include breakpoint-min($tablet) {
      position: absolute;
      left: 0;
      top: -74px;
    }

    .views-widget-filter-field_termin_typ_tid {
      label {
        display: none;
      }
      select {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
        width: 240px;
        padding: 3px;
        @include font-size(18);
        font-style: italic;
        option {
          color: #61686e;
          background: #fff;
          padding: 5px 8px;
        }
      }
    }
  }

  > .view-content > .item-list {
    height: 200px;
  }
  
  // see also: .freefm-views-slider li.views-row
  > .view-content li.views-row {
    padding-top: 30px;
  }

  .views-field {
    text-align: center;
  }
  .views-field-field-datum {
    @include font-size(21);
    font-weight: bold;
  }
  .views-field-field-datum-1,
  .views-field-field-ort {
    color: $color_text_meta;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .views-field-field-datum-1 {
    bottom: 30px;
  }
  .views-field-title {
    @include font-size(21);
    font-style: italic;
    a {
      color: $color_text;
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }
  }
  .views-field-name span {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: $color_label_orange;
    color: #fff;
    @include font-size(14);
    text-transform: uppercase;
    font-weight: 600;
    padding: 3px 8px;
    &.termin-tid-21,
    &.termin-tid-22 {
      background: $color_label_red;
    }
    &.termin-tid-20 {
      background: $color_label_green;
    }

  }
  .views-field-body {
    display: none;
  }
  &.expanded {
    > .view-content li.views-row {
      @include clearfix();
      padding-top: 22px;
      padding-bottom: 22px;
    }
    .views-field {
      text-align: left;
    }
    .views-field-field-datum {
      position: absolute;
      left: 35px;
      top: 22px;
      @include breakpoint($mobile) {
        position: static;
      }
    }
    .views-field-field-ort {
      position: static;
    }
    .views-field-field-datum-1, .views-field-field-ort {
      float: left;
      color: $color_text_meta;
      @include font-size(14);
    }
    .views-field-field-datum-1 {
      margin-right: .5em;
      position: static;
      .field-content:after {
        content: ' | ';
        display: inline-block;
        margin-left: .5em;
      }
    }
    .views-field-body {
      // display: block;
      clear: left;
      width: 100%;
      color: $color_text_small;
      margin-top: 40px;
      margin-left: -155px;
      @include font-size(16);
      p {
        margin: 0;
      }
    }
  }
}

// Platte der Woche

.view-platte-der-woche.view-display-id-block_triple {
  height: 280px;
  margin: 20px 30px;
  .view-content {
    height: 280px;
  }
  li.views-row {
    height: 280px;
  }
  article {
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    bottom: 0;
  }
  .node-intro-kw {
    @include font-size(16);
    text-align: left;
  }
  h2 {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include font-size(21);
    font-weight: normal;
    font-style: italic;
    text-align: left;
    color: $color-text;
    border: none;
    text-transform: none;
    max-width: 100%;
  }
  .field-name-field-artist {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    @include font-size(21);
  }
  .field-name-field-coverbild {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    //width: 180px;
    max-width: 180px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &.expanded {
    height: auto;
    @include breakpoint($mobile) {
      margin: 10px -20px;
    }
    > .view-content {
      height: auto;
    }
    .views-row {
      @include clearfix();
      height: 100px;
      padding: 0;
      @include breakpoint($mobile) {
        width: 100% !important;
        article {
          right: 0;
        }
      }
    }
    .node-intro-kw {
      margin: 10px;
    }
    h2,
    .field-name-field-artist {
      position: static;
    }
    h2,
    .field-name-field-artist {
      float: left;
      margin: 10px 10px;
      @include font-size(21);
    }
    h2 {
      margin-right: 0;
    }

    .field-name-field-coverbild {
      width: 80px;
      height: 80px;
      right: 10px;
      top: 10px;
      left: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

