/*****
 * Content: Editor classes.
 ****/

p.highlighted {
  color: $color_text_meta;
  @include font-size(24);
  font-style: italic;
}

//p.twocolumn {
//  @include column-count(2);
//  @include column-gap(30px);
//  @include breakpoint($mobile) {
//    @include column-count(1);
//  }
//}

.columns-wrapper {
  @include clearfix();
  margin-bottom: 1em;
  .col {
    margin-bottom: 1em;
  }
  @include breakpoint-min($mobile) {
    > .col {
      width: 50%;
      float: left;
      margin-bottom: 0;
      padding-left: 1em;
    }
    .col:first-child {
      padding-left: 0;
    }
    .col:last-child {
      //float: right;
    }
    &.threecol > .col {
      width: 33.3%;
    }
    &.twocol-wide {
      > .col:first-child {
        width: 33.3%;
      }
      > .col:last-child {
        width: 66.6%;
      }
    }
    &.twocol-wide2 {
      > .col:first-child {
        width: 66.6%;
      }
      > .col:last-child {
        width: 33.3%;
      }
    }

  }
}

.columns-wrapper.twocol-auto {
  display: flex;
  .col {
    flex: 1 1 auto;
    float: none;
    width: auto;
  }
  .col:first-child {
    flex: 0 0 auto;
  }
}

p.highlight,
p.citation-block {
  font-size: 1.3em;
  line-height: 1.4em;
  font-style: italic;
  color: $color_text_meta;
  margin: .5em 0 1em 0;
}

p.caption {
  color: $color_text_meta;
  font-weight: 600;
  img {
    margin-bottom: 5px;
  }
}


span.inline-right {
  margin: 10px 0 10px 10px;
  display: block;
  float: right;
}

span.inline-left {
  margin: 10px 10px 10px 0;
  display: block;
  float: left;
}

span.smallbold {
  font-size: 0.9em;
  font-weight: bold;
}


h3 {
  font-weight: normal;
  @include font-size(22);
  color: $color-text;
}

h3 strong {
  font-size: 0.83em;
}

h3 a, a h3 {
  font-style: italic;
}
