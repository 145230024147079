// Team page
//.page-node-40295 {
//  .content {
//    h3 {
//      font-weight: normal;
//      font-style: italic;
//    }
//  }
//}

.inline-table {
	&, tr, th, tr.odd, tr.even, tbody, thead {
		border: none;
		background: transparent;
		padding: 0;
		margin: 0;
	}
	td, tr.odd td, tr.even td {
		border: none;
		background: transparent;
		padding: 0 3rem 0 0;
		&:first-child {
			 white-space: nowrap;
			 width: 0%;
		}
		&:last-child {
			padding-right: 0;
		  width: 100%;
		}
	}
}

// Intranet menu
#sidebar-first #block-menu_block-3 {
	ul.menu {
		li {
			padding: 0;
			> a {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
		li.active-trail {
			background: transparent;
		}
		li.active > a {
			background: #fff;
		}
		ul.menu {
			padding-left: 20px;
			li {
				@include font-size(16);
				a {
					padding-top: 5px;
					padding-bottom: 3px;
				}
			}
		}
	}

}


.freefm-team {
	h3 {
		margin-top: 1em;
		border-top: 1px solid #ccc;
		padding-top: 1em;
	}
	// not for first.
	> div:first-child > h3:first-child {
		border-top: none;
		margin-top: 0;
		padding-top: 0;
	}
	h4 {
		@include font-size(18);
		font-weight: strong;
		color: $color_text;
		margin-top: 1em;
	}
	h5 {
		@include font-size(18);
		font-weight: strong;
		color: $color_text_meta;
	}
	.nodes {
		@include clearfix();
		article {
			@include breakpoint-min($mobile) {
				width: 33%;
				float: left;
				clear: none;
			}
		}
	}

}
