input::-ms-clear {
  display: none;
}

#page {
    padding-top: 110px;
    @include breakpoint($mobile) {
        padding-top: 60px;
    }
    position: relative;
}
#page-intro {
    width: 100%;
    height: 10px;
    background-color: #111c1f;
    z-index: 90;
    position: absolute;
    left: 0;
    top: 0;
    @include breakpoint($mobile) {
        display: none !important;
    }
}
body.admin-menu.adminimal-menu:before {
    height: 19px;
}
#header {
    @include clearfix();
    height: 110px;
    width: $page_width;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 80;
    .mobile-nav & {
        z-index: 200;
    }
      border-top: 10px solid transparent;
    overflow: visible;

    @include breakpoint($page_width) {
        width: 100%;
    }
    @include breakpoint($mobile) {
        width: 100%;
        padding-top: 0;
    }
    #logo {
        width: 100px;
        height: 100px;
        float: left;
        z-index: 90;
        background-color: #fff;
        padding: 6px 0 0 14px;
        @include breakpoint($mobile) {
            display: none;
        }
    }

    #header-top {
        @include clearfix();
        overflow: visible;
        height: 50px;
      background: $color_bg;
        @include breakpoint($mobile) {
            height: 70px;
        }
    }

    #site-name {
        float: left;
        height: 50px;
        padding: 0;
        margin: 0;
        @include font-size(28);
        a {
            margin-left: 25px;
            margin-top: 14px;
            display: block;
            width: 224px;
            height: 22px;
            @include bgimg("elements/freeFM-wordmark", 224px, 22px);
            span {
                display: none;
            }
            @include breakpoint($mobile) {
                margin-top: 21px;
            }
            @include breakpoint(400px) {
                width: 203px;
                height: 20px;
                background-size: 203px 20px;
                margin-left: 10px;
                margin-top: 26px;
            }
            @include breakpoint(360px) {
                width: 163px;
                height: 16px;
                background-size: 163px 16px;
                margin-left: 5px;
            }
        }
    }

    #loginlink {
        display: block;
        color: #999a9b;
        @include font-size(18);
        text-transform: uppercase;
        font-weight: 600;
        display: none;
        @include breakpoint-min($mobile) {
            display: block;
            position: absolute;
            right: 260px;
            top: 12px;
        }

    }

    #header-region {
        float: right;
        height: 50px;
        position: relative; // For absolute positioned search block label.
    }

    #search-block-form {
        .form-type-textfield {
            @include font-size(18);
            label {
                @include element-invisible-off();
                position: absolute !important;
                left: 15px;
                top: 13px;
                text-transform: uppercase;
                font-weight: 600;
                color: #959899;
            }
            .form-text {
                padding: 0;
                margin: 0;
                height: 50px;
                border: none;
                border-radius: 0;
                background-color: #fff;
                width: 240px;
                font-weight: 500;
                @include bgicon("elements/lupe", 19px, 19px);
                background-position: 210px center;
                padding: 15px;
                color: $color_text;
            }
        }
        #edit-submit {
            display: none;
        }
        .form-actions input[type="submit"] {
            display: none;
        }
    }

    #block-search-form.mobile {
        position: absolute;
        width: 100%;
        top: 70px;
        height: 50px;
        z-index: 100;
        .form-text {
            width: 70%;
            float: left;
        }
        #edit-submit {
            display: block;
            float: right;
            width: 30%;
            background-color: $color_tbright;
            border: none;
            padding: 15px;
            height: 50px;
            text-transform: uppercase;
        }
    }

    .mobile-button:after {
        display: block;
        float: left;
        width: 50px;
        height: 50px;
        padding: 10px;
        text-align: center;
        cursor: pointer;
        @include font-size(26);
        font-weight: bold;
        line-height: 44px;
    }

    #header-search-button:after {
        position: absolute;
        right: 0;
        top: 0;
        // float: right;
        background-color: rgba(0,0,0,0.2);
        content: ' ';
        // @include bgicon("icons/icon-suche", 20px, 20px);
        background-image: url("../images/icons/icn-search.svg");
        background-size: 32px 32px;
        background-repeat: no-repeat;
        background-position: 18px 16px;
        @include breakpoint($mobile) {
            background-color: transparent;
        }
    }

    #header-search-button.pushed:after {
        background-image: none;
        content: '✕';
    }

    #header-menu-button:after {
        background: $color_bg;
        // @include bgicon("icon")
        content: '☰';
    }

    #header-menu-button.pushed:after {
        content: '✕';
    }

  #navigation {
      margin-left: 0;
      width: 100%;
      @extend .clearfix;

        height: 50px;
        padding-left: 100px;
        // background-color: $color_bg_nav;
        //background-color: rgba(53, 60, 62, 0.9);
    background-color: rgba(37,38,41,0.8);

        @include breakpoint-max($tablet) {
        clear: both;
            display: none;
            &.mobile {
                display: block;
                margin: 0;
                padding: 0;
                position: absolute;
                z-index: 2000;
                top: 70px;
                height: auto;
                width: 100%;
                background-color: rgba(53, 60, 62, 0.95);
                ul {
                    display: block;
                    li {
                        a {
                            float: none;
                            text-align: center;
                            margin: 0;
                            padding: 20px;
                            width: 100%;
                            @include font-size(26);
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        ul {
            float: none;
            margin: 0;
            padding: 0 0 0 18px;
      display: flex;
      flex-wrap: nowrap;
            justify-content: space-between;
            li {
                list-style: none;
                &:nth-last-child(3) {
                    flex-grow: 2;
                }
                a {
                    display: block;
                    //float: left;
                    //flex: 1;
                    //flex-grow: 0;
                    padding: 11px 12px;
                    margin-right: 10px;
                    @include breakpoint(920px) {
                        padding: 11px 6px 11px 12px;
                        margin-right: 10px;
                    }
                    @include breakpoint(750px) {
                        letter-spacing: -0.005em;
                        margin-right: 5px;
                    }
                    @include breakpoint(700px) {
                        @include font-size(16);
                    }
                    @include breakpoint(650px) {
                        letter-spacing: -0.01em;
                        margin-right: 0;
                        padding: 11px 3px 11px 12px;
                    }
                    @include font-size(18);
                    color: $color_text_nav;
                    text-transform: uppercase;
                    font-weight: 600;
                    &.active, &:hover {
                        color: $color_text_nav_active;
                        text-decoration: none;
                    }
          &[href="/#kontakt"].active,
                    &[href="/#termine"].active {
                        color: $color_text_nav;
            &:hover {
                            color: $color_text_nav_active;
                        }
                    }
                }
            }
        }
    }
}

#main {
    @include breakpoint($mobile) {
        // No logo on mobile, so we need extra margin.
        margin-top: 50px;
    }
}

/*****
 * Base styles: Sidebar.
 ****/

// Mixin for fat titles.
%sidebar-block-title {
  @include font-size(25);
    background: $color_headline;
    text-transform: uppercase;
    text-align: center;
    height: 50px;
    padding: 6px;
    margin: 0;
    font-weight: 600;
    border: none;
    &, & a {
        color: #fff;
    }
}

// Mixins for alt titles.
%sidebar-block-title-alt {
    background: $color_bg_grey;
    color: #fff;
    height: 42px;
    text-align: left;
    padding-left: 30px;
    margin-bottom: 0;
    @include font-size(18);
    line-height: 30px;
}

// Regular (big) block
%sidebar-block {
    margin-bottom: 25px;
    h2.block-title {
        @extend %sidebar-block-title;
        margin-bottom: 25px;
    }

    ul.menu,
    div.item-list ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            margin: 0;
            padding: 0 30px;
            @include font-size(18);
            a {
                display: block;
                // height: 40px;
                padding-top: 7px;
                padding-bottom: 5px;
                border-top: 1px solid #bababa;
                font-weight: 600;
            }
        }
        li.active,
        li.active-trail {
            background-color: #fff;
            a {
                //color: #8c8c8c;
                //color: #282828;
                //color: $c08;
                border-top: none;
            }
            + li a {
                border-top: none;
            }
        }
        // Views don't have an active trail marker on the fields, therefore custom
        // style active links in sidebar view.
        .views-field-title a.active {
            background-color: #fff;
            color: #8c8c8c;
            margin: 0 -30px;
            padding-left: 30px;
            padding-right: 30px;
            border-top: none;
        }
        li.active-trail + li {
            a {
                border-top: none;
            }
        }
    }
}

// Alt (smaller) block
%sidebar-block-alt {
    margin-bottom: 0;
    h2.block-title {
        @extend %sidebar-block-title-alt;
    }
    ul.menu,
    div.item-list ul {
        li:first-child {
            a {
                border-top: none;
            }
        }
    }
}

#sidebar-first {
    background: #e9e9e9;
    a {
        color: #282828;
    }
    .block:first-child {
        h2.block-title {
            margin-bottom: 50px;
        }
    }
    .block {
        @extend %sidebar-block;
    }
}

/*****
 * Base styles: Content.
 ****/

#content-inner {
    background-color: #fff;
    padding: 30px;
    @include breakpoint($mobile) {
        padding: 20px;
    }
    margin-top: 50px;
    //margin-bottom: 50px;
    position: relative;
}

#content-inner-top {
    position: relative;
    margin-top: -32px;
    min-height: 32px;
    ul.tabs {
        position: relative;
        top: -22px;
        padding: 0;
        margin: 0;
        border: none;
    }
}

/*****
 * Base styles: Footer.
 ****/

#footer {
    background: $color_bg_footer;
    padding-left: 10px;
    padding-right: 10px;
  .footer-info {
    max-width: 1000px;
    margin: 0 auto;
  }
    @include breakpoint($page_width) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @include clearfix();
    #block-user-login {
        @include clearfix();
        float: left;
        position: relative;
        padding-bottom: 30px;
        h2.block-title {
            display: none;
        }
        .form-item, .form-actions {
            float: left;
            position: relative;
            margin: 18px 18px 18px 0;
        }
        div.item-list {
            position: absolute;
            @include font-size($fontsize_small);
            top: 60px;
            ul, li {
                margin: 0;
                padding: 0;
                display: inline;
            }
        }
        input {
            border: 1px solid #5e656b;
            background: transparent;
            padding: 5px 10px;
            border-radius: 0;
            color: $color_hl1;
            @include font-size(16);
        }
        input.form-submit {
            background: $color_hl1;
            color: $color_bg_footer;
        }

        label {
            display: none;
            .form-required {
                display: none;
            }
        }
    }
}

.footer-info {
    padding: 20px 0;
    @include breakpoint-min($page_width) {
        padding: 20px 30px 20px 0;
    }
    @include font-size(16);
    @include clearfix();
    position: relative;
    .col {
        text-align: center;
        @include breakpoint-min($mobile) {
            width: 33%;
            float: left;
            &:nth-child(1) {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
        &:nth-child(2) {
      a {
                @include breakpoint-min($page_width) {
                    font-size: 21px;
                }
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        &:last-child {
            a {
                @include bgimg("elements/arrow_impressum", 14px, 20px);
                padding-right: 30px;
                background-position: center right;
                background-repeat: no-repeat;
            }
        }
    }
    &, a {
        color: #b09d8a;
    }
  &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        @include background-image(linear-gradient(to right, rgba(62, 62, 62, 0), rgba(62, 62, 62, 1) 20%, rgba(62, 62, 62, 1) 80%,rgba(62, 62, 62, 0)));
    }
}

.freefm-social {
  @include clearfix();
  text-align: right;
  padding-top: 18px;
  padding-right: 30px;
  a {
    span {
      display: none;
    }
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-left: 8px;
  }
  br {
    display: none;
  }
  .fm-facebook {
    background: url("../images/icons/icn-facebook.svg")
  }
  .fm-twitter {
    background: url("../images/icons/icn-twitter.svg")
  }
  .fm-soundcloud {
    background: url("../images/icons/icn-soundcloud.svg")
  }
  .fm-youtube {
    background: url("../images/icons/icn-youtube.svg")
  }
}

/*****
 * Base styles: Mobile-specific.
 ****/

// Mobile menu block.
.block-menu-block.mobile {
    // height: 50px;
    // margin-top: -50px;
    width: 100%;
    margin-top: -50px;
    position: relative;
    top: 50px;
    padding-bottom: 10px;
    // height: 100px;
    h2.block-title {
        @extend %sidebar-block-title;
        width: auto;
        max-width: 60%;
        text-align: left;
        padding-left: 20px;
    }
    @include clearfix();
    ul {
        margin: 10px;
        padding: 0;
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            a {
                display: block;
                float: left;
                padding: 5px 10px;
                color: #000;
                font-weight: 600;
                &.active {
                    background: #fff;
                }
                // @include font-size(24);
            }
        }
    }
}

/*****
 * Page Section Teasers (in Header).
 ****/
.block-sectiontitle h2 {
    @extend %sidebar-block-title;
}

// Generic style.
.page-freefm-teaser {
    position: relative;
    #page-top-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 1000px;
        height: 490px;
        content: '';
        display: block;
        overflow: hidden;
    }
    #page-top {
        background-repeat: no-repeat;
        background-position: 0 0;
        // background-size: 1000px 666px;
        background-size: 1000px 430px;
    top: 60px;
        left: 0;
    width: 100%;
    bottom: 0;
        position: absolute;
    }
    #section-teaser {
        height: 330px;
        position: relative;
        .block-block {
            position: absolute;
            // left: 560px;
            // width: 400px;
            left: 56%;
            width: 40%;
            top: 130px;
            height: 200px;
            @include font-size(40);
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            opacity: 0.9;
            // text-shadow: 0 0 6px rgba(0,0,0,0.8);
            @include breakpoint($mobile) {
                left: 80px !important;
                // top: 190px;
                width: auto;
                @include font-size(30);
            }
            @include breakpoint(460px) {
                left: 30px !important;
                width: auto;
                @include font-size(25);
            }
        }
        .block-sectiontitle h2 {
            width: 245px;
            position: absolute;
            bottom: -50px;
            color: #fff;
            left: 0;
        }
    }
    #content {
        @include bgimg("elements/teaser-timeline-overlay", 1237px, 54px);
        background-repeat: no-repeat;
    }

    #content-inner {
        margin-top: 100px;
        @include breakpoint($mobile) {
            margin-top: 50px;
        }
    }
}

// Section-specific styles.

.page-freefm-info {
    #page-top {
        @include bgimg("teaser/INFOTMP", 1000px, 480px, "jpg");
    }
    h1, h2, a:hover {
        color: $color_minfo;
    }
    #sidebar-first .block,
    .block-sectiontitle {
        h2.block-title {
            background: $color_minfo;
        }
    }
    #section-teaser {
        .block-block {
            padding-top: 54px;
            @include bgicon("icons/01_info_white", 42px, 54px);
        }
    }
}

.page-freefm-mitmachen {
    #page-top {
        @include bgimg("teaser/04.3_Mitmachen", 1000px, 480px, "jpg");
    }
    h1, h2, a:hover {
        color: $color_mmitmachen;
    }
    #sidebar-first .block,
    .block-sectiontitle {
      h2.block-title {
            background: $color_mmitmachen;
        }
    }
    #section-teaser {
        .block-block {
            padding-top: 54px;
            @include bgicon("icons/02_mitmachen_white", 42px, 54px);
        }
    }
}

.page-freefm-musik {
    #page-top {
        @include bgimg("teaser/04.4_Musik", 1000px, 480px, "jpg");
    }
    h1, h2, a:hover {
        color: $color_mmusik;
    }
    #sidebar-first .block,
    .block-sectiontitle {
      h2.block-title {
            background: $color_mmusik;
        }
    }
    #section-teaser {
        .block-block {
            left: 50px;
            width: auto;
            padding-top: 49px;
            @include bgicon("icons/03_musik_white", 49px, 52px);
        }
    }
}

.page-freefm-programm {
    #page-top {
        @include bgimg("teaser/04.5_Programm", 1000px, 480px, "jpg");
    }
    h1, h2, a:hover {
        color: $color_mprogramm;
    }
    #sidebar-first .block,
    .block-sectiontitle {
      h2.block-title {
            background: $color_mprogramm;
        }
    }
    #section-teaser {
        .block-block {
            padding-top: 54px;
            left: 118px;
            width: auto;
            @include bgicon("icons/04_programm_white", 66px, 48px);
        }
    }
}

.page-freefm-mediathek {
    #page-top {
        @include bgimg("teaser/banner-mediathek", 1000px, 480px, 'jpg');
    }
    h1, h2, a:hover {
        color: $color_minfo;
    }
    #sidebar-first .block,
    .block-sectiontitle {
      h2.block-title {
            background: $color_minfo;
        }
    }
    #section-teaser {
        .block-block {
            padding-top: 54px;
            left: 52px;
            width: auto;
        }
    }
}

.page-freefm-projekte {
    #page-top {
        @include bgimg("teaser/04.6_Projekte", 1000px, 480px, "jpg");
    }
    h1, h2, a:hover {
        color: $color_mprojekte;
    }

    // Custom blocks: Only first block is regular, others are alt.
    #sidebar-first .block {
        @extend %sidebar-block-alt;
    }
    #sidebar-first .block:first-child {
        @extend %sidebar-block;
        margin-bottom: 0;
    }
    #sidebar-first .block:first-child,
    .block-sectiontitle {
      h2.block-title {
          @extend %sidebar-block-title;
          margin-bottom: 50px;
            background: $color_mprojekte;
        }
    }
    #section-teaser {
        .block-block {
            padding-top: 54px;
            @include bgicon("icons/05_projekte_white", 52px, 52px);
        }
    }
}

.page-freefm-home {
    #page-top {
        @include bgimg("teaser/04.1_Home", 1000px, 480px, "jpg");
    }
    #section-teaser .block-block {
        left: 50px;
        width: auto;
        // @include breakpoint($tablet) {
        // 	top: 80px;
        // }
        // @include breakpoint($mobile) {
        // 	top: 50px;
        // }
    }
  .freefm-front .freefm-front-container:last-child {
        margin-bottom: 0;
    }
}

.page-freefm-suche {
    #page-top {
        @include bgimg("teaser/teaserbild-suchergebnisse", 1000px, 430px, "jpg");
    }
}



/*****
 * Search results.
 ****/

.page-search {
    h1.title {
        display: none;
    }
    #content-area #search-form {
        display: none;
    }
    h2 {
        color: $color-text-meta;
        text-transform: none;
        font-weight: 700;
        margin-top: 0;
        &:before {
            content: "»";
            display: inline-block;
            padding-right: .2em;
        }
    }
    .search-results {
        padding-left: 0;
        h3.title {
          @include font-size(25);
          font-weight: 600;
          font-style: italic;
          a {
              color: $color_text;
              &:after {
                  content: "weiterlesen →";
                  position: absolute;
                  right: 0;
                  bottom: 15px;
                  display: block;
                  @include font-size(16);
                  font-weight: bold;
                  color: $color-text-meta;
                  font-style: normal;
              }
          }
        }
        .search-snippet-info {
            padding-left: 0;
        }
        .search-info {
            &, & a {
                color: $color-text-meta;
            }
        }
        .search-result {
            border-bottom: 1px solid #e5e5e5;
            position: relative;
        }
    }

}

/*****
 * Airplan.
 ****/

.airplan-onair {
    text-align: center;
    font-weight: bold;
    @include font-size(18);
    padding: 20px;
    h3 {
        font-weight: normal;
        @include font-size(25);
        font-style: italic;
        margin-top: 10px;
        &, a {
            color: $color_mprogramm;
        }
    a {
            @include bgicon("elements/arrow_sendeblog-green", 20px, 14px);
            background-position: 100% 8px;
            padding-right: 30px;
        }
    }
}

.airplan-day {
    .airplan-row {
        &:nth-child(2n+1) {
            background-color: #f4f4f3;
        }
        position: relative;
        padding: 10px 20px 10px 20px;
        @include breakpoint('', $tablet) {
            padding-left: 50px;
        }
        cursor: pointer;
        .airplan-time {
            @include breakpoint('', $mobile) {
                float: left;
                width: 200px;
            }
        }
        h3 {
            //margin: 0 0 0 200px;
            margin: 0;
            font-style: italic;
            font-weight: 600;
        }
        .text {
            overflow: hidden;
            transition: max-height .5s ease-out;
            max-height: 0;
            .field-name-body {
                @include font-size($fontsize_small);
                color: $color_text_small;
                padding: 20px 0 10px 0;
                @include breakpoint('', $tablet) {
                    padding-left: 200px;
                }
            }
        }
        .airplan-link {
            padding-right: 30px;
            text-align: right;
            @include bgicon("elements/arrow_sendeblog-green", 20px, 14px);
            background-position: 100% 5px;
            margin-bottom: 20px;
            a {
                color: $color_mprogramm;
                font-weight: bold;
            }
        }
        &:hover, &.active {
            h3 {
                text-decoration: underline;
            }
        }
        &.active {
            .text {
                max-height: 500px;
            }
        }
    }
}

h1.page-title {
    position: relative;
    border-bottom: 1px solid #d2d2d4;
}

.freefm-back-link {
  span { 
    display: none;
  }
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  svg path {
    fill: $color_minfo;
    @include pagecolor(fill);
  }
}

.page-node-artikel .freefm-back-link {
    display: block;
    padding: 0 0 16px 0;
    height: 33px;
    width: 100%;
    position: relative;
    &:after {
        content: '';
        left: 0;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid #d2d2d4;
        position: absolute;
        @include breakpoint-min(600px) {
            right: 305px;
        }
    }
}


.pagetitle-icon {
  cursor: pointer;
    span {
        display: none;
    }
    position: absolute;
    right: 0;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center center;
}

.close-link {
    @include bgimg("elements/close", 19px, 19px);
    &.left-link {
        @include bgimg("elements/arrow-widget-grey", 15px, 28px);
    }
}

.plus-link {
    @include bgimg("elements/close", 19px, 19px);
  transform: rotate(45deg);
    transition: transform .3s linear;
    &.is-shown {
        transform: rotate(0);
    }
}

.freefm-hide {
    &.do-hide {
        overflow: hidden;
        max-height: 0;
    transition: max-height .3s ease-out;
        &.is-shown {
      max-height: 1000px;
        }
    }
}

.airplan-schedule {
    height: 24 * 2 * 15px + 100px;
  width: 1000px;
    overflow-x: scroll;

    > .inner {
        position: absolute;
        left: 0;
        height: auto;
        width: 1000px;
        background: #fff;

    }

    .column {
        width: 130px;
        float: left;
        text-align: center;
        * {
            text-align: center;
        }
        &.times {
            width: 90px;
        }

        h3 {
            height: 50px;
            @include font-size(16);
            font-weight: normal;
            border-right: 1px solid #e8e8e7;
            background-color: #f4f4f3;
            margin: 0;
            padding: 2px 0;
            strong {
                display: block;
                font-weight: bold;
            }
            &.today {
                color: $color_mprogramm;
            }
        }

        a, .cell {
            @include font-size(14);
            font-style: italic;
            //display: table-cell;
            //vertical-align: middle;
            text-align: center;
            display: block;
            &:nth-child(2n+1) {
                background: #f3f3f3;
            }
            border-right: 1px solid #b2b2b2;
            &.onair {
                color: #fff;
                background-color: $color_mprogramm;
            }
        }

        @for $i from 1 through 30 {
            .span#{$i} {
                height: 15px * $i;
                line-height: 15px * $i;
            }
        }

        &.times {
            .cell {
                font-style: normal;
                &:nth-child(2n) {
                    background: #e9e9e9;
                }
            }
        }

    }
}

.airplan-links {
    @include clearfix();
    li {
        list-style-type: none;
        list-style-image: none;
        float: left;
        color: #b09d8a;
        &:after {
            content: '|';
            display: inline-block;
            padding: 0 5px;
        }
        &:last-child {
            &:after {
                content: '';
            }
        }
    }
    a {
        color: #b09d8a;
        &.active {
            color: $color_text;
        }
    }
}

.view-sendungen {
    position: relative;
    h3 {
        @include font-size(27);
        color: $color_mprogramm;
        position: absolute;
        left: 0;
        font-weight: bold;
        background: $color_mprogramm;
        color: #fff;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 36px;
        z-index: 10;
    }

    .views-row {
        padding: 5px 20px 5px 10px;
        position: relative;
        cursor: pointer;
        background-color: #f4f4f3;
        margin-bottom: 5px;
        margin: 0 0 4px 40px;
        &:hover {
            background-color: $color_bg;
        }
        &:nth-child(2n) {
            //background-color: #f4f4f3;
        }
    }

    .views-field-title {
        font-style: italic;
    }
    .views-field-body {
        @include font-size($fontsize_small);
        color: $color_text_small;
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-out;
        .field-content {
            padding: 10px;
        }
    }
    .views-field-view-node {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-out;
        padding-right: 30px;
        text-align: right;
        @include bgicon("elements/arrow_sendeblog-green", 20px, 14px);
        background-position: 100% 5px;
        a {
            color: $color_mprogramm;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    .views-row.active {
        .views-field-body, .views-field-view-node {
            max-height: 500px;
        }
    }

}

iframe {
    max-width: 100%
}

/**
 * Page: Projekte
 */

.item-list ul.projektlogos {
    list-style-type: none;
    margin: 2rem 0;
    li {
        list-style-type: none;
        margin: 10px;
    padding: 10px 0;
      @include breakpoint-min($tablet) {
          display: flex;
      }
    @include clearfix;
    .projekt-logo {
                width: 270px;
        @include breakpoint-min($tablet) {
                padding-right: 20px;
        }
      img {
                max-width: 100%;
                margin: 0 auto;
                display: block;
            }
            flex-grow: 0;
            flex-shrink: 0;
        }
        h4 {
            float: left;
            margin: auto 0;
      flex-shrink: 1;
            flex-grow: 1;
            font-weight: normal;
            font-style: italic;
        }
    }
}

/**
 * Elements.
 */
.readmorelink {
    // color: $color_link;
    @include pagecolor();
    font-weight: 600;
    cursor: pointer;
    @include font-size(16px);
    text-align: right;
    display: block;
    &:after {
        display: inline-block;
        content: '→';
        font-size: 23px;
        position: relative;
        top: 1px;
        margin-left: 8px;

        // content: "";
        // @include bgicon("elements/arrow_sendeblog-grey", 20px, 14px);
        // width: 20px;
        // height: 14px;
        // margin-left: 8px;
        // position: relative;
        // top: 2px;
    }
    &.expanded:after {
        // @include transform(rotate(-90deg));
        content: '↑';
    }
}


#freelight {
  position: fixed;
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 90000;
  background: rgba(0,0,0,0.93);
  cursor: pointer;
  .freelight-inner {
    margin: 5vh;
    height: 90vh;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    /* align horizontal */

    align-items: center;

  }
  img {
    max-width: 100%;
    max-height: 90vh;
    margin: auto;
  }
}

