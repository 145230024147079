

.item-list ul.pager {
  text-align: center;
  //line-height: 35px;
  li {
    margin: 0 8px 0 0;
    padding: 0;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    overflow: hidden;
    //display: inline;
    //position: relative;
    //float: none;
  }
  li, a, a:visited {
    color: #383737;
  }
  a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    //overflow: hidden;
    &:hover {
      text-decoration: none;
    }
  }
  .pager-current,
  .pager-item:hover,
  .pager-next:hover,
  .pager-previous:hover,
  .pager-first:hover,
  .pager-last:hover {
    @include pagecolor(background-color);
    &, a {
      color: #fff;
    }
  }

  .pager-first,
  .pager-last {
    display: none;
  }

  .pager-first,
  .pager-last,
  .pager-previous,
  .pager-next {
    a {
      text-indent: 9999px;
      overflow: hidden;
    }
  }

  .pager-next,
  .pager-previous {
    background-position: center center;
    @include bgicon("elements/arrow-widget-grey", 15px, 28px);
    &:hover {
      @include bgicon("elements/arrow-widget-white", 15px, 28px);
    }
  }
  .pager-next {
    transform: rotate(180deg);
  }

}

/**
 * Select2
 */

.select2-container.select2-container--default {
  .select2-selection--single {
    border-radius: 0;
    border-color: #8c8c8c;
    height: 33px;
    @include font-size(20);
    font-style: italic;
  }
  .select2-selection__rendered {
    border-radius: 0;
    height: 25px;
    padding-top: 2px;
  }
  .select2-dropdown {
    border-radius: 0;
    .select2-search__field {
      border: none;
      outline: none;
      box-shadow: none;
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: $color_minfo;
    }
  }
  .select2-selection--single .select2-selection__arrow {
    @include bgicon('elements/arrow-mediathek', 10px, 16px);
    width: 10px;
    height: 16px;
    transform: rotate(270deg);
    top: 8px;
    right: 9px;

    b {
      display: none !important;
    }
  }
  &.select2-container--open .select2-selection--single .select2-selection__arrow {
    transform: rotate(90deg);
  }
}

ul.primary {
  border: none;
  margin: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  opacity: .65;
  transition: opacity .2s linear;
  &:hover {
    opacity: .8;
  }
  li a {
    border: none;
    border-radius: 10px;
    background: #eee;
    color: #333;
    float: right;
    transition: color .2s ease-out, background-color .2s ease-out;
    font-size: 14px;
    &:hover {
      background: #222;
      color: #f0f0f0;
    }
  }
  li.active a {
    background: #ddd;
    border: none;
    color: #111;
  }
}
