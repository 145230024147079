// Main scrollabletabs view styling.
.views-scrollabletabs {
	// This is needed so that the prev/next links are positioned wrt this.
	position: relative;
	> .item-list > .pager {
		display: none;
	}

	// Previous / Next links.
	div.next,
	div.prev {
		position: absolute;
		width: 340px;
		right: 0;
		height: 40px;
		z-index: 70;
		background: #f5f5f5;
		cursor: pointer;
		&:hover {
			// background-color: #e5e5e5;
		}
		&:before {
			content: "";
	    position: absolute;
	    width: 15px;
	    height: 28px;
	    top: 6px;
			@include bgicon("elements/arrow-widget-grey", 15px, 28px);
	    left: 162px;
	  }
	  span {
	  	display: none;
	  }

	  &.disabled {
	  	cursor: auto;
	  	&:before {
	  		opacity: 0.2;
	  	}
	  }
	}

	div.next {
		bottom: 0;
		&:before {
	    @include transform(rotate(-90deg));
		}
	}
	div.prev {
		top: 0;
		&:before {
	    @include transform(rotate(90deg));
		}
	}

	.vtabs-readmore {
		color: $color_link;
		font-weight: 600;
		position: absolute;
		right: 360px;
		bottom: 15px;
		cursor: pointer;
		@include font-size(16px);
		&:after {
			content: "";
			@include bgicon("elements/arrow_sendeblog-grey", 20px, 14px);
			display: inline-block;
			width: 20px;
			height: 14px;
			margin-left: 8px;
			position: relative;
			top: 2px;
		}
		&.expanded:after {
			@include transform(rotate(-90deg));
		}
	}

	.vtabs-readmore-fade {
		position: absolute;
		bottom: 40px;
		height: 30px;
		@include background-image(linear-gradient(rgba(255,255,255,0) 0%, #fff 70%));
		// &:after {
		// 	content: "";
		// 	@include background-image(linear-gradient(to left, #fff 0%, #fff 10%, rgba(255,255,255,0) 100%));
		// 	width: 50px;
		// 	height: 60px;
		// 	bottom: 40px;
		// 	left: 350px;
		// 	display: block;
		// 	position: absolute;
		// }
	}

	.vertical-tabs {
		padding: 0 340px 0 0;
		margin: 0;
		border: none;

		.vertical-tabs-pane {
			padding-top: 42px;
			padding-left: 0;
				&:after {
					content: "";
					height: 30px;
					display: inline-block;
					@include background-image(linear-gradient(#fff, rgba(255,255,255,0.0)));
				}
		}

		.vertical-tabs-list-wrapper {
			margin: 0 -340px 0 0;
			width: 340px;
			float: right;
			border: none;
			background: #f5f5f5;
			padding: 35px 0;
			max-height: 420px;
			position: relative;
			overflow: hidden;

			ul.vertical-tabs-list {
				//scrollbehavior.
				height: 10000px;
				padding: 0;
				margin: 0;
				width: 340px;
				border: none;
				float: none;
			}

			li {
				border: none;
				background: #f5f5f5;
				a {
					margin: 0 30px;
					padding: 17px 0;
					border-top: 1px solid #c4c4c4;
				}
				a:focus {
					outline: none;
				}
				strong {
					color: $color_text;
					font-weight: 600;
				}
				.summary {
					color: $color_text_meta;
					@include font-size(14);
				}
				&.selected {
					background: #fff;
					strong {
						color: #8c8c95;
					}
				}
				&.first {
					a {
						border-top: none;
					}
				}
				&.selected,
				&.selected + li {
					a {
						border-top-color: #fff;
					}
				}
			}

		}

		// Nodes in vertical tabs.
		.node {
			overflow: hidden;
			max-height: 337px;
			position: static;
			h2 {
				border: none;
				font-style: italic;
				text-transform: none;
				font-weight: normal;
				margin: 25px 0;
				min-height: 30px;
				padding: 0;
				&, a {
					color: $color_headline_dark;
				}
				@include breakpoint($tablet) {
					margin: 25px 30px;

				}
			}
			.submitted {
				display: block;
				height: 25px;
				position: absolute;
				top: 25px;
				left: 90px;
			}
			.field-name-field-sendung {
				display: none;
			}
			.content {
				padding-bottom: 30px;
        color: $color-text-small;
			}
		}
	}

	@include breakpoint($mobile) {
		.vtabs-readmore {
			right: 10px;
		}

		.vertical-tabs {
			padding-right: 0;
			.vertical-tabs-list-wrapper {
				// margin: 0;
				// padding: 0;
				// position: static;
				// float: none;
				// width: 0px;
				// height: 0px;
				// overflow: visible;
				width: 1px;
				position: static;
				.next, .prev {
					width: 15px;
					height: 28px;
					top: 40px;
					z-index: 100;
					background: transparent;
					&:before {
						width: 15px;
						height: 28px;
						left: 0;
						top: 39px;
					}
				}

				.next {
					right: 10px;
					left: auto;
					&:before {
						@include transform(rotate(180deg));
					}
				}

				.prev {
					left: -10px;
					right: auto;
					&:before {
						@include transform(rotate(0));
					}
				}

			}
			.vertical-tabs-list {
				// display: none;
				opacity: 0;
			}
			.node {
				header {
					h2 {
						text-align: center;
						padding: 0 20px;
					}
				}
			}
		}
	}

}


